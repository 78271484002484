import React from 'react';
import { AuthorTurkeyCMS, EventTurkeyCMS } from '~/data/models/cmsTurkey';
import { EventType } from '~/data/operations/global';
import { t } from '~/utils/i18n';
import Button from '../Button';
import EventDate from '../EventDate';
import IconStyled from '../IconStyled';
import {
  Card,
  Title,
  SubTitle,
  TopWrapper,
  IconWrapper,
  BottomWrapper,
  ImageBox,
  TitleAndDate,
  TitleWrapper,
  LocationText,
  CustomBadge,
  ActionWrapper,
} from './style';

export type EventCMSCardForTurkey = {
  event: EventTurkeyCMS;
  eventHost: AuthorTurkeyCMS;
  onPress?: () => void;
  isPast?: boolean;
  isApplied?: boolean;
  isSaved?: boolean;
  onSaveToggleEvent?: () => void;
  onApply?: () => void;
};

export default function EventCMSCardForTurkey({
  event,
  eventHost,
  onPress,
  isPast,
  onApply,
  isApplied,
  isSaved,
  onSaveToggleEvent,
}: EventCMSCardForTurkey): JSX.Element {
  // const attendeesText = (attendeesCount: number | undefined) => {
  //   if (!attendeesCount) {
  //     return '-';
  //   }
  //   return t('g.attendees', { attendeesCount });
  // };
  const EventText = t(`cardEvent.badge${EventType.EVENT}`);

  return (
    <Card
      key={event.sys.id}
      testID={`EventCard:${event.sys.id}`}
      onPress={onPress}
      isPast={isPast}
    >
      <TopWrapper>
        <ImageBox
          source={{
            uri: (eventHost && eventHost.avatar.url) || '',
          }}
        />
        <TitleAndDate>
          <TitleWrapper>
            <Title>{event.title}</Title>
            {eventHost && (
              <SubTitle>{`${t('eventsTRTab.createdBy')}${
                eventHost.fullName
              }`}</SubTitle>
            )}
          </TitleWrapper>
          <EventDate date={new Date(event.eventDate)} />
        </TitleAndDate>
      </TopWrapper>
      <BottomWrapper>
        <IconWrapper>
          <CustomBadge
            text={EventText}
            size={'large'}
            type={'basic'}
            eventType={EventType.EVENT}
          />
        </IconWrapper>
        <IconWrapper>
          <IconStyled name="location_dark" />
          <LocationText numberOfLines={1}>
            {event.isOnline ? t('g.online') : event.locationTitle || ''}
          </LocationText>
        </IconWrapper>
        <IconWrapper>
          {/* <AttendeesText>
            {attendeesText(event.attendees?.length)}
          </AttendeesText> */}
        </IconWrapper>
      </BottomWrapper>
      {!isPast && (
        <ActionWrapper>
          <Button
            testID={`EventCard:${event.sys.id}:SaveButton`}
            text={isSaved ? t('g.saved') : t('g.save')}
            size={'sm'}
            type={'secondary-base'}
            onPress={() => onSaveToggleEvent && onSaveToggleEvent()}
          ></Button>
          <Button
            testID={`EventCard:${event.sys.id}:ApplyButton`}
            text={isApplied ? t('g.applied') : t('g.apply')}
            size={'sm'}
            state={isApplied ? 'disabled' : 'default'}
            onPress={() => onApply && onApply()}
          ></Button>
        </ActionWrapper>
      )}
    </Card>
  );
}
