import React from 'react';
import { useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import { useAuth } from '~/context/auth';
import useLeaderboard from '~/data/hooks/useLeaderboard';
import { useLeaderboardUsersQuery } from '~/data/operations/group/leaderboardUsers';
import { RootStackParamList } from '~/navigation/types';
import LeaderboardLayout from './layout';

type LeaderboardNavProp = StackNavigationProp<
  RootStackParamList,
  'Leaderboard'
>;

export default function Leaderboard(): JSX.Element {
  const { authUserId } = useAuth();
  const { data: leaderboardData } = useLeaderboardUsersQuery({
    skip: !authUserId,
    variables: {
      user: authUserId,
    },
  });

  const { users, onLoadMore, onRefresh, loading } = useLeaderboard();

  const currentUserRanking =
    leaderboardData?.leaderboardUsers?.edges?.[0]?.node;

  const navigation = useNavigation<LeaderboardNavProp>();
  return (
    <LeaderboardLayout
      leaderboardUser={currentUserRanking}
      users={users}
      loading={loading}
      onLoadMore={onLoadMore}
      onRefresh={onRefresh}
      onBack={() => navigation.goBack()}
      meId={authUserId}
    />
  );
}
