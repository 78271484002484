import React, { useMemo, useState } from 'react';
import {
  CompositeNavigationProp,
  useNavigation,
} from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import Snackbar from '~/components/Snackbar';
import { EventTurkeyCMS } from '~/data/models/cmsTurkey';
import { useMeQuery } from '~/data/operations/auth/me';
import { useToggleExternalEventSaveMutation } from '~/data/operations/calendar/toggleExternalEventSave';
import { useAuthorsTurkeyCMS } from '~/data/operations/cmsForTurkey/useAuthorsTurkeyCMS';
import { useEventsTurkeyCMS } from '~/data/operations/cmsForTurkey/useEventsTurkeyCMS';
import { Platform } from '~/data/operations/global';
import {
  RootStackParamList,
  TurkeyEntranceTabParamList,
} from '~/navigation/types';
import { t } from '~/utils/i18n';
import EventsTRLayout from './layout';

type EventsTRNavProp = CompositeNavigationProp<
  StackNavigationProp<RootStackParamList>,
  StackNavigationProp<TurkeyEntranceTabParamList, 'EventsTR'>
>;

export type EnchancedCMSEvent = {
  event: EventTurkeyCMS;
  isPast: boolean;
  isSaved: boolean;
  isApplied: boolean;
};

export default function EventsTR(): JSX.Element {
  const navigation = useNavigation<EventsTRNavProp>();
  const {
    data: meData,
    loading: meLoading,
    error: meError,
    refetch: refetchMe,
  } = useMeQuery();
  const authEmail = meData?.me?.email || '';
  const [saveToggleLoading, setSaveToggleLoading] = useState(false);

  // **** Start: Event Gradoo Module **** //
  // const { authUserId } = useAuth();
  // const { data, refetch } = useEventsQuery({
  //   variables: { after: null, first: DEFAULT_LIST_OFFSET },
  // });

  // useFocusEffect(
  //   React.useCallback(() => {
  //     refetch();
  //   }, []),
  // );

  // const events: Event[] =
  //   (data?.events?.edges.map((edge) => edge?.node) as Event[]) || [];

  // const getUpcomingEvents = (events: Event[]): Event[] => {
  //   const currentDate = new Date();
  //   return events.filter((event) => {
  //     const eventDate = new Date(event.startDateTime);
  //     const dayDifference =
  //       (eventDate.getTime() - currentDate.getTime()) / (1000 * 60 * 60 * 24);

  //     return eventDate >= currentDate && dayDifference > 0;
  //   });
  // };

  // const upcomingEvents = useMemo(() => getUpcomingEvents(events), [events]);
  // const myEvents: Event[] = useMemo(() => {
  //   return events.filter((event) => event.authorUser?.id === authUserId);
  // }, [authUserId, events]);
  // const myAttendedEvents: Event[] = useMemo(() => {
  //   const eventList: Event[] = [];
  //   events.forEach((event) => {
  //     event.attendees?.forEach((attendee) => {
  //       if (attendee?.user.id === authUserId) {
  //         eventList.push(event);
  //       }
  //     });
  //   });
  //   return eventList;
  // }, [authUserId, events]);

  // **** End:  Event Gradoo Module **** //

  // **** Start: Turkey CMS  **** //
  const savedCMSEventIDs = useMemo(() => {
    const cmsTurkeyEvents = meData?.me?.externalEvents.edges?.filter(
      (item) => item?.node?.platform === Platform.CONTENTFUL,
    );

    const ids = cmsTurkeyEvents?.map((item) => item?.node?.eventId) || [];
    return ids;
  }, [meData?.me?.externalEvents.edges]);

  const {
    data: eventsDataFromTurkeyCMS,
    loading: eventsCMSLoading,
    error: eventsCMSError,
    refetch: refetchEventsFromTurkeyCMS,
  } = useEventsTurkeyCMS();
  const cmsEvents = eventsDataFromTurkeyCMS?.eventsCollection.items || [];
  const authorsIDs = useMemo(() => {
    const authorsList = cmsEvents?.map((event) => event.host?.sys.id) || [];
    return [...new Set(authorsList)];
  }, [eventsDataFromTurkeyCMS]);

  const {
    data: authorsData,
    loading: authorsCMSLoading,
    error: authorsCMSError,
  } = useAuthorsTurkeyCMS({
    variables: {
      authorsIDs,
    },
    skip: !authorsIDs,
  });
  const cmsAuthors = authorsData?.peopleCollection.items || [];

  const [toggleExternalEventSave] = useToggleExternalEventSaveMutation();

  const onSaveToggleEvent = async (eventId: string) => {
    setSaveToggleLoading(true);
    try {
      const res = await toggleExternalEventSave({
        variables: {
          input: {
            eventId,
          },
        },
      });
      const isSuccess = res.data?.toggleExternalEventSave?.success;
      const isSavedAlready = savedCMSEventIDs?.includes(eventId) || false;
      Snackbar.show(
        !isSuccess
          ? t('eventsTRTab.eventSaveError')
          : isSavedAlready
          ? t('eventsTRTab.eventUnsaved')
          : t('eventsTRTab.eventSaved'),
      );
      await refetchMe();
    } catch (e) {
      if (e instanceof Error) {
        Snackbar.show(e.message);
      }
    } finally {
      setSaveToggleLoading(false);
    }
  };

  const enhancedEvents = useMemo(() => {
    const events: EnchancedCMSEvent[] = [];
    const currentDate = new Date();

    cmsEvents.forEach((event) => {
      const eventDate = new Date(event.eventDate);
      const isPast = eventDate <= currentDate;
      const isSaved = savedCMSEventIDs?.includes(event.sys.id) || false;
      const isApplied = event.participants?.includes(authEmail) || false;
      events.push({
        event,
        isPast,
        isSaved,
        isApplied,
      });
    });

    return events;
  }, [eventsDataFromTurkeyCMS, savedCMSEventIDs]);

  const filteredEvents = useMemo(() => {
    const pastEventsTurkeyCMS: EnchancedCMSEvent[] = [];
    const upcomingEventsTurkeyCMS: EnchancedCMSEvent[] = [];
    const savedTurkeyCMSEvents: EnchancedCMSEvent[] = [];
    const appliedTurkeyCMSEvents: EnchancedCMSEvent[] = [];

    enhancedEvents.forEach((event) => {
      if (event.isPast) {
        pastEventsTurkeyCMS.push(event);
      } else {
        upcomingEventsTurkeyCMS.push(event);
      }

      if (event.isSaved) {
        savedTurkeyCMSEvents.push(event);
      }

      if (event.isApplied) {
        appliedTurkeyCMSEvents.push(event);
      }
    });

    return {
      pastEventsTurkeyCMS,
      upcomingEventsTurkeyCMS,
      savedTurkeyCMSEvents,
      appliedTurkeyCMSEvents,
    };
  }, [eventsDataFromTurkeyCMS, savedCMSEventIDs]);
  // **** End: Turkey CMS  **** //

  return (
    <EventsTRLayout
      loading={
        eventsCMSLoading || authorsCMSLoading || meLoading || saveToggleLoading
      }
      errorMessage={
        eventsCMSError?.message || authorsCMSError?.message || meError?.message
      }
      // *** Start:  Event Gradoo Module *** //
      myEvents={[]} // soon
      myAttendedEvents={[]} // soon
      upcomingEvents={[]} // soon
      onBack={() => navigation.goBack()}
      onAddEvent={() =>
        navigation.navigate('CreateEvent', {
          isFromTurkeyEntrance: true,
        })
      }
      onEventOpen={(id) =>
        navigation.navigate('EventDetail', {
          eventId: id,
          isFromTurkeyEntrance: true,
        })
      }
      // *** Start: Turkey CMS *** //
      pastEventsTurkeyCMS={filteredEvents.pastEventsTurkeyCMS}
      upcomingEventsTurkeyCMS={filteredEvents.upcomingEventsTurkeyCMS}
      savedTurkeyCMSEvents={filteredEvents.savedTurkeyCMSEvents}
      appliedTurkeyCMSEvents={filteredEvents.appliedTurkeyCMSEvents}
      authorsTurkeyCMS={cmsAuthors}
      onPressCMSEvent={(title, url) => {
        navigation.navigate('Browser', {
          title: title,
          url: url,
          onGoBack: () => {
            refetchEventsFromTurkeyCMS();
          },
        });
      }}
      onSaveToggleEvent={onSaveToggleEvent}
    />
  );
}
