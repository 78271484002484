import { gql } from '@apollo/client';
import { USER_GROUP_FIELDS } from '../group';
import { TEAM_FIELDS } from '../team';

/** Fragments */

export const MODULE_FIELDS = gql`
  fragment ModuleFields on ModuleNode {
    id
    name
    image
    type
    description
    features
  }
`;

export const MODULE_INSTANCE_FIELDS = gql`
  ${MODULE_FIELDS}
  fragment ModuleInstanceFields on ModuleInstanceNode {
    id
    isActive
    dueDate
    module {
      ...ModuleFields
    }
  }
`;

export const PREWORD_INSTANCE_FIELDS = gql`
  ${MODULE_FIELDS}
  fragment PrewordInstanceFields on PrewordInstanceNode {
    id
    text
  }
`;

export const PROFILE_PAGE_SETUP_FIELDS = gql`
  fragment ProfilePageSetupFields on ProfilePageSetupNode {
    id
    photoFormat
    maxChars
    anonymousComments
    visibleComments
  }
`;

export const RANKINGS_SETUP_FIELDS = gql`
  ${TEAM_FIELDS}
  fragment RankingsSetupFields on RankingsSetupNode {
    id
    resultsTeam {
      ...TeamFields
    }
  }
`;

export const COLLAGES_SETUP_FIELDS = gql`
  ${TEAM_FIELDS}
  fragment CollagesSetupFields on CollagesSetupNode {
    id
    createAlbumsTeam {
      ...TeamFields
    }
    addPhotosTeam {
      ...TeamFields
    }
    viewPhotosTeam {
      ...TeamFields
    }
    photosCount
  }
`;

export const REPORTS_SETUP_FIELDS = gql`
  fragment ReportsSetupFields on ReportsSetupNode {
    id
    allowImages
  }
`;

export const QUOTES_SETUP_FIELDS = gql`
  fragment QuotesSetupFields on QuotesSetupNode {
    id
    anonymous
    public
    maxChars
  }
`;

export const MODULE_INSTANCE_DETAIL_FIELDS = gql`
  ${MODULE_INSTANCE_FIELDS}
  ${PROFILE_PAGE_SETUP_FIELDS}
  ${RANKINGS_SETUP_FIELDS}
  ${COLLAGES_SETUP_FIELDS}
  ${REPORTS_SETUP_FIELDS}
  ${QUOTES_SETUP_FIELDS}
  fragment ModuleInstanceDetailFields on ModuleInstanceNode {
    ...ModuleInstanceFields
    profilePageSetup {
      ...ProfilePageSetupFields
    }
    rankingsSetup {
      ...RankingsSetupFields
    }
    collagesSetup {
      ...CollagesSetupFields
    }
    reportsSetup {
      ...ReportsSetupFields
    }
    quotesSetup {
      ...QuotesSetupFields
    }
  }
`;

export const PREWORD_RESULT_FIELDS = gql`
  fragment PrewordResultFields on PrewordInstanceNode {
    wordCount
  }
`;

export const PROFILE_PAGE_USER_SUMMARY_FIELDS = gql`
  ${USER_GROUP_FIELDS}
  fragment ProfilePageUserSummaryFields on ProfilePageUserSummaryNode {
    userGroup {
      ...UserGroupFields
    }
    questionsAnswered
    imagesUploaded
  }
`;

export const PROFILE_PAGE_RESULT_FIELDS = gql`
  ${PROFILE_PAGE_USER_SUMMARY_FIELDS}
  fragment ProfilePageResultFields on ProfilePageSetupNode {
    id
    usersSummary {
      ...ProfilePageUserSummaryFields
    }
    usersDelivered
  }
`;

export const RANKINGS_RESULT_FIELDS = gql`
  fragment RankingsResultFields on RankingsSetupNode {
    rankingsCount
  }
`;

export const COLLAGES_RESULT_FIELDS = gql`
  fragment CollagesResultFields on CollagesSetupNode {
    photosCount
  }
`;

export const REPORTS_RESULT_FIELDS = gql`
  fragment ReportsResultFields on ReportsSetupNode {
    reportsCount
  }
`;

export const QUOTES_RESULT_FIELDS = gql`
  fragment QuotesResultFields on QuotesSetupNode {
    quotesCount
  }
`;

export const MODULE_INSTANCE_RESULT_FIELDS = gql`
  ${PREWORD_RESULT_FIELDS}
  ${MODULE_INSTANCE_FIELDS}
  ${PROFILE_PAGE_RESULT_FIELDS}
  ${RANKINGS_RESULT_FIELDS}
  ${COLLAGES_RESULT_FIELDS}
  ${REPORTS_RESULT_FIELDS}
  ${QUOTES_RESULT_FIELDS}
  fragment ModuleInstanceResultFields on ModuleInstanceNode {
    ...ModuleInstanceFields
    prewordInstance {
      ...PrewordResultFields
    }
    profilePageSetup {
      ...ProfilePageResultFields
    }
    rankingsSetup {
      ...RankingsResultFields
    }
    collagesSetup {
      ...CollagesResultFields
    }
    reportsSetup {
      ...ReportsResultFields
    }
    quotesSetup {
      ...QuotesResultFields
    }
  }
`;

export const PROFILE_PAGE_PHOTO_FIELDS = gql`
  fragment ProfilePagePhotoFields on ProfilePagePhotoNode {
    id
    image
  }
`;

export const BASE_PROFILE_PAGE_PHOTO_CATEGORY_FIELDS = gql`
  fragment BaseProfilePagePhotoCategoryFields on ProfilePagePhotoCategoryNode {
    id
    name
  }
`;

export const PROFILE_PAGE_PHOTO_CATEGORY_FIELDS = gql`
  ${BASE_PROFILE_PAGE_PHOTO_CATEGORY_FIELDS}
  ${PROFILE_PAGE_PHOTO_FIELDS}
  fragment ProfilePagePhotoCategoryFields on ProfilePagePhotoCategoryNode {
    ...BaseProfilePagePhotoCategoryFields
    profilePagePhotos(userGroup: $ppqUserGroup) {
      edges {
        node {
          ...ProfilePagePhotoFields
        }
      }
    }
  }
`;

export const PROFILE_PAGE_QUESTION_OPTION_FIELDS = gql`
  fragment ProfilePageQuestionOptionFields on ProfilePageQuestionOptionNode {
    id
    text
  }
`;

export const PROFILE_PAGE_ANSWER_FIELDS = gql`
  ${PROFILE_PAGE_QUESTION_OPTION_FIELDS}
  fragment ProfilePageAnswerFields on ProfilePageAnswerNode {
    id
    text
    profilePageQuestionOption {
      ...ProfilePageQuestionOptionFields
    }
    userGroup {
      id
    }
  }
`;

export const BASE_PROFILE_PAGE_QUESTION_FIELDS = gql`
  ${PROFILE_PAGE_QUESTION_OPTION_FIELDS}
  fragment BaseProfilePageQuestionFields on ProfilePageQuestionNode {
    id
    text
    order
    profilePageQuestionOptions {
      ...ProfilePageQuestionOptionFields
    }
  }
`;

export const PROFILE_PAGE_QUESTION_FIELDS = gql`
  ${BASE_PROFILE_PAGE_QUESTION_FIELDS}
  ${PROFILE_PAGE_ANSWER_FIELDS}
  fragment ProfilePageQuestionFields on ProfilePageQuestionNode {
    ...BaseProfilePageQuestionFields
    profilePageAnswers(userGroup: $ppqUserGroup) {
      edges {
        node {
          ...ProfilePageAnswerFields
        }
      }
    }
  }
`;

export const PROFILE_PAGE_SUGGESTED_QUESTION_OPTION_FIELDS = gql`
  fragment ProfilePageSuggestedQuestionOptionFields on ProfilePageSuggestedQuestionOptionNode {
    id
    text
  }
`;

export const PROFILE_PAGE_SUGGESTED_QUESTION_FIELDS = gql`
  ${PROFILE_PAGE_SUGGESTED_QUESTION_OPTION_FIELDS}
  fragment ProfilePageSuggestedQuestionFields on ProfilePageSuggestedQuestionNode {
    id
    text
    options {
      ...ProfilePageSuggestedQuestionOptionFields
    }
  }
`;

export const PROFILE_PAGE_COMMENT_FIELDS = gql`
  ${USER_GROUP_FIELDS}
  fragment ProfilePageCommentFields on ProfilePageCommentNode {
    id
    text
    comentatorUserGroup {
      ...UserGroupFields
    }
    profileUserGroup {
      ...UserGroupFields
    }
    created
  }
`;

export const COLLAGES_PHOTO_FIELDS = gql`
  fragment CollagesPhotoFields on CollagesPhotoNode {
    id
    photo
    collagesPhotoLikesCount
    created
    userGroup {
      id
    }
    collagesPhotoLikes(userGroup: $authUserGroupId) {
      edges {
        node {
          userGroup {
            id
          }
        }
      }
    }
  }
`;

export const COLLAGES_BASE_PHOTO_FIELDS = gql`
  fragment CollagesBasePhotoFields on CollagesPhotoNode {
    id
    photo
    collagesPhotoLikesCount
    created
    userGroup {
      id
    }
  }
`;

export const BASE_COLLAGES_ALBUM_FIELDS = gql`
  fragment BaseCollagesAlbumFields on CollagesAlbumNode {
    id
    name
  }
`;

export const COLLAGES_ALBUM_FIELDS = gql`
  ${BASE_COLLAGES_ALBUM_FIELDS}
  ${COLLAGES_BASE_PHOTO_FIELDS}
  fragment CollagesAlbumFields on CollagesAlbumNode {
    ...BaseCollagesAlbumFields
    photosCount
    collagesPhotos(after: $cpAfter, first: $cpFirst) {
      edges {
        node {
          ...CollagesBasePhotoFields
        }
      }
    }
  }
`;

export const COLLAGES_BASE_ALBUM_FIELDS = gql`
  ${BASE_COLLAGES_ALBUM_FIELDS}
  fragment CollagesBaseAlbumFields on CollagesAlbumNode {
    ...BaseCollagesAlbumFields
    photosCount
  }
`;

export const REPORTS_INSTANCE_FIELDS = gql`
  ${TEAM_FIELDS}
  fragment ReportsInstanceFields on ReportsInstanceNode {
    id
    title
    text
    editTeam {
      ...TeamFields
    }
    viewTeam {
      ...TeamFields
    }
    maxChars
  }
`;

export const BASE_QUOTE_FIELDS = gql`
  ${USER_GROUP_FIELDS}
  fragment BaseQuoteFields on QuotesInstanceNode {
    id
    quote
    userGroup {
      ...UserGroupFields
    }
    quotesInstanceLikesCount
    created
  }
`;

export const QUOTE_FIELDS = gql`
  ${BASE_QUOTE_FIELDS}
  fragment QuoteFields on QuotesInstanceNode {
    ...BaseQuoteFields
    quotesInstanceLikes(userGroup: $authUserGroupId) {
      edges {
        node {
          id
          userGroup {
            id
          }
        }
      }
    }
  }
`;

export const CUSTOM_LIST_FIELDS = gql`
  fragment CustomListFields on CustomListNode {
    id
    title
  }
`;

export const CUSTOM_LIST_OPTION_FIELDS = gql`
  fragment CustomListOptionFields on CustomListOptionNode {
    id
    text
  }
`;

export const RANKING_QUESTION_RESULT_FIELDS = gql`
  ${USER_GROUP_FIELDS}
  ${CUSTOM_LIST_OPTION_FIELDS}
  fragment RankingQuestionResultFields on RankingsQuestionResultNode {
    votee1UserGroup {
      ...UserGroupFields
    }
    votee2UserGroup {
      ...UserGroupFields
    }
    voteeListOption {
      ...CustomListOptionFields
    }
    percentage
  }
`;

export const RANKING_QUESTION_RESULTS_FIELDS = gql`
  ${RANKING_QUESTION_RESULT_FIELDS}
  fragment RankingQuestionResultsFields on RankingsQuestionResultsNode {
    alreadyVoted
    possibleVoters
    options {
      ...RankingQuestionResultFields
    }
  }
`;

export const RANKING_ANSWER_FIELDS = gql`
  ${USER_GROUP_FIELDS}
  ${CUSTOM_LIST_OPTION_FIELDS}
  fragment RankingsAnswerFields on RankingsAnswerNode {
    id
    votee1UserGroup {
      ...UserGroupFields
    }
    votee2UserGroup {
      ...UserGroupFields
    }
    voteeListOption {
      ...CustomListOptionFields
    }
  }
`;

export const RANKING_QUESTION_FIELDS = gql`
  ${RANKING_QUESTION_RESULTS_FIELDS}
  ${RANKING_ANSWER_FIELDS}
  fragment RankingQuestionFields on RankingsQuestionNode {
    id
    question
    answerType
    allowedVotes
    onlyOppositeGender
    customList {
      id
    }
    results {
      ...RankingQuestionResultsFields
    }
    rankingsAnswers(voterUserGroup: $authUserGroupId) {
      edges {
        node {
          ...RankingsAnswerFields
        }
      }
    }
  }
`;

export const RANKING_BASE_QUESTION_FIELDS = gql`
  ${RANKING_QUESTION_RESULTS_FIELDS}
  fragment RankingBaseQuestionFields on RankingsQuestionNode {
    id
    question
    answerType
    allowedVotes
    onlyOppositeGender
    customList {
      id
    }
    rankingsAnswers(voterUserGroup: $authUserGroupId) {
      edges {
        node {
          id
        }
      }
    }
  }
`;

export const DATA_EXPORT_FIELDS = gql`
  ${USER_GROUP_FIELDS}
  fragment DataExportFields on DataExportNode {
    id
    isodatetime
    userGroup {
      ...UserGroupFields
    }
    exportFile
    exportCollages
    exportRankings
    exportProfilePages
    exportQuotes
  }
`;

/** Queries */

export const MODULES = gql`
  ${MODULE_FIELDS}
  query Modules($after: String, $first: Int!) {
    modules(after: $after, first: $first) {
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        node {
          ...ModuleFields
        }
      }
    }
  }
`;

export const MODULE = gql`
  ${MODULE_FIELDS}
  query Module($id: ID!) {
    module(id: $id) {
      ...ModuleFields
    }
  }
`;

export const MODULE_INSTANCES = gql`
  ${MODULE_INSTANCE_FIELDS}
  query ModuleInstances($after: String, $first: Int!, $group: ID) {
    moduleInstances(after: $after, first: $first, group: $group) {
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        node {
          ...ModuleInstanceFields
        }
      }
    }
  }
`;

export const MODULE_INSTANCE = gql`
  ${MODULE_INSTANCE_DETAIL_FIELDS}
  query ModuleInstance($id: ID!) {
    moduleInstance(id: $id) {
      ...ModuleInstanceDetailFields
    }
  }
`;

export const MODULE_INSTANCE_RESULTS = gql`
  ${MODULE_INSTANCE_RESULT_FIELDS}
  query ModuleInstanceResults($after: String, $first: Int!, $group: ID) {
    moduleInstances(after: $after, first: $first, group: $group) {
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        node {
          ...ModuleInstanceResultFields
        }
      }
    }
  }
`;

export const MODULE_INSTANCE_RESULT = gql`
  ${MODULE_INSTANCE_RESULT_FIELDS}
  query ModuleInstanceResult($id: ID!) {
    moduleInstance(id: $id) {
      ...ModuleInstanceResultFields
    }
  }
`;

export const PROFILE_PAGE_PHOTO_CATEGORIES = gql`
  ${PROFILE_PAGE_PHOTO_CATEGORY_FIELDS}
  query ProfilePagePhotoCategories(
    $after: String
    $first: Int!
    $moduleInstance: ID
    $ppqUserGroup: ID
  ) {
    profilePagePhotoCategories(
      after: $after
      first: $first
      moduleInstance: $moduleInstance
    ) {
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        node {
          ...ProfilePagePhotoCategoryFields
        }
      }
    }
  }
`;

export const PROFILE_PAGE_QUESTIONS = gql`
  ${PROFILE_PAGE_QUESTION_FIELDS}
  query ProfilePageQuestions(
    $after: String
    $first: Int!
    $moduleInstance: ID
    $ppqUserGroup: ID
  ) {
    profilePageQuestions(
      after: $after
      first: $first
      moduleInstance: $moduleInstance
    ) {
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        node {
          ...ProfilePageQuestionFields
        }
      }
    }
  }
`;

export const PROFILE_PAGE_SUGGESTED_QUESTIONS = gql`
  ${PROFILE_PAGE_SUGGESTED_QUESTION_FIELDS}
  query ProfilePageSuggestedQuestions($after: String, $first: Int!) {
    profilePageSuggestedQuestions(after: $after, first: $first) {
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        node {
          ...ProfilePageSuggestedQuestionFields
        }
      }
    }
  }
`;

export const PROFILE_PAGE_COMMENTS = gql`
  ${PROFILE_PAGE_COMMENT_FIELDS}
  query ProfilePageComments(
    $after: String
    $first: Int!
    $profileUserGroup: ID
    $comentatorUserGroup: ID
    $moduleInstance: ID
  ) {
    profilePageComments(
      after: $after
      first: $first
      profileUserGroup: $profileUserGroup
      comentatorUserGroup: $comentatorUserGroup
      moduleInstance: $moduleInstance
    ) {
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        node {
          ...ProfilePageCommentFields
        }
      }
    }
  }
`;

export const COLLAGES_ALBUMS = gql`
  ${COLLAGES_ALBUM_FIELDS}
  query CollagesAlbums(
    $after: String
    $first: Int!
    $moduleInstance: ID
    $cpAfter: String
    $cpFirst: Int
  ) {
    collagesAlbums(
      after: $after
      first: $first
      moduleInstance: $moduleInstance
    ) {
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        node {
          ...CollagesAlbumFields
        }
      }
    }
  }
`;

export const COLLAGES_ALBUM = gql`
  ${COLLAGES_BASE_ALBUM_FIELDS}
  query CollagesAlbum($id: ID!) {
    collagesAlbum(id: $id) {
      ...CollagesBaseAlbumFields
    }
  }
`;

export const COLLAGES_PHOTOS = gql`
  ${COLLAGES_PHOTO_FIELDS}
  query CollagesPhotos(
    $after: String
    $first: Int
    $collagesAlbum: ID
    $authUserGroupId: ID
  ) {
    collagesPhotos(
      after: $after
      first: $first
      collagesAlbum: $collagesAlbum
    ) {
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        node {
          ...CollagesPhotoFields
        }
      }
    }
  }
`;

export const REPORTS_INSTANCES = gql`
  ${REPORTS_INSTANCE_FIELDS}
  query ReportsInstances($after: String, $first: Int!, $moduleInstance: ID) {
    reportsInstances(
      after: $after
      first: $first
      moduleInstance: $moduleInstance
    ) {
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        node {
          ...ReportsInstanceFields
        }
      }
    }
  }
`;

export const REPORTS_INSTANCE = gql`
  ${REPORTS_INSTANCE_FIELDS}
  query ReportsInstance($id: ID!) {
    reportsInstance(id: $id) {
      ...ReportsInstanceFields
    }
  }
`;

export const QUOTES_INSTANCES = gql`
  ${QUOTE_FIELDS}
  query QuotesInstances(
    $after: String
    $first: Int!
    $moduleInstance: ID
    $userGroup: ID
    $authUserGroupId: ID
    $orderBy: String
  ) {
    quotesInstances(
      after: $after
      first: $first
      moduleInstance: $moduleInstance
      userGroup: $userGroup
      orderBy: $orderBy
    ) {
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        node {
          ...QuoteFields
        }
      }
    }
  }
`;

export const CUSTOM_LISTS = gql`
  ${CUSTOM_LIST_FIELDS}
  query CustomLists($after: String, $first: Int!, $moduleInstance: ID) {
    customLists(after: $after, first: $first, moduleInstance: $moduleInstance) {
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        node {
          ...CustomListFields
        }
      }
    }
  }
`;

export const CUSTOM_LIST_OPTIONS = gql`
  ${CUSTOM_LIST_OPTION_FIELDS}
  query CustomListOptions($after: String, $first: Int!, $customList: ID) {
    customListOptions(after: $after, first: $first, customList: $customList) {
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        node {
          ...CustomListOptionFields
        }
      }
    }
  }
`;

export const RANKINGS_QUESTIONS = gql`
  ${RANKING_QUESTION_FIELDS}
  query RankingsQuestions(
    $after: String
    $first: Int!
    $moduleInstance: ID
    $authUserGroupId: ID
  ) {
    rankingsQuestions(
      after: $after
      first: $first
      moduleInstance: $moduleInstance
    ) {
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        node {
          ...RankingQuestionFields
        }
      }
    }
  }
`;

export const RANKINGS_QUESTION = gql`
  ${RANKING_BASE_QUESTION_FIELDS}
  query RankingsQuestion($id: ID!, $authUserGroupId: ID) {
    rankingsQuestion(id: $id) {
      ...RankingBaseQuestionFields
    }
  }
`;

export const DATA_EXPORTS = gql`
  ${DATA_EXPORT_FIELDS}
  query DataExports($after: String, $first: Int!, $group: ID!) {
    dataExports(after: $after, first: $first, group: $group) {
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        node {
          ...DataExportFields
        }
      }
    }
  }
`;

/** Mutations */

export const CREATE_MODULE_INSTANCE = gql`
  ${MODULE_INSTANCE_FIELDS}
  mutation CreateModuleInstance($input: CreateModuleInstanceInput!) {
    createModuleInstance(input: $input) {
      errors {
        field
        messages
      }
      moduleInstance {
        ...ModuleInstanceFields
      }
    }
  }
`;

export const DESTROY_MODULE_INSTANCE = gql`
  ${MODULE_INSTANCE_FIELDS}
  mutation DestroyModuleInstance($input: DestroyModuleInstanceInput!) {
    destroyModuleInstance(input: $input) {
      moduleInstance {
        ...ModuleInstanceFields
      }
    }
  }
`;

export const UPDATE_PREWORD_INSTANCE = gql`
  ${PREWORD_INSTANCE_FIELDS}
  mutation UpdatePrewordInstance($input: UpdatePrewordInstanceInput!) {
    updatePrewordInstance(input: $input) {
      errors {
        field
        messages
      }
      prewordInstance {
        ...PrewordInstanceFields
      }
    }
  }
`;

export const CREATE_PROFILE_PAGE_PHOTO_CATEGORY = gql`
  ${BASE_PROFILE_PAGE_PHOTO_CATEGORY_FIELDS}
  mutation CreateProfilePagePhotoCategory(
    $input: CreateProfilePagePhotoCategoryInput!
  ) {
    createProfilePagePhotoCategory(input: $input) {
      errors {
        field
        messages
      }
      profilePagePhotoCategory {
        ...BaseProfilePagePhotoCategoryFields
      }
    }
  }
`;

export const UPDATE_PROFILE_PAGE_PHOTO_CATEGORY = gql`
  ${BASE_PROFILE_PAGE_PHOTO_CATEGORY_FIELDS}
  mutation UpdateProfilePagePhotoCategory(
    $input: UpdateProfilePagePhotoCategoryInput!
  ) {
    updateProfilePagePhotoCategory(input: $input) {
      errors {
        field
        messages
      }
      profilePagePhotoCategory {
        ...BaseProfilePagePhotoCategoryFields
      }
    }
  }
`;

export const DESTROY_PROFILE_PAGE_PHOTO_CATEGORY = gql`
  ${BASE_PROFILE_PAGE_PHOTO_CATEGORY_FIELDS}
  mutation DestroyProfilePagePhotoCategory(
    $input: DestroyProfilePagePhotoCategoryInput!
  ) {
    destroyProfilePagePhotoCategory(input: $input) {
      profilePagePhotoCategory {
        ...BaseProfilePagePhotoCategoryFields
      }
    }
  }
`;

export const CREATE_PROFILE_PAGE_PHOTO = gql`
  ${PROFILE_PAGE_PHOTO_FIELDS}
  mutation CreateProfilePagePhoto($input: CreateProfilePagePhotoInput!) {
    createProfilePagePhoto(input: $input) {
      profilePagePhoto {
        ...ProfilePagePhotoFields
      }
    }
  }
`;

export const UPDATE_PROFILE_PAGE_PHOTO = gql`
  ${PROFILE_PAGE_PHOTO_FIELDS}
  mutation UpdateProfilePagePhoto($input: UpdateProfilePagePhotoInput!) {
    updateProfilePagePhoto(input: $input) {
      profilePagePhoto {
        ...ProfilePagePhotoFields
      }
    }
  }
`;

export const DESTROY_PROFILE_PAGE_PHOTO = gql`
  ${PROFILE_PAGE_PHOTO_FIELDS}
  mutation DestroyProfilePagePhoto($input: DestroyProfilePagePhotoInput!) {
    destroyProfilePagePhoto(input: $input) {
      profilePagePhoto {
        ...ProfilePagePhotoFields
      }
    }
  }
`;

export const CREATE_PROFILE_PAGE_QUESTION = gql`
  ${BASE_PROFILE_PAGE_QUESTION_FIELDS}
  mutation CreateProfilePageQuestion($input: CreateProfilePageQuestionInput!) {
    createProfilePageQuestion(input: $input) {
      errors {
        field
        messages
      }
      profilePageQuestion {
        ...BaseProfilePageQuestionFields
      }
    }
  }
`;

export const UPDATE_PROFILE_PAGE_QUESTION = gql`
  ${BASE_PROFILE_PAGE_QUESTION_FIELDS}
  mutation UpdateProfilePageQuestion($input: UpdateProfilePageQuestionInput!) {
    updateProfilePageQuestion(input: $input) {
      errors {
        field
        messages
      }
      profilePageQuestion {
        ...BaseProfilePageQuestionFields
      }
    }
  }
`;

export const DESTROY_PROFILE_PAGE_QUESTION = gql`
  ${BASE_PROFILE_PAGE_QUESTION_FIELDS}
  mutation DestroyProfilePageQuestion(
    $input: DestroyProfilePageQuestionInput!
  ) {
    destroyProfilePageQuestion(input: $input) {
      profilePageQuestion {
        ...BaseProfilePageQuestionFields
      }
    }
  }
`;

export const CREATE_PROFILE_PAGE_ANSWER = gql`
  ${PROFILE_PAGE_ANSWER_FIELDS}
  mutation CreateProfilePageAnswer($input: CreateProfilePageAnswerInput!) {
    createProfilePageAnswer(input: $input) {
      errors {
        field
        messages
      }
      profilePageAnswer {
        ...ProfilePageAnswerFields
      }
    }
  }
`;

export const UPDATE_PROFILE_PAGE_ANSWER = gql`
  ${PROFILE_PAGE_ANSWER_FIELDS}
  mutation UpdateProfilePageAnswer($input: UpdateProfilePageAnswerInput!) {
    updateProfilePageAnswer(input: $input) {
      errors {
        field
        messages
      }
      profilePageAnswer {
        ...ProfilePageAnswerFields
      }
    }
  }
`;

export const DESTROY_PROFILE_PAGE_ANSWER = gql`
  mutation DestroyProfilePageAnswer($input: DestroyProfilePageAnswerInput!) {
    destroyProfilePageAnswer(input: $input) {
      clientMutationId
    }
  }
`;

export const UPDATE_PROFILE_PAGE_SETUP = gql`
  ${PROFILE_PAGE_SETUP_FIELDS}
  mutation UpdateProfilePageSetup($input: UpdateProfilePageSetupInput!) {
    updateProfilePageSetup(input: $input) {
      errors {
        field
        messages
      }
      profilePageSetup {
        ...ProfilePageSetupFields
      }
    }
  }
`;

export const UPDATE_MODULE_INSTANCE = gql`
  ${MODULE_INSTANCE_DETAIL_FIELDS}
  mutation UpdateModuleInstance($input: UpdateModuleInstanceInput!) {
    updateModuleInstance(input: $input) {
      errors {
        field
        messages
      }
      moduleInstance {
        ...ModuleInstanceDetailFields
      }
    }
  }
`;

export const CREATE_PROFILE_PAGE_COMMENT = gql`
  ${PROFILE_PAGE_COMMENT_FIELDS}
  mutation CreateProfilePageComment($input: CreateProfilePageCommentInput!) {
    createProfilePageComment(input: $input) {
      errors {
        field
        messages
      }
      profilePageComment {
        ...ProfilePageCommentFields
      }
    }
  }
`;

export const UPDATE_PROFILE_PAGE_COMMENT = gql`
  ${PROFILE_PAGE_COMMENT_FIELDS}
  mutation UpdateProfilePageComment($input: UpdateProfilePageCommentInput!) {
    updateProfilePageComment(input: $input) {
      errors {
        field
        messages
      }
      profilePageComment {
        ...ProfilePageCommentFields
      }
    }
  }
`;

export const DESTROY_PROFILE_PAGE_COMMENT = gql`
  ${PROFILE_PAGE_COMMENT_FIELDS}
  mutation DestroyProfilePageComment($input: DestroyProfilePageCommentInput!) {
    destroyProfilePageComment(input: $input) {
      profilePageComment {
        ...ProfilePageCommentFields
      }
    }
  }
`;

export const MOVE_PROFILE_PAGE_QUESTION_TO_POSITION = gql`
  ${BASE_PROFILE_PAGE_QUESTION_FIELDS}
  mutation MoveProfilePageQuestionToPosition(
    $input: MoveProfilePageQuestionToPositionInput!
  ) {
    moveProfilePageQuestionToPosition(input: $input) {
      profilePageQuestion {
        ...BaseProfilePageQuestionFields
      }
    }
  }
`;

export const ADD_PHOTO_TO_COLLAGES_ALBUM = gql`
  ${COLLAGES_PHOTO_FIELDS}
  mutation AddPhotoToCollagesAlbum(
    $input: AddPhotoToCollagesAlbumInput!
    $authUserGroupId: ID
  ) {
    addPhotoToCollagesAlbum(input: $input) {
      photo {
        ...CollagesPhotoFields
      }
    }
  }
`;

export const REMOVE_PHOTO_FROM_COLLAGES_ALBUM = gql`
  mutation RemovePhotoFromCollagesAlbum(
    $input: RemovePhotoFromCollagesAlbumInput!
  ) {
    removePhotoFromCollagesAlbum(input: $input) {
      success
    }
  }
`;

export const CREATE_COLLAGES_ALBUM = gql`
  ${BASE_COLLAGES_ALBUM_FIELDS}
  mutation CreateCollagesAlbum($input: CreateCollagesAlbumInput!) {
    createCollagesAlbum(input: $input) {
      errors {
        field
        messages
      }
      collagesAlbum {
        ...BaseCollagesAlbumFields
      }
    }
  }
`;

export const UPDATE_COLLAGES_ALBUM = gql`
  ${BASE_COLLAGES_ALBUM_FIELDS}
  mutation UpdateCollagesAlbum($input: UpdateCollagesAlbumInput!) {
    updateCollagesAlbum(input: $input) {
      errors {
        field
        messages
      }
      collagesAlbum {
        ...BaseCollagesAlbumFields
      }
    }
  }
`;

export const DESTROY_COLLAGES_ALBUM = gql`
  ${BASE_COLLAGES_ALBUM_FIELDS}
  mutation DestroyCollagesAlbum($input: DestroyCollagesAlbumInput!) {
    destroyCollagesAlbum(input: $input) {
      collagesAlbum {
        ...BaseCollagesAlbumFields
      }
    }
  }
`;

export const LIKE_COLLAGES_PHOTO = gql`
  ${COLLAGES_PHOTO_FIELDS}
  mutation LikeCollagesPhoto(
    $input: LikeCollagesPhotoInput!
    $authUserGroupId: ID
  ) {
    likeCollagesPhoto(input: $input) {
      collagesPhoto {
        ...CollagesPhotoFields
      }
    }
  }
`;

export const REMOVE_LIKE_FROM_COLLAGES_PHOTO = gql`
  ${COLLAGES_PHOTO_FIELDS}
  mutation RemoveLikeFromCollagesPhoto(
    $input: RemoveLikeFromCollagesPhotoInput!
    $authUserGroupId: ID
  ) {
    removeLikeFromCollagesPhoto(input: $input) {
      collagesPhoto {
        ...CollagesPhotoFields
      }
    }
  }
`;

export const UPDATE_COLLAGES_SETUP = gql`
  ${COLLAGES_SETUP_FIELDS}
  mutation UpdateCollagesSetup($input: UpdateCollagesSetupInput!) {
    updateCollagesSetup(input: $input) {
      errors {
        field
        messages
      }
      collagesSetup {
        ...CollagesSetupFields
      }
    }
  }
`;

export const UPDATE_REPORTS_SETUP = gql`
  ${REPORTS_SETUP_FIELDS}
  mutation UpdateReportsSetup($input: UpdateReportsSetupInput!) {
    updateReportsSetup(input: $input) {
      errors {
        field
        messages
      }
      reportsSetup {
        ...ReportsSetupFields
      }
    }
  }
`;

export const CREATE_REPORTS_INSTANCE = gql`
  ${REPORTS_INSTANCE_FIELDS}
  mutation CreateReportsInstance($input: CreateReportsInstanceInput!) {
    createReportsInstance(input: $input) {
      errors {
        field
        messages
      }
      reportsInstance {
        ...ReportsInstanceFields
      }
    }
  }
`;

export const UPDATE_REPORTS_INSTANCE = gql`
  ${REPORTS_INSTANCE_FIELDS}
  mutation UpdateReportsInstance($input: UpdateReportsInstanceInput!) {
    updateReportsInstance(input: $input) {
      errors {
        field
        messages
      }
      reportsInstance {
        ...ReportsInstanceFields
      }
    }
  }
`;

export const DESTROY_REPORTS_INSTANCE = gql`
  mutation DestroyReportsInstance($input: DestroyReportsInstanceInput!) {
    destroyReportsInstance(input: $input) {
      success
      reportsInstanceId
    }
  }
`;

export const CREATE_QUOTES_INSTANCE = gql`
  ${BASE_QUOTE_FIELDS}
  mutation CreateQuotesInstance($input: CreateQuotesInstanceInput!) {
    createQuotesInstance(input: $input) {
      errors {
        field
        messages
      }
      quotesInstance {
        ...BaseQuoteFields
      }
    }
  }
`;

export const DESTROY_QUOTES_INSTANCE = gql`
  ${BASE_QUOTE_FIELDS}
  mutation DestroyQuotesInstance($input: DestroyQuotesInstanceInput!) {
    destroyQuotesInstance(input: $input) {
      quotesInstance {
        ...BaseQuoteFields
      }
    }
  }
`;

export const UPDATE_QUOTES_SETUP = gql`
  ${QUOTES_SETUP_FIELDS}
  mutation UpdateQuotesSetup($input: UpdateQuotesSetupInput!) {
    updateQuotesSetup(input: $input) {
      errors {
        field
        messages
      }
      quotesSetup {
        ...QuotesSetupFields
      }
    }
  }
`;

export const LIKE_QUOTES_INSTANCE = gql`
  ${QUOTE_FIELDS}
  mutation LikeQuotesInstance(
    $input: LikeQuotesInstanceInput!
    $authUserGroupId: ID
  ) {
    likeQuotesInstance(input: $input) {
      quotesInstance {
        ...QuoteFields
      }
    }
  }
`;

export const REMOVE_LIKE_FROM_QUOTES_INSTANCE = gql`
  ${QUOTE_FIELDS}
  mutation RemoveLikeFromQuotesInstance(
    $input: RemoveLikeFromQuotesInstanceInput!
    $authUserGroupId: ID
  ) {
    removeLikeFromQuotesInstance(input: $input) {
      quotesInstance {
        ...QuoteFields
      }
    }
  }
`;

export const UPDATE_RANKINGS_SETUP = gql`
  ${RANKINGS_SETUP_FIELDS}
  mutation UpdateRankingsSetup($input: UpdateRankingsSetupInput!) {
    updateRankingsSetup(input: $input) {
      errors {
        field
        messages
      }
      rankingsSetup {
        ...RankingsSetupFields
      }
    }
  }
`;

export const CREATE_RANKINGS_QUESTION = gql`
  ${RANKING_QUESTION_FIELDS}
  mutation CreateRankingsQuestion(
    $input: CreateRankingsQuestionInput!
    $authUserGroupId: ID
  ) {
    createRankingsQuestion(input: $input) {
      errors {
        field
        messages
      }
      rankingsQuestion {
        ...RankingQuestionFields
      }
    }
  }
`;

export const UPDATE_RANKINGS_QUESTION = gql`
  ${RANKING_QUESTION_FIELDS}
  mutation UpdateRankingsQuestion(
    $input: UpdateRankingsQuestionInput!
    $authUserGroupId: ID
  ) {
    updateRankingsQuestion(input: $input) {
      errors {
        field
        messages
      }
      rankingsQuestion {
        ...RankingQuestionFields
      }
    }
  }
`;

export const DESTROY_RANKINGS_QUESTION = gql`
  ${RANKING_QUESTION_FIELDS}
  mutation DestroyRankingsQuestion(
    $input: DestroyRankingsQuestionInput!
    $authUserGroupId: ID
  ) {
    destroyRankingsQuestion(input: $input) {
      rankingsQuestion {
        ...RankingQuestionFields
      }
    }
  }
`;

export const CREATE_RANKINGS_ANSWER = gql`
  ${RANKING_QUESTION_FIELDS}
  mutation CreateRankingsAnswer(
    $input: CreateRankingsAnswerInput!
    $authUserGroupId: ID
  ) {
    createRankingsAnswer(input: $input) {
      errors {
        field
        messages
      }
      rankingsAnswer {
        rankingsQuestion {
          ...RankingQuestionFields
        }
      }
    }
  }
`;

export const UPDATE_RANKINGS_ANSWER = gql`
  ${RANKING_QUESTION_FIELDS}
  mutation UpdateRankingsAnswer(
    $input: UpdateRankingsAnswerInput!
    $authUserGroupId: ID
  ) {
    updateRankingsAnswer(input: $input) {
      errors {
        field
        messages
      }
      rankingsAnswer {
        rankingsQuestion {
          ...RankingQuestionFields
        }
      }
    }
  }
`;

export const CREATE_CUSTOM_LIST = gql`
  ${CUSTOM_LIST_FIELDS}
  mutation CreateCustomList($input: CreateCustomListInput!) {
    createCustomList(input: $input) {
      customList {
        ...CustomListFields
      }
    }
  }
`;

export const UPDATE_CUSTOM_LIST = gql`
  ${CUSTOM_LIST_FIELDS}
  mutation UpdateCustomList($input: UpdateCustomListInput!) {
    updateCustomList(input: $input) {
      customList {
        ...CustomListFields
      }
    }
  }
`;

export const DELETE_CUSTOM_LIST = gql`
  ${CUSTOM_LIST_FIELDS}
  mutation DeleteCustomList($input: DeleteCustomListInput!) {
    deleteCustomList(input: $input) {
      customList {
        ...CustomListFields
      }
    }
  }
`;

export const CREATE_CUSTOM_LIST_OPTION = gql`
  ${CUSTOM_LIST_OPTION_FIELDS}
  mutation CreateCustomListOption($input: CreateCustomListOptionInput!) {
    createCustomListOption(input: $input) {
      customListOption {
        ...CustomListOptionFields
      }
    }
  }
`;

export const UPDATE_CUSTOM_LIST_OPTION = gql`
  ${CUSTOM_LIST_OPTION_FIELDS}
  mutation UpdateCustomListOption($input: UpdateCustomListOptionInput!) {
    updateCustomListOption(input: $input) {
      customListOption {
        ...CustomListOptionFields
      }
    }
  }
`;

export const DELETE_CUSTOM_LIST_OPTION = gql`
  ${CUSTOM_LIST_OPTION_FIELDS}
  mutation DeleteCustomListOption($input: DeleteCustomListOptionInput!) {
    deleteCustomListOption(input: $input) {
      customListOption {
        ...CustomListOptionFields
      }
    }
  }
`;

export const CREATE_DATA_EXPORT = gql`
  mutation CreateDataExport($input: CreateDataExportInput!) {
    createDataExport(input: $input) {
      success
    }
  }
`;
