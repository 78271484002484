import React from 'react';
import { general } from '~/assets/imgs';
import Button from '~/components/Button';
import GroupCell from '~/components/GroupCell';
import GroupRequestCell from '~/components/GroupRequestCell';
import SectionHeader from '~/components/SectionHeader';
import { GroupUser } from '~/data/models/group';
import { Spacer } from '~/theme/wrappers';
import { t } from '~/utils/i18n';
import {
  Container,
  LNContainer,
  TopBoxView,
  CommunityImage,
  TopBoxTitle,
  Content,
  InviteBox,
  InviteBoxTitle,
  InviteBoxText,
  GroupContainer,
  InfoContent,
  NoGroupTitle,
  NoGroupDescp,
  Loading,
  ErrorMessage,
} from './style';

export interface GraduationProps {
  userName: string;
  groupUsersAccepted: GroupUser[];
  groupUsersPending: GroupUser[];
  onSelectedGroup: (groupId: string, userGroupId: string) => void;
  onJoinGroup: () => void;
  onRemoveRequest: (groupId: string) => void;
  loading: boolean;
  errorMessage?: string;
}

export default function GraduationTR({
  userName,
  groupUsersAccepted,
  groupUsersPending,
  onSelectedGroup,
  onJoinGroup,
  onRemoveRequest,
  loading,
  errorMessage,
}: GraduationProps): JSX.Element {
  const noGroup =
    groupUsersAccepted.length == 0 && groupUsersPending.length == 0;

  return (
    <Container>
      <LNContainer />
      <TopBoxView>
        <CommunityImage source={general.community_avatars} />
        <TopBoxTitle>{t('gradutionTRTab.title')}</TopBoxTitle>
      </TopBoxView>
      <Content>
        <InviteBox>
          <InviteBoxTitle>{t('gradutionTRTab.inviteBoxTitle')}</InviteBoxTitle>
          <InviteBoxText>{t('gradutionTRTab.inviteBoxDesc1')}</InviteBoxText>
          <InviteBoxText>{t('gradutionTRTab.inviteBoxDesc2')}</InviteBoxText>
          <InviteBoxText>{t('gradutionTRTab.inviteBoxDesc3')}</InviteBoxText>
          <Spacer size={16} />
          <Button
            testID={'buttonJoinGroup'}
            text={
              noGroup && !loading
                ? t('lobby.joinGroup')
                : t('lobby.joinAnotherGroup')
            }
            type={'primary-brand-01'}
            onPress={onJoinGroup}
          />
        </InviteBox>
      </Content>
      <GroupContainer>
        {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
        {noGroup && !loading && !errorMessage && (
          <InfoContent>
            <NoGroupTitle>{`${t(
              'gradutionTRTab.hey',
            )} ${userName},`}</NoGroupTitle>
            <NoGroupDescp>{t('lobby.noGroupDescp')}</NoGroupDescp>
          </InfoContent>
        )}

        {!noGroup && groupUsersAccepted && groupUsersAccepted.length > 0 && (
          <>
            <Spacer size={16} />
            <SectionHeader title={t('lobby.sectionGroups')} />
            {groupUsersAccepted.map((groupUser) => (
              <GroupCell
                key={groupUser.id}
                group={groupUser.group}
                onPress={() =>
                  groupUser.group &&
                  onSelectedGroup(groupUser.group.id, groupUser.id)
                }
              />
            ))}
          </>
        )}

        {groupUsersPending && groupUsersPending.length > 0 && (
          <>
            <Spacer size={16} />
            <SectionHeader title={t('lobby.sectionRequests')} />
            {groupUsersPending.map((groupUser, i) => (
              <GroupRequestCell
                testID={`groupRequestCell:${i}`}
                type="light"
                key={groupUser.id}
                groupUser={groupUser}
                onRemove={() =>
                  groupUser.group && onRemoveRequest(groupUser.group.id)
                }
              />
            ))}
          </>
        )}
        {loading && <Loading />}
      </GroupContainer>
    </Container>
  );
}
