import React, { useCallback, useState } from 'react';
import keyBy from 'lodash/keyBy';
import {
  Dimensions,
  NativeScrollEvent,
  NativeSyntheticEvent,
} from 'react-native';
import EventCMSCardForTurkey from '~/components/EventCMSCardForTurkey';
import { EventCalendarProps } from '~/components/EventCalendar';
import NavHeader from '~/components/NavHeader';
import SwitchSelector from '~/components/SwitchSelector';
import { LINKS } from '~/data/constants';
import { Event } from '~/data/models/calendar';
import { AuthorTurkeyCMS } from '~/data/models/cmsTurkey';
import { Spacer } from '~/theme/wrappers';
import { t } from '~/utils/i18n';
import { EnchancedCMSEvent } from './..';
import {
  Container,
  TabContainer,
  ScrollView,
  MySavedEventList,
  MyEventCardContainer,
  EventsContainer,
  MySavedEventsHeader,
  DotTrackWrapper,
  Dot,
  MyAppliedEventsHeader,
  MyAppliedEventCardContainer,
  UpcomingEventCardContainer,
  Loading,
  ErrorMessage,
  EmptyMessage,
  PastEventsHeader,
  UpcomingEventsHeader,
  PastEventCardContainer,
} from './style';

type CalendarLayoutProps = {
  onBack: () => void;
  onAddEvent: EventCalendarProps['onAddEvent'];
  onEventOpen: (eventId: string) => void;
  myEvents: Event[];
  myAttendedEvents: Event[];
  upcomingEvents: Event[];
  authorsTurkeyCMS: AuthorTurkeyCMS[];
  pastEventsTurkeyCMS: EnchancedCMSEvent[];
  savedTurkeyCMSEvents: EnchancedCMSEvent[];
  appliedTurkeyCMSEvents: EnchancedCMSEvent[];
  upcomingEventsTurkeyCMS: EnchancedCMSEvent[];
  loading?: boolean;
  errorMessage?: string;
  onPressCMSEvent: (title: string, url: string) => void;
  onSaveToggleEvent: (eventId: string) => void;
};

enum CalendarViewEnum {
  MyEvents = 'myEvents',
  UpcomingEvents = 'upcomingEvents',
}

const { width } = Dimensions.get('window');
const SCREEN_HORIZONTAL_PADDING = 16 * 2;

export default function EventsTR({
  myEvents,
  onPressCMSEvent,
  upcomingEventsTurkeyCMS,
  authorsTurkeyCMS,
  errorMessage,
  loading,
  savedTurkeyCMSEvents,
  appliedTurkeyCMSEvents,
  pastEventsTurkeyCMS,
  onSaveToggleEvent,
}: CalendarLayoutProps): JSX.Element {
  const [calendarKey, setCalendarKey] = useState<CalendarViewEnum>(
    CalendarViewEnum.UpcomingEvents,
  );
  const [mySavedEventIndex, setMySavedEventIndex] = useState(0);

  // const renderUpcomingEvents = (event: { item: Event }) => {
  //   return <UpcomingEventsCard openEvent={onEventOpen} event={event.item} />;
  // };

  const mappedAuthorsByID = keyBy(authorsTurkeyCMS, 'sys.id');

  const renderEventCardTurkeyCMS = (eventData: EnchancedCMSEvent) => {
    const event = eventData.event;

    if (!event) {
      return null;
    }
    return (
      <EventCMSCardForTurkey
        isPast={eventData.isPast}
        isApplied={eventData.isApplied}
        isSaved={eventData.isSaved}
        event={event}
        eventHost={mappedAuthorsByID[event.host?.sys.id || '']}
        onPress={() => {
          onPressCMSEvent(
            event.title,
            LINKS.TR.WEBSITE_URL + '/events/' + event.slug,
          );
        }}
        onSaveToggleEvent={() => onSaveToggleEvent(event.sys.id)}
        onApply={() => {
          event.joinLink &&
            !eventData.isPast &&
            onPressCMSEvent(event.title, event.joinLink);
        }}
      />
    );
  };

  const onScrollMyHostedEvents = useCallback(
    (event: NativeSyntheticEvent<NativeScrollEvent>) => {
      const slideSize = event.nativeEvent.layoutMeasurement.width;
      const index = event.nativeEvent.contentOffset.x / slideSize;
      const roundIndex = Math.round(index);
      setMySavedEventIndex(roundIndex);
    },
    [],
  );

  return (
    <Container>
      <NavHeader title={t('g.events')} type={'main'} />
      <TabContainer>
        <SwitchSelector
          items={[
            {
              key: CalendarViewEnum.UpcomingEvents,
              title: t('eventsTRTab.upcomingEvents'),
            },
            {
              key: CalendarViewEnum.MyEvents,
              title: t('eventsTRTab.myEvents'),
            },
          ]}
          selectedItemKey={calendarKey}
          onSelectedItem={(key) => {
            setCalendarKey(key as CalendarViewEnum);
          }}
        />
      </TabContainer>

      <ScrollView showsVerticalScrollIndicator={false}>
        {loading && <Loading />}
        {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
        {!errorMessage && (
          <>
            {calendarKey === CalendarViewEnum.MyEvents && (
              <>
                <MySavedEventsHeader title={t('eventsTRTab.savedEvents')} sub />
                {savedTurkeyCMSEvents.length === 0 && (
                  <EmptyMessage>{t('eventsTRTab.noSavedEvents')}</EmptyMessage>
                )}
                <MySavedEventList<React.ElementType>
                  data={savedTurkeyCMSEvents}
                  renderItem={({ item }: { item: EnchancedCMSEvent }) => (
                    <MyEventCardContainer
                      style={{ width: width - SCREEN_HORIZONTAL_PADDING }}
                    >
                      {renderEventCardTurkeyCMS(item)}
                    </MyEventCardContainer>
                  )}
                  keyExtractor={(item: EnchancedCMSEvent) => item.event.sys.id}
                  horizontal
                  showsHorizontalScrollIndicator={false}
                  snapToInterval={width - SCREEN_HORIZONTAL_PADDING}
                  decelerationRate={0}
                  onScroll={onScrollMyHostedEvents}
                />
                {savedTurkeyCMSEvents.length > 0 && (
                  <DotTrackWrapper>
                    {savedTurkeyCMSEvents.map((item, i) => (
                      <Dot
                        key={item.event.sys.id}
                        isLast={i === myEvents.length - 1}
                        isActive={i === mySavedEventIndex}
                      />
                    ))}
                  </DotTrackWrapper>
                )}
                <Spacer size={16} />
                <MyAppliedEventsHeader
                  sub
                  title={t('eventsTRTab.attendedEventsTitle')}
                />
                {appliedTurkeyCMSEvents.length === 0 && (
                  <EmptyMessage>
                    {t('eventsTRTab.noAttendedEvents')}
                  </EmptyMessage>
                )}
                {appliedTurkeyCMSEvents.map((item) => (
                  <MyAppliedEventCardContainer key={item.event.sys.id}>
                    {renderEventCardTurkeyCMS(item)}
                  </MyAppliedEventCardContainer>
                ))}
              </>
            )}

            {calendarKey === CalendarViewEnum.UpcomingEvents && (
              <EventsContainer>
                {/* {upcomingEvents.map((item) => (
              <UpcomingEventCardContainer key={item.id}>
                {renderUpcomingEvents({ item })}
              </UpcomingEventCardContainer>
            ))} */}
                <UpcomingEventsHeader
                  sub
                  title={t('eventsTRTab.upcomingEvents')}
                />
                {upcomingEventsTurkeyCMS.length === 0 && (
                  <EmptyMessage>
                    {t('eventsTRTab.noUpcomingEvents')}
                  </EmptyMessage>
                )}
                {upcomingEventsTurkeyCMS.map((item) => (
                  <UpcomingEventCardContainer key={item.event.sys.id}>
                    {renderEventCardTurkeyCMS(item)}
                  </UpcomingEventCardContainer>
                ))}
                <PastEventsHeader sub title={t('eventsTRTab.pastEvents')} />
                {pastEventsTurkeyCMS.map((item) => (
                  <PastEventCardContainer key={item.event.sys.id}>
                    {renderEventCardTurkeyCMS(item)}
                  </PastEventCardContainer>
                ))}
                {pastEventsTurkeyCMS.length === 0 && (
                  <EmptyMessage>{t('eventsTRTab.noPastEvents')}</EmptyMessage>
                )}
              </EventsContainer>
            )}
          </>
        )}
      </ScrollView>
    </Container>
  );
}
