import { User } from '~/data/models/user';
import { t } from '~/utils/i18n';

export const isEmpty = (value: string): boolean => {
  return value.trim().length === 0;
};

export function isValidName(name: string): boolean {
  return name.length >= 2 && !isEmpty(name);
}

export function isValidEmail(email: string): boolean {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
}

export function isValidPhone(phone: string): boolean {
  return phone.length >= 8;
}

export function isValidPassword(password: string): boolean {
  return password.length >= 6;
}

export function validatePassword(password: string): string[] {
  const errors = [];
  if (password.length < 8) {
    errors.push(t('inputValidation.passwordShort'));
  }
  if (!/\d/.test(password)) {
    errors.push(t('inputValidation.passwordMissingDigit'));
  }
  if (!/[^a-zA-Z0-9]/.test(password)) {
    errors.push(t('inputValidation.passwordMissingSpecialChar'));
  }
  return errors;
}

export function userHasText(user: User, text: string): boolean {
  return (
    user.firstName.toLowerCase().includes(text.toLocaleLowerCase()) ||
    user.lastName.toLowerCase().includes(text.toLowerCase())
  );
}

export function validateExistingUser(
  emailExists: boolean,
  phoneExists: boolean,
): string | undefined {
  if (emailExists && phoneExists) {
    return t('inputValidation.emailAndPhoneExists');
  } else if (emailExists) {
    return t('inputValidation.emailExists');
  } else if (phoneExists) {
    return t('inputValidation.phoneExists');
  }
}
