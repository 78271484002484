import React from 'react';
import { LeaderboardUser } from '~/data/models/referralLeaderboard';
import {
  Container,
  OrderNoBox,
  OrderNo,
  Name,
  Score,
  ScoreBubble,
} from './style';

type ReferralCellProps = {
  leaderboardUser?: LeaderboardUser | null;
  highlight?: boolean;
};

export default function ReferralCell({
  leaderboardUser,
  highlight,
}: ReferralCellProps): JSX.Element {
  return (
    <Container highlight={highlight}>
      <OrderNoBox>
        <OrderNo>{leaderboardUser?.position || 0} .</OrderNo>
      </OrderNoBox>
      <Name>
        {leaderboardUser?.user.firstName || ''}{' '}
        {leaderboardUser?.user.lastName || ''}
      </Name>
      <Score>{leaderboardUser?.points || 0}</Score>
      <ScoreBubble />
    </Container>
  );
}
