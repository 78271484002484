import React from 'react';
import { FlatList, RefreshControl } from 'react-native';
import NavHeader from '~/components/NavHeader';
import ReferralCell from '~/components/ReferralCell';
import SectionHeader from '~/components/SectionHeader';
import { Loading } from '~/components/common/style';
import { LeaderboardUser } from '~/data/models/referralLeaderboard';
import { t } from '~/utils/i18n';
import { Container, ContentContainer, VSpacer } from './style';

type LeaderboardLayoutProps = {
  onBack: () => void;
  leaderboardUser?: LeaderboardUser | null;
  users: LeaderboardUser[];
  onLoadMore: () => void;
  onRefresh: () => void;
  loading: boolean;
  meId?: string;
};

export default function Leaderboard({
  onBack,
  onLoadMore,
  onRefresh,
  users,
  loading,
  leaderboardUser,
  meId,
}: LeaderboardLayoutProps): JSX.Element {
  const renderItem = ({ item }: { item: LeaderboardUser }) => {
    return (
      <>
        <VSpacer size={4} />
        <ReferralCell
          highlight={meId === item.user.id}
          leaderboardUser={item}
        />
      </>
    );
  };

  return (
    <Container>
      <NavHeader
        backButtonTestID={'buttonBack'}
        title={t('screens.leaderboard')}
        onBackPress={onBack}
      />
      <ContentContainer>
        <VSpacer size={8} />
        <SectionHeader title={t('leaderboard.userRankTitle')} sub />
        <ReferralCell leaderboardUser={leaderboardUser} />
        <VSpacer size={16} />
        <SectionHeader title={t('leaderboard.rankingTitle')} sub />
        {loading && users.length <= 0 && <Loading />}
        <FlatList
          testID={'flatList'}
          refreshControl={
            <RefreshControl refreshing={loading} onRefresh={onRefresh} />
          }
          showsVerticalScrollIndicator={false}
          data={users}
          renderItem={renderItem}
          onEndReachedThreshold={0.3}
          onEndReached={() => users.length < 100 && onLoadMore && onLoadMore()}
          keyExtractor={(user) => user.user.id}
        />
      </ContentContainer>
    </Container>
  );
}
