import React, { useState, useEffect } from 'react';
import {
  useNavigation,
  CompositeNavigationProp,
} from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import { Linking, Share } from 'react-native';
import { LINKS } from '~/data/constants';
import { DATA_POLICY, TERM } from '~/data/derivedConstants';
import { useMeQuery } from '~/data/operations/auth/me';
import { ProfileStackParamList, RootStackParamList } from '~/navigation/types';
import {
  getCurrentVersion,
  getReferAFriendUrl,
  versionNeedUpdated,
} from '~/utils/helpers';
import ProfileLayout from './layout';

type ProfileNavProp = CompositeNavigationProp<
  StackNavigationProp<ProfileStackParamList, 'Profile', string | undefined>,
  StackNavigationProp<RootStackParamList>
>;

export default function Profile() {
  const navigation = useNavigation<ProfileNavProp>();
  const [needUpdate, setNeedUpdate] = useState<boolean | undefined>();
  const { loading: loadingMe, error: meError, data: meData } = useMeQuery();

  // const {
  //   data: leaderboardData,
  //   error: leaderboardError,
  //   loading: leaderboardLoading,
  // } = useLeaderboardUsersQuery({
  //   skip: !meData?.me?.id,
  //   variables: {
  //     user: meData?.me?.id,
  //   },
  // });

  // const leaderboardCurrentUser =
  //   leaderboardData?.leaderboardUsers?.edges?.[0]?.node;

  const version = getCurrentVersion(true);

  useEffect(() => {
    versionNeedUpdated().then((isNeeded) => {
      setNeedUpdate(isNeeded);
    });
  }, []);

  const referInviteURL =
    meData?.me?.inviteCode && getReferAFriendUrl(meData?.me?.inviteCode);

  const onShareReferLink = () => {
    if (referInviteURL) {
      Share.share({
        message: referInviteURL,
        url: referInviteURL,
      });
    }
  };

  return (
    <ProfileLayout
      referInviteURL={referInviteURL}
      error={meError?.message}
      loading={loadingMe}
      meData={meData}
      version={version}
      needUpdate={needUpdate}
      onShareReferLink={onShareReferLink}
      onContact={() => Linking.openURL(LINKS.TR.WPP)}
      onEditProfile={() => navigation.navigate('ProfileSetupTurkeyEntrance')}
      onNotification={() => navigation.navigate('NotificationSettings')}
      onAppSetup={() => navigation.navigate('AppSetup')}
      onRateApp={() =>
        navigation.navigate('Browser', {
          title: '',
          url: LINKS.RATE,
        })
      }
      onImprintAndLicences={() =>
        navigation.navigate('Browser', {
          title: TERM.title,
          url: TERM.url,
        })
      }
      onDataPolicy={() =>
        navigation.navigate('Browser', {
          title: DATA_POLICY.title,
          url: DATA_POLICY.url,
        })
      }
    />
  );
}
