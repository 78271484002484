import { QueryHookOptions, QueryResult, useQuery } from '@apollo/client';
import { gql } from '@apollo/client';
import { MockedResponse } from '@apollo/client/testing';
import { IBlogFields } from '../cmsForTurkey';

export const BLOGS_CMS_FOR_TURKEY = gql`
  query BlogCollection($limit: Int, $skip: Int, $tags: [String] = null) {
    blogCollection(
      limit: $limit
      skip: $skip
      where: { filterTags: { tag_in: $tags } }
    ) {
      total
      items {
        title
        slug
        blogType
        summary
        filterTagsCollection {
          items {
            tag
          }
        }
        thumbnail {
          url
        }
        sys {
          id
        }
      }
    }
  }
`;

export type BlogTurkeyCMSType = {
  title: IBlogFields['title'];
  summary: IBlogFields['summary'];
  slug: IBlogFields['slug'];
  filterTagsCollection: {
    items: {
      tag: string;
    }[];
  };
  blogType: IBlogFields['blogType'];
  thumbnail: {
    url: string;
  };
  sys: {
    id: string;
  };
};

export interface BlogsCMSResponseType {
  blogCollection: {
    total: number;
    items: BlogTurkeyCMSType[];
  };
}

interface Variables {
  tags: string[] | null;
  skip?: number;
  limit?: number;
}

export const useBlogsTurkeyCMS = (
  options?: QueryHookOptions<BlogsCMSResponseType, Variables>,
): QueryResult<BlogsCMSResponseType, Variables> => {
  return useQuery(BLOGS_CMS_FOR_TURKEY, {
    ...options,
    context: {
      client: 'cmsForTurkey',
    },
  });
};

export function createBlogsTurkeyCMSMock({
  variables,
  data,
  error,
}: {
  variables?: Variables;
  data?: BlogsCMSResponseType;
  error?: Error;
}): MockedResponse {
  return {
    request: {
      query: BLOGS_CMS_FOR_TURKEY,
      variables,
    },
    result: {
      data,
    },
    error,
  };
}
