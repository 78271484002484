import { LinearGradient } from 'expo-linear-gradient';
import styled from 'styled-components/native';

interface IContainer {
  highlight?: boolean;
}

export const Container = styled.View<IContainer>`
  flex-direction: row;
  align-items: center;
  border-width: 2px;
  border-color: ${({ theme, highlight }) =>
    highlight ? theme.color.brand_02 : theme.color.base.c3};
  height: 56px;
  background-color: ${({ theme, highlight }) =>
    highlight ? theme.color.brand_02 : theme.color.base.c0};
  border-radius: 14px;
  padding: 16px;
`;

export const OrderNoBox = styled.View`
  padding: 9px;
  border-radius: 9px;
  height: 40px;
  border-width: 1px;
  border-color: ${({ theme }) => theme.color.brand_02};
  background-color: ${({ theme }) => theme.color.brand_02};
  margin-right: 16px;
`;

export const OrderNo = styled.Text`
  color: ${({ theme }) => theme.color.base.c0};
  font-size: 14px;
  line-height: 20px;
  font-weight: 700;
`;

export const Name = styled.Text`
  color: ${({ theme }) => theme.color.base.c9};
  font-weight: 600;
  font-size: 16px;
  flex: 1;
`;

export const Score = styled.Text`
  color: ${({ theme }) => theme.color.base.c9};
  margin-right: 4px;
  font-weight: 600;
`;

export const ScoreBubble = styled(LinearGradient).attrs(({ theme }) => ({
  colors: theme.gradient.primary.cs02,
  start: { x: 0, y: 0 },
  end: { x: 1, y: 1 },
}))`
  width: 12px;
  height: 12px;
  border-radius: 12px;
  margin-right: 12px;
`;
