import { QueryHookOptions, QueryResult, useQuery } from '@apollo/client';
import { gql } from '@apollo/client';
import { MockedResponse } from '@apollo/client/testing';
import { IPeopleFields } from '../cmsForTurkey';

export const AUTHORS_CMS_FOR_TURKEY = gql`
  query AuthorsCollections($authorsIDs: [String]) {
    peopleCollection(where: { sys: { id_in: $authorsIDs } }) {
      items {
        fullName
        avatar {
          url
        }
        sys {
          id
        }
      }
    }
  }
`;

export type AuthorTurkeyCMSType = {
  fullName: IPeopleFields['fullName'];
  avatar: {
    url: string;
  };
  sys: {
    id: string;
  };
};

export interface AuthorsCMSResponseType {
  peopleCollection: {
    items: AuthorTurkeyCMSType[];
  };
}

interface Variables {
  authorsIDs: (string | undefined)[];
}

export const useAuthorsTurkeyCMS = (
  options?: QueryHookOptions<AuthorsCMSResponseType, Variables>,
): QueryResult<AuthorsCMSResponseType, Variables> => {
  return useQuery(AUTHORS_CMS_FOR_TURKEY, {
    ...options,
    context: {
      client: 'cmsForTurkey',
    },
  });
};

export function createAuthorsTurkeyCMSMock({
  variables,
  data,
  error,
}: {
  variables?: Variables;
  data?: AuthorsCMSResponseType;
  error?: Error;
}): MockedResponse {
  return {
    request: {
      query: AUTHORS_CMS_FOR_TURKEY,
      variables,
    },
    result: {
      data,
    },
    error,
  };
}
