import { LinearGradient } from 'expo-linear-gradient';
import styled from 'styled-components/native';
import ImageComp from '~/components/Image';

const LNBG_HEIGHT = 220;

export const Container = styled.ScrollView`
  background-color: ${({ theme }) => theme.color.base.c0};
`;

export const LNContainer = styled(LinearGradient).attrs(({ theme }) => ({
  colors: [theme.color.brand_02, theme.color.base.c2, theme.color.brand_02],
  start: { x: 0, y: 0 },
  end: { x: 1, y: 1 },
}))`
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  height: ${({ theme }) => LNBG_HEIGHT + theme.safeAreaInsets.top}px;
`;

export const TopBoxView = styled.View`
  padding-top: ${({ theme }) => theme.safeAreaInsets.top}px;
  padding-bottom: 12px;
  align-items: center;
`;

export const CommunityImage = styled(ImageComp)`
  width: 199px;
  height: 100px;
`;

export const TopBoxTitle = styled.Text`
  padding-top: 16px;
  padding-horizontal: 20px;
  text-align: center;
  font-weight: 600;
  line-height: 24px;
  font-size: 20px;
  color: ${({ theme }) => theme.color.brand_02};
`;

export const Content = styled.View`
  padding-horizontal: 16px;
  padding-bottom: 16px;
`;

export const InviteBox = styled.View`
  background-color: ${({ theme }) => theme.color.base.c0};
  margin-top: 16px;
  border-radius: 14px;
  padding: 8px;
  box-shadow: 0px 3px 2px ${({ theme }) => theme.color.base.c2};
`;

export const InviteBoxTitle = styled.Text`
  font-size: 14px;
  font-weight: 600;
  color: ${({ theme }) => theme.color.base.c9};
  padding-vertical: 8px;
  padding-bottom: 16px;
  text-align: center;
`;

export const InviteBoxText = styled.Text`
  background-color: ${({ theme }) => theme.color.brand_02};
  color: ${({ theme }) => theme.color.brand_02};
  margin-bottom: 4px;
  border-radius: 6px;
  line-height: 32px;
  padding-horizontal: 16px;
  overflow: hidden;
  font-weight: 600;
  font-size: 12px;
`;

export const GroupContainer = styled.View`
  flex: 1;
  padding-horizontal: 16px;
  padding-bottom: 32px;
`;

export const InfoContent = styled.View`
  align-items: center;
  padding: ${({ theme }) => theme.spacing.medium};
`;

export const NoGroupTitle = styled.Text`
  margin-top: 20px;
  font-family: ${({ theme }) => theme.fontFamily.core.w600};
  color: ${({ theme }) => theme.color.base.c9};
  font-size: 38px;
  line-height: 43px;
`;

export const NoGroupDescp = styled.Text`
  margin-top: 32px;
  text-align: center;
  font-family: ${({ theme }) => theme.fontFamily.core.w400};
  color: ${({ theme }) => theme.color.base.c9};
  font-size: 18px;
  line-height: 23px;
`;

export const Loading = styled.ActivityIndicator.attrs(({ theme }) => ({
  color: theme.color.base.c4,
}))`
  padding-vertical: 10px;
`;

export const ErrorMessage = styled.Text`
  font-family: ${({ theme }) => theme.fontFamily.core.w400};
  color: ${({ theme }) => theme.color.error};
  font-size: 18px;
  line-height: 23px;
  text-align: center;
  padding-vertical: 10px;
`;
