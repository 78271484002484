import { Dimensions } from 'react-native';
import styled from 'styled-components/native';
import Image from '~/components/Image';

const { width } = Dimensions.get('window');
const CONTAINER_WIDTH = width - 32;
const CONTAINER_PADDING = 8;
export const BLOG_IMAGE_WIDTH = 96;

interface IBlogImage {
  heightRatio: number;
}

export const Container = styled.View`
  background-color: ${({ theme }) => theme.color.base.c0};
  flex-direction: row;
  border-radius: 12px;
  max-width: ${CONTAINER_WIDTH - BLOG_IMAGE_WIDTH}px;
`;

export const Content = styled.View`
  padding-horizontal: ${CONTAINER_PADDING}px;
  flex-direction: column;
`;

export const Text = styled.Text`
  font-family: ${({ theme }) => theme.fontFamily.core.w400};
  color: ${({ theme }) => theme.color.base.c9};
  font-size: 14px;
`;

export const BlogHeadline = styled.Text`
  font-family: ${({ theme }) => theme.fontFamily.core.w600};
  color: ${({ theme }) => theme.color.base.c9};
  font-size: 16px;
  margin-bottom: 8px;
`;

export const BlogImage = styled(Image)<IBlogImage>`
  background-color: ${({ theme }) => theme.color.base.c1};
  width: ${BLOG_IMAGE_WIDTH}px;
  height: ${({ heightRatio }) => heightRatio * BLOG_IMAGE_WIDTH}px;
  border-radius: 5px;
`;
export const BadgeContainer = styled.View`
  position: absolute;
  top: -8px;
`;
