/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// Helper mutation ToggleExternalEventSave
// ====================================================

import {
  useMutation,
  MutationHookOptions,
  MutationTuple,
} from '@apollo/client';
import { MockedResponse } from '@apollo/client/testing';
import {
  ToggleExternalEventSave,
  ToggleExternalEventSaveVariables,
} from './types/ToggleExternalEventSave';
import { TOGGLE_EXTERNAL_EVENT_SAVE } from './';

export function useToggleExternalEventSaveMutation(
  options?: MutationHookOptions<
    ToggleExternalEventSave,
    ToggleExternalEventSaveVariables
  >,
): MutationTuple<ToggleExternalEventSave, ToggleExternalEventSaveVariables> {
  return useMutation<ToggleExternalEventSave, ToggleExternalEventSaveVariables>(
    TOGGLE_EXTERNAL_EVENT_SAVE,
    options,
  );
}

export function createToggleExternalEventSaveMock({
  variables,
  data,
  error,
}: {
  variables?: ToggleExternalEventSaveVariables;
  data?: ToggleExternalEventSave;
  error?: Error;
}): MockedResponse {
  return {
    request: {
      query: TOGGLE_EXTERNAL_EVENT_SAVE,
      variables,
    },
    result: {
      data,
    },
    error,
  };
}
