// Note: keeping these constants here to prevent "Require cycle warning"
// See: https://stackoverflow.com/questions/55664673/require-cycles-are-allowed-but-can-result-in-uninitialized-values-consider-ref
// The relationship causes error -> utils - > constants -> utils

import { t } from '~/utils/i18n';
import { Policy } from './models/marketProfile';
import { getNormalizedLocale } from './utils';

export const DATA_POLICY: Policy = {
  title: t('dataPolicy'),
  url: `https://www.gradoo.com/mobile/${getNormalizedLocale({
    includeSubLocale: false,
  })}/datapolicy`,
  version: '1',
};

export const TERM: Policy = {
  title: t('term'),
  url: `https://www.gradoo.com/mobile/${getNormalizedLocale({
    includeSubLocale: false,
  })}/terms`,
  version: '1',
};
