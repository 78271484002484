import { BottomTabBarProps } from '@react-navigation/bottom-tabs';
import { Route } from '@react-navigation/native';
import { TabBarStyledIconName } from '~/components/TabBarItem';
import { TurkeyEntranceTabParamList } from '~/navigation/types';
import { t } from '~/utils/i18n';

type TurkeyEntranceTabName = keyof TurkeyEntranceTabParamList;

const getRouteLabel = (routeName: TurkeyEntranceTabName): string => {
  const tabLabels: { [key in TurkeyEntranceTabName]: string } = {
    EventsTR: t('g.events'),
    ExploreTRStack: t('g.explore'),
    GraduationTR: t('g.graduation'),
    ProfileTRStack: t('g.me'),
  };

  return tabLabels[routeName];
};

interface IMobileRouteMeta {
  isFocused: boolean;
  routeName: string;
  iconName: TabBarStyledIconName;
  label: string;
}

const mobileTabIcons: { [key in TurkeyEntranceTabName]: TabBarStyledIconName } =
  {
    EventsTR: 'calendar',
    ExploreTRStack: 'jewelry',
    GraduationTR: 'graduation',
    ProfileTRStack: 'user',
  };

export const getMobileRouteMeta = (
  state: BottomTabBarProps['state'],
  route: Route<string>,
  index: number,
): IMobileRouteMeta => {
  const isFocused = state.index === index;
  const routeName = route.name as TurkeyEntranceTabName;

  const tabIcon = mobileTabIcons[routeName];

  const label = getRouteLabel(routeName);

  return {
    isFocused,
    routeName,
    iconName: tabIcon,
    label,
  };
};
