import React from 'react';
import {
  CompositeNavigationProp,
  useNavigation,
} from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import { useAuth } from '~/context/auth';
import {
  HIDDEN_YEARBOOK_MODULES_FOR_TURKEY,
  NO_PAG_LIST_OFFSET,
} from '~/data/constants';
import { ModuleInstanceResult } from '~/data/models/yearbook';
import { ModuleType } from '~/data/operations/global';
import { useGroupSampleQuery } from '~/data/operations/group/groupSample';
import { useModuleInstanceResultsQuery } from '~/data/operations/yearbook/moduleInstanceResults';
import { RootStackParamList, YearbookStackParamList } from '~/navigation/types';
import CollectedDataLayout from './layout';

type CollectedDataNavProp = CompositeNavigationProp<
  StackNavigationProp<YearbookStackParamList, 'CollectedData'>,
  StackNavigationProp<RootStackParamList>
>;

export default function CollectedData(): JSX.Element {
  const navigation = useNavigation<CollectedDataNavProp>();

  const { authGroupId, isTurkishUser, authUserId } = useAuth();

  const { data: groupData } = useGroupSampleQuery({
    variables: {
      id: authGroupId,
    },
  });

  const groupSample = groupData?.group;

  const { data: moduleInstancesData, loading } = useModuleInstanceResultsQuery({
    variables: { group: authGroupId, first: NO_PAG_LIST_OFFSET },
  });

  let moduleInstanceResults: ModuleInstanceResult[] =
    (moduleInstancesData?.moduleInstances?.edges.map(
      (edge) => edge?.node,
    ) as ModuleInstanceResult[]) || [];

  if (isTurkishUser) {
    moduleInstanceResults = moduleInstanceResults.filter(
      (moduleInstanceResult) =>
        moduleInstanceResult.module.type &&
        !HIDDEN_YEARBOOK_MODULES_FOR_TURKEY.includes(
          moduleInstanceResult.module.type,
        ),
    );
  }

  const openBannersInBrowser = (url: string) => {
    navigation.navigate('Browser', {
      title: 'Banner',
      url,
    });
  };

  return (
    <CollectedDataLayout
      loading={loading}
      moduleInstanceResults={moduleInstanceResults}
      usersTotal={groupSample?.usersCount || 0}
      onBack={() => navigation.goBack()}
      authUserId={authUserId}
      openBannersInBrowser={openBannersInBrowser}
      onSelectModule={(type, moduleInstanceId) => {
        if (type === ModuleType.PROFILE_PAGE) {
          navigation.navigate('ProfilePageResults', { moduleInstanceId });
        } else if (type === ModuleType.QUOTES) {
          navigation.navigate('Quotes', { moduleInstanceId, viewOnly: true });
        } else if (type === ModuleType.COLLAGES) {
          navigation.navigate('Collages', { moduleInstanceId, viewOnly: true });
        } else if (type === ModuleType.REPORTS) {
          navigation.navigate('Reports', { moduleInstanceId, viewOnly: true });
        } else if (type === ModuleType.RANKINGS) {
          navigation.navigate('Rankings', { moduleInstanceId, viewOnly: true });
        }
      }}
    />
  );
}
