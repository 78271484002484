import * as React from 'react';
import { createStackNavigator } from '@react-navigation/stack';
import { ExploreTRStackParamList } from '~/navigation/types';
import EbookTR from '~/screens/TurkeyEntrance/EbookTR';
import ExploreTR from '~/screens/TurkeyEntrance/ExploreTR';

const ExploreTRStackNavigator = createStackNavigator<ExploreTRStackParamList>();

export default function ExploreTRStack(): JSX.Element {
  return (
    <ExploreTRStackNavigator.Navigator
      screenOptions={{
        headerShown: false,
      }}
    >
      <ExploreTRStackNavigator.Screen name={'EventsTR'} component={ExploreTR} />
      <ExploreTRStackNavigator.Screen name={'EbookTR'} component={EbookTR} />
    </ExploreTRStackNavigator.Navigator>
  );
}
