import React from 'react';
import { TouchableOpacity } from 'react-native-gesture-handler';
import { useTheme } from 'styled-components/native';
import { general } from '~/assets/imgs';
import Button from '~/components/Button';
import NavHeader from '~/components/NavHeader';
import PostCell, { PostCellActionProps } from '~/components/PostCell';
import TrackingFlatlist from '~/components/TrackingFlatList';
import { AdsImage, AdsText } from '~/components/common/style';
import { BestMatchedAsset } from '~/data/models/campaign';
import { PollOption, Post } from '~/data/models/post';
import { VariantTypeEnum } from '~/data/operations/global';
import { getPollVotedOptions } from '~/data/utils';
import useAdsTracking from '~/utils/hooks/useAdsTracking';
import { t } from '~/utils/i18n';
import InputHeader, { InputHeaderProps } from './InputHeader';
import {
  Container,
  Header,
  RefreshControl,
  Footer,
  EmptyWrapper,
  EmptyDesc,
  EmptyTitle,
  EmptyImage,
} from './style';

export type FeedLayoutProps = {
  now?: Date;
  authUserId: string;
  posts: (BestMatchedAsset | Post)[];
  loading: boolean;
  authTeamIds: string[];
  hasNextPage?: boolean;
  onLoadMorePosts: () => void;
  onRefresh: () => void;
  onGoTurkeyEntrance: () => void;
  onHelp: () => void;
  isTurkishUser?: boolean;
  openBannersInBrowser: (url: string) => void;
} & InputHeaderProps &
  PostCellActionProps;

export default function Feed({
  now = new Date(),
  authUserId,
  posts,
  loading,
  authTeamIds,
  loggedUserGroup,
  hasNextPage,
  onCreatePost,
  onCreatePoll,
  onLike,
  onComment,
  onDelete,
  onReportPost,
  onReportUser,
  onBlockUser,
  onHidePost,
  onLoadMorePosts,
  onRefresh,
  onPollVote,
  onEdit,
  onGoTurkeyEntrance,
  openBannersInBrowser,
  onHelp,
  isTurkishUser,
}: FeedLayoutProps): JSX.Element {
  const { handleViewTracking, handleAdsClick } = useAdsTracking({
    authUserId,
    variantType: VariantTypeEnum.SMALL,
  });
  const renderItem = ({
    item: post,
    index,
  }: {
    item?: BestMatchedAsset | Post;
    index: number;
  }) => {
    const pollVotedOptions =
      post?.__typename === 'PostNode' &&
      getPollVotedOptions(
        (post?.poll?.options as PollOption[]) || [],
        authUserId as string,
      );
    const isPostOwner =
      post?.__typename === 'PostNode' &&
      loggedUserGroup?.user?.id === post?.createdBy?.user.id;

    if (post?.__typename === 'MatchedAssetNode') {
      return (
        <TouchableOpacity
          testID={`${index}:AdsCell:${post.id}`}
          onPress={() => {
            handleAdsClick(
              post?.id as string,
              post?.channelData?.[0]?.trackingUrl as string,
              openBannersInBrowser,
            );
          }}
        >
          <AdsText>{t('asset.gratitude')}</AdsText>
          <AdsImage
            source={{ uri: post?.channelData?.[0]?.imageSmall as string }}
          />
        </TouchableOpacity>
      );
    }

    return (
      <PostCell
        testID={`${index}` as string}
        now={now}
        post={post as Post}
        isPostOwner={isPostOwner}
        pollVotedOptions={pollVotedOptions || []}
        authTeamIds={authTeamIds}
        onLike={onLike}
        onComment={onComment}
        onDelete={onDelete}
        onReportPost={onReportPost}
        onReportUser={onReportUser}
        onBlockUser={onBlockUser}
        onHidePost={onHidePost}
        onPollVote={onPollVote}
        onEdit={onEdit}
      />
    );
  };
  const theme = useTheme();
  const data = loading && posts.length == 0 ? [undefined, undefined] : posts;

  return (
    <Container>
      <NavHeader
        title={t('g.feed')}
        type={'main'}
        RightComponent={
          isTurkishUser ? (
            <Button
              testID="backToTurkeyEntrance"
              size={'sm'}
              text={t('g.mainPage')}
              type={'secondary-base'}
              onPress={onGoTurkeyEntrance}
            />
          ) : (
            <Button
              text={t('getHelp')}
              testID="getHelp"
              size={'sm'}
              type={'secondary-base'}
              onPress={onHelp}
            />
          )
        }
      />
      <TrackingFlatlist
        testID={'flatList'}
        contentContainerStyle={{
          backgroundColor: theme.color.base.c1,
          flexGrow: 1,
        }}
        refreshControl={
          <RefreshControl
            refreshing={loading && posts.length > 0}
            onRefresh={onRefresh}
          />
        }
        handleViewTracking={handleViewTracking}
        ListHeaderComponent={
          <Header>
            <InputHeader
              loggedUserGroup={loggedUserGroup}
              onCreatePost={onCreatePost}
              onCreatePoll={onCreatePoll}
            />
          </Header>
        }
        data={data as BestMatchedAsset[]}
        renderItem={renderItem}
        onEndReachedThreshold={0.3}
        onEndReached={() => hasNextPage && onLoadMorePosts()}
        ListFooterComponent={() => <Footer />}
        ListEmptyComponent={() => (
          <EmptyWrapper>
            <EmptyImage source={general.double_hearts} />
            <EmptyTitle>{t('feed.emptyTitle')}</EmptyTitle>
            <EmptyDesc>{t('feed.emptyDesc')}</EmptyDesc>
            <Button
              onPress={onCreatePost}
              text={t('feed.postBtn')}
              type="primary-brand-01"
              flex
              size="xl"
            />
          </EmptyWrapper>
        )}
      />
    </Container>
  );
}
