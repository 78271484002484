import React, { useState } from 'react';
import Carousel, { Pagination } from 'react-native-snap-carousel';
import { useTheme } from 'styled-components/native';
import {
  Container,
  CONTAINER_WIDTH,
  NewsCard,
  NewsCardImage,
  NewsCardSeparator,
  NEWS_ITEM_WIDTH,
  PaginationContainer,
} from './style';

export type BannerInfo = {
  id: string;
  imgUri?: number;
  link?: string;
  externalImgUri?: string;
};

export type BannerCarouselProps = {
  banners: BannerInfo[];
  imageHeightRatio?: number;
  onOpenLink: (link: string) => void;
  externalImgUri?: string;
};

export default function BannerCarousel({
  banners,
  imageHeightRatio = 0.57,
  onOpenLink,
}: BannerCarouselProps): JSX.Element {
  const theme = useTheme();
  const [activeNewsIndex, setActiveNewsIndex] = useState(0);
  const renderNewsItem = ({ item: banner }: { item: BannerInfo }) => {
    const source = banner.externalImgUri
      ? { uri: banner.externalImgUri }
      : banner.imgUri;

    return (
      <NewsCard
        testID={banner.id}
        onPress={() => banner.link && onOpenLink(banner.link)}
      >
        <NewsCardImage heightRatio={imageHeightRatio} source={source} />
      </NewsCard>
    );
  };

  return (
    <Container>
      <Carousel
        data={banners}
        renderItem={renderNewsItem}
        ItemSeparatorComponent={() => <NewsCardSeparator />}
        sliderWidth={CONTAINER_WIDTH}
        itemWidth={NEWS_ITEM_WIDTH}
        inactiveSlideScale={1.0}
        inactiveSlideOpacity={1.0}
        onSnapToItem={setActiveNewsIndex}
        removeClippedSubviews={false}
      />
      <PaginationContainer>
        <Pagination
          dotsLength={banners.length}
          activeDotIndex={activeNewsIndex}
          containerStyle={{
            backgroundColor: theme.color.base.c1,
            paddingHorizontal: 8,
            paddingVertical: 6,
            borderRadius: 10,
          }}
          dotContainerStyle={{
            marginHorizontal: 2,
          }}
          dotStyle={{
            height: 10,
            width: 10,
            borderRadius: 5,
            backgroundColor: theme.color.base.c4,
          }}
          inactiveDotStyle={{
            height: 10,
            width: 10,
            borderRadius: 5,
            backgroundColor: theme.color.base.c3,
          }}
          inactiveDotOpacity={1.0}
          inactiveDotScale={1.0}
        />
      </PaginationContainer>
    </Container>
  );
}
