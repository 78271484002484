import React from 'react';
import { useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import { useAuth } from '~/context/auth';
import { useIntercom } from '~/context/intercom';
import { HIDDEN_TEAMS_FOR_TURKEY } from '~/data/constants';
import useTeamSamples from '~/data/hooks/useTeamSamples';
import { PlanningStackParamList } from '~/navigation/types';
import TeamsLayout from './layout';

type TeamsNavProp = StackNavigationProp<PlanningStackParamList, 'Teams'>;

export default function Teams(): JSX.Element {
  const navigation = useNavigation<TeamsNavProp>();
  const { onHelpCenter } = useIntercom();

  const {
    teamSamples,
    loading: teamSamplesLoading,
    onSearchTeam,
    onLoadMore: onLoadMoreTeams,
  } = useTeamSamples();

  const { coreAdmin, isTurkishUser } = useAuth();

  const teams = isTurkishUser
    ? teamSamples.filter(
        (t) => t.type && !HIDDEN_TEAMS_FOR_TURKEY.includes(t.type),
      )
    : teamSamples;

  const filteredTeams = coreAdmin
    ? teams.sort((t1, t2) => +t1.isHidden - +t2.isHidden)
    : teams.filter((t) => !t.isHidden);

  return (
    <TeamsLayout
      teams={filteredTeams}
      loading={teamSamplesLoading}
      onBack={() => navigation.goBack()}
      onSearchTeamChange={onSearchTeam}
      onAddTeam={() => navigation.navigate('CreateTeam')}
      onSelectedTeam={(team) =>
        navigation.navigate('TeamDetail', { teamId: team.id })
      }
      onLoadMoreTeams={onLoadMoreTeams}
      onHelp={onHelpCenter}
    />
  );
}
