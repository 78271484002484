import React, { useEffect, useRef, useState } from 'react';
import Button from '~/components/Button';
import CardOptionCell from '~/components/CardOptionCell';
import InputField from '~/components/InputField';
import ModalCountryPicker, {
  ModalCountryPickerHandler,
} from '~/components/ModalCountryPicker';
import NavHeader from '~/components/NavHeader';
import ResidenceCountryCell from '~/components/ResidenceCountryCell';
import SectionHeader from '~/components/SectionHeader';
import { COUNTRIES } from '~/data/constants';
import { Country, CountryInfo } from '~/data/models/marketProfile';
import { Me } from '~/data/operations/auth/types/Me';
import { UsersUserGenderChoices, UserType } from '~/data/operations/global';
import { t } from '~/utils/i18n';
import {
  Container,
  Loading,
  ScrollView,
  Content,
  SectionView,
  ErrorText,
} from './style';

type ProfileSetupLoadingErrorLayoutProps = {
  onBack: () => void;
  onLogout: () => void;
  error?: string;
};

export function ProfileSetupLoadingError({
  onBack,
  onLogout,
  error,
}: ProfileSetupLoadingErrorLayoutProps): JSX.Element {
  return (
    <Container>
      <NavHeader
        backButtonTestID={'buttonBack'}
        title={t('screens.profileSetup')}
        RightComponent={
          <Button
            testID={'buttonLogout'}
            text={t('g.logout')}
            minWidth={80}
            size={'sm'}
            onPress={onLogout}
          />
        }
        onBackPress={onBack}
      />
      {error ? (
        <ErrorText>{error}</ErrorText>
      ) : (
        <ScrollView>
          <Loading />
        </ScrollView>
      )}
    </Container>
  );
}

export type MeInput = {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  gender: UsersUserGenderChoices | null;
  phoneCountryCode: string;
  updateToken: boolean;
};

export type UserGroupInput = {
  role: UserType;
  uri?: string | null;
  defaultAvatarId?: string | null;
};

export type ProfileSetupLayoutProps = {
  onBack: () => void;
  onLogout: () => void;
  onUpdateMe: (input: MeInput) => void;
  meData: Me;
  onDeleteAccount: () => void;
  error?: string;
  loading?: boolean;
  country: Country;
  onUpdateCountry: (country: Country) => Promise<void>;
};

export default function ProfileSetup({
  meData,
  onBack,
  onLogout,
  onUpdateMe,
  onDeleteAccount,
  country,
  onUpdateCountry,
}: ProfileSetupLayoutProps): JSX.Element {
  const [firstName, setFirstName] = useState<string>(
    () => meData.me?.firstName || '',
  );
  const [lastName, setLastName] = useState<string>(
    () => meData.me?.lastName || '',
  );
  const [email, setEmail] = useState<string>(() => meData.me?.email || '');
  const [phone, setPhone] = useState<string>(() => meData.me?.phone || '');
  const [gender, setGender] = useState<UsersUserGenderChoices | null>(
    () => meData.me?.gender || null,
  );
  const [phoneCountry, setPhoneCountry] = useState<CountryInfo | undefined>();

  const countryModalizeRef = useRef<ModalCountryPickerHandler>(null);

  function handleUpdateMe() {
    const updatedFirstName = meData.me?.firstName !== firstName;

    const updatedLastName = meData.me?.lastName !== lastName;

    const updatedEmail = meData.me?.email !== email;

    const updatedPhone = meData.me?.phone !== phone;

    const updatedGender = meData.me?.gender !== gender;

    const updatePhoneCountryCode =
      phoneCountry && meData.me?.phoneCountryCode !== phoneCountry?.code;
    if (
      updatedFirstName ||
      updatedLastName ||
      updatedPhone ||
      updatedGender ||
      updatePhoneCountryCode ||
      updatedEmail
    ) {
      onUpdateMe({
        firstName,
        lastName,
        email,
        phone,
        gender,
        phoneCountryCode: phoneCountry?.code || COUNTRIES['TR'].code,
        updateToken: !!updatedEmail,
      });
    }
  }

  useEffect(() => {
    handleUpdateMe();
  }, [gender, phoneCountry]);

  useEffect(() => {
    const countryInfos = Object.values(COUNTRIES);
    const phoneCountryInfo = countryInfos.find(
      (countryInfo) => countryInfo.code === meData.me?.phoneCountryCode,
    );
    setPhoneCountry(phoneCountryInfo);
  }, []);

  return (
    <Container>
      <NavHeader
        backButtonTestID={'buttonBack'}
        title={t('screens.profileSetup')}
        RightComponent={
          <Button
            testID={'buttonLogout'}
            text={t('g.logout')}
            minWidth={80}
            size={'sm'}
            onPress={onLogout}
          />
        }
        onBackPress={onBack}
      />
      <ScrollView>
        <Content>
          <SectionHeader title={t('profileSetup.contactSecion')} sub />
          <SectionView>
            <InputField
              testID={'inputFirstName'}
              iconName={'user-03'}
              label={t('signUpName.firstNameLabel')}
              value={firstName}
              onChangeText={(value) => setFirstName(value)}
              blurOnSubmit={false}
              returnKeyType={'done'}
              onSubmitEditing={() => handleUpdateMe()}
            />
            <InputField
              testID={'inputLastName'}
              iconName={'user-03'}
              label={t('signUpName.lastNameLabel')}
              value={lastName}
              blurOnSubmit={false}
              returnKeyType={'done'}
              onChangeText={(value) => setLastName(value)}
              onSubmitEditing={() => handleUpdateMe()}
            />
            <InputField
              testID={'inputEmail'}
              iconName={'mail-01'}
              label={t('signUpContact.emailLabel')}
              placeholder={t('signUpContact.emailPlaceholder')}
              value={email}
              blurOnSubmit={false}
              returnKeyType={'done'}
              onChangeText={(value) => setEmail(value)}
              onSubmitEditing={() => handleUpdateMe()}
            />
            <InputField
              testID={'inputPhone'}
              country={phoneCountry?.country}
              onPhonePress={() => countryModalizeRef.current?.open()}
              keyboardType={'numeric'}
              label={t('signUpContact.phoneLabel')}
              placeholder={t('signUpContact.phonePlaceholder')}
              value={phone}
              blurOnSubmit={false}
              returnKeyType={'done'}
              onChangeText={(value) => setPhone(value)}
              onSubmitEditing={() => handleUpdateMe()}
            />
          </SectionView>
          <SectionHeader title={t('profileSetup.countryOfResidence')} sub />
          <SectionView>
            <ResidenceCountryCell
              country={country}
              onSelect={() => {
                countryModalizeRef.current?.open();
              }}
            />
          </SectionView>
          <SectionHeader title={t('profileSetup.genderSection')} sub />
          <SectionView row>
            <CardOptionCell
              testID={'femaleCardOption'}
              text={t('g.female')}
              iconStyledName={'gender_women'}
              isSelected={gender === UsersUserGenderChoices.FEMALE}
              onPress={() => setGender(UsersUserGenderChoices.FEMALE)}
            />
            <CardOptionCell
              testID={'maleCardOption'}
              text={t('g.male')}
              iconStyledName={'gender_men'}
              isSelected={gender === UsersUserGenderChoices.MALE}
              onPress={() => setGender(UsersUserGenderChoices.MALE)}
            />
            <CardOptionCell
              testID={'diversCardOption'}
              text={t('g.divers')}
              iconStyledName={'gender_transgender'}
              isSelected={gender === UsersUserGenderChoices.OTHER}
              onPress={() => setGender(UsersUserGenderChoices.OTHER)}
            />
          </SectionView>
          <SectionHeader title={t('profileSetup.accountSection')} sub />
          <SectionView>
            <Button
              testID={'buttonDelete'}
              type={'destructive'}
              text={t('profileSetup.deleteAccount')}
              flex
              onPress={onDeleteAccount}
            />
          </SectionView>
        </Content>
      </ScrollView>
      <ModalCountryPicker
        testID={'countryPicker'}
        ref={countryModalizeRef}
        initialSelectedCountry={phoneCountry?.country || 'TR'}
        onUpdateCountry={(country) => {
          countryModalizeRef.current?.close();
          setPhoneCountry(COUNTRIES[country]);
        }}
      />
      <ModalCountryPicker
        testID={'countryPicker'}
        ref={countryModalizeRef}
        initialSelectedCountry={country}
        onUpdateCountry={(countryPicked) => {
          onUpdateCountry(countryPicked);
          countryModalizeRef.current?.close();
        }}
      />
    </Container>
  );
}
