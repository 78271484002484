import React from 'react';
import {
  CompositeNavigationProp,
  useNavigation,
} from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import Snackbar from '~/components/Snackbar';
import { useAuth } from '~/context/auth';
import { Country } from '~/data/models/marketProfile';
import { useMeQuery } from '~/data/operations/auth/me';
import { useUpdateMeMutation } from '~/data/operations/auth/updateMe';
import { setAuth } from '~/data/storage';
import { ProfileStackParamList, RootStackParamList } from '~/navigation/types';
import ProfileSetupLayout, {
  MeInput,
  ProfileSetupLoadingError,
} from './layout';

type ProfileSetupNavProp = CompositeNavigationProp<
  StackNavigationProp<ProfileStackParamList, 'ProfileSetup'>,
  StackNavigationProp<RootStackParamList>
>;

export default function ProfileSetupTurkeyEntrance(): JSX.Element {
  const navigation = useNavigation<ProfileSetupNavProp>();

  const { data: meData, loading: meLoading, error: meError } = useMeQuery();

  const [updateMe] = useUpdateMeMutation();

  const { country, onUpdateCountry, onLogout } = useAuth();

  const onUpdateMe = async ({
    firstName,
    lastName,
    email,
    phone,
    gender,
    phoneCountryCode,
    updateToken,
  }: MeInput) => {
    if (!meData || !meData.me) {
      return null;
    }

    try {
      const { data } = await updateMe({
        variables: {
          input: {
            firstName,
            lastName,
            email,
            phone,
            phoneCountryCode: phoneCountryCode,
            gender,
            communicationLanguage: meData.me.communicationLanguage || '',
          },
        },
      });
      const messages = data?.updateMe?.errors?.map(
        (error) => error?.messages[0],
      );
      const errorMessage = messages?.[0];
      if (errorMessage) {
        Snackbar.show(errorMessage);
        return;
      }

      const authToken = data?.updateMe?.token;
      const refreshToken = data?.updateMe?.refreshToken;

      if (updateToken && authToken && refreshToken) {
        setAuth(authToken, refreshToken);
      }
    } catch (e) {
      if (e instanceof Error) {
        Snackbar.show(e.message);
      }
    }
  };

  if (meLoading || !meData || meError) {
    return (
      <ProfileSetupLoadingError
        onBack={() => navigation.goBack()}
        onLogout={onLogout}
        error={meError?.message}
      />
    );
  }

  return (
    <ProfileSetupLayout
      meData={meData}
      onBack={() => navigation.goBack()}
      onLogout={onLogout}
      onUpdateMe={onUpdateMe}
      onDeleteAccount={() => navigation.navigate('DeleteAccount')}
      country={country as Country}
      onUpdateCountry={onUpdateCountry}
    />
  );
}
