import React, { useEffect, useRef, useState } from 'react';
import Button from '~/components/Button';
import EventToolbar from '~/components/EventToolbar';
import { getImageFromLibrary } from '~/components/ImagePicker';
import InputField from '~/components/InputField';
import ModalDatePicker, {
  ModalDatePickerHandler,
} from '~/components/ModalDatePicker';
import ModalTimePicker, {
  ModalTimePickerHandler,
} from '~/components/ModalTimePicker';
import NavHeader from '~/components/NavHeader';
import {
  SettingPanel,
  SettingToggleItem,
  SettingLabelItem,
} from '~/components/SettingPanel';
import SwitchSelector from '~/components/SwitchSelector';
import { Spacer } from '~/components/common/style.web';
import { CalendarDateObject } from '~/data/models/calendar';
import { Event } from '~/data/models/calendar';
import { GroupUser, UserGroup } from '~/data/models/group';
import { Team } from '~/data/models/team';
import { EventType } from '~/data/operations/global';
import { EditEventInput } from '~/data/operations/global';
import {
  calendarDateObject,
  combineDateAndTime,
  formattedDate,
} from '~/utils/dates';
import { t } from '~/utils/i18n';
import {
  Container,
  ScrollView,
  Title,
  ImageContent,
  EmptyImageIcon,
  ImageBanner,
  AboutEvent,
  Loading,
} from './style';

type AuthorType = 'GROUP' | 'USER' | 'TEAM';
type InviteOption = 'classmate' | 'class' | 'team';

export type EditEventLayoutProps = {
  teams: Team[];
  groupsUser: GroupUser[];
  event: Event;
  loggedUserGroup?: UserGroup | null;
  loading: boolean;
  initialInviteOption?: InviteOption;
  onBack: () => void;
  onUpdateEvent: (eventInput: EditEventInput) => void;
  onDeleteEvent: () => void;
  isFromTurkeyEntrance?: boolean;
};

export default function EditEvent({
  teams,
  groupsUser,
  loggedUserGroup,
  event,
  onBack,
  onUpdateEvent,
  onDeleteEvent,
  loading,
  isFromTurkeyEntrance,
}: EditEventLayoutProps): JSX.Element {
  const startModalizeRef = useRef<ModalDatePickerHandler>(null);
  const startTimeModalizeRef = useRef<ModalTimePickerHandler>(null);
  const endModalizeRef = useRef<ModalDatePickerHandler>(null);
  const endTimeModalizeRef = useRef<ModalTimePickerHandler>(null);

  const [eventKey, setEventKey] = useState<string>('Event');

  const [name, setName] = useState<string>('');
  const [eventLocation, setEventLocation] = useState<string>('');
  const [about, setAbout] = useState<string>('');
  const [authorId, setAuthorId] = useState<string | undefined>();
  const [authorType, setAuthorType] = useState<AuthorType>('USER');
  const [imgUri, setImgUri] = useState<string>('');
  const [isJoinable, setIsJoinable] = useState(false);
  const [isPublic, setIsPublic] = useState(false);
  const [isFullDayEvent, setIsFullDayEvent] = useState(false);

  const getInitialDueDay = (initialDay?: string) =>
    initialDay
      ? calendarDateObject(formattedDate(initialDay, 'yyyy-MM-dd'))
      : null;

  const getInitialDueTime = (date?: string) =>
    date ? new Date(Date.parse(date)) : undefined;

  const [startTimeDate, setStartTimeDate] = useState<Date | undefined>(
    getInitialDueTime(event?.startDateTime),
  );
  const [endTimeDate, setEndTimeDate] = useState<Date | undefined>(
    getInitialDueTime(event?.endDateTime),
  );
  const [startDay, setStartDay] = useState<CalendarDateObject | null>(
    getInitialDueDay(),
  );
  const [endDay, setEndDay] = useState<CalendarDateObject | null>(
    getInitialDueDay(),
  );

  // Prefill values when event is fetched
  useEffect(() => {
    if (!event) {
      return;
    }

    setName(event.title);
    setAbout(event.description || '');
    setAuthorType(event.authorType);
    setEventKey(event.eventType || 'Event');
    setEventLocation(event.location || '');
    setAuthorId(() => {
      const map = {
        USER: event.authorUser?.id,
        GROUP: event.authorGroup?.id,
        TEAM: event.authorTeam?.id,
      };
      return event.authorType in map ? map[event.authorType] : undefined;
    });
    setImgUri(event.bannerImage || '');
    setIsJoinable(event.isJoinable);
    setIsPublic(event.isPublic);
    setIsFullDayEvent(event.isAllDay);
    setStartTimeDate(getInitialDueTime(event.startDateTime));
    setEndTimeDate(getInitialDueTime(event.endDateTime));
    setStartDay(getInitialDueDay(event.startDateTime));
    setEndDay(getInitialDueDay(event.endDateTime));
  }, [event]);

  const onSubmitUpdate = () => {
    if (!event) {
      return;
    }

    // Prepare datetime data for request
    const timeFormat = 'h:mm A';
    const [startTime, endTime] = [
      formattedDate(startTimeDate || '', timeFormat),
      formattedDate(endTimeDate || '', timeFormat),
    ];
    const [startDateTime, endDateTime] = [
      combineDateAndTime(startDay?.dateString, startTime),
      combineDateAndTime(endDay?.dateString, endTime),
    ];

    const input: EditEventInput = {
      id: event.id,
      title: name,
      bannerImage: imgUri,
      startDateTime,
      endDateTime,
      isAllDay: isFullDayEvent,
      eventType: eventKey.toUpperCase(),
      authorType: authorType || 'USER',
      authorUser: authorType === 'USER' ? authorId : null,
      authorGroup: authorType === 'GROUP' ? authorId : null,
      authorTeam: authorType === 'TEAM' ? authorId : null,
      location: eventLocation,
      description: about,
      isJoinable: isJoinable,
      isPublic: isPublic,
    };

    onUpdateEvent(input);
  };

  const onPickImage = async () => {
    const imgUri = await getImageFromLibrary({ maxFiles: 1 });
    setImgUri(imgUri[0]);
  };

  if (!event) {
    return <Loading testID="spinner" />;
  }

  return (
    <Container>
      <NavHeader
        backButtonTestID={'buttonBack'}
        title={t('screens.editEvent')}
        onBackPress={onBack}
      />
      <ScrollView>
        <Title>{t('g.editEvent')}</Title>
        <InputField
          testID={'inputName'}
          value={name}
          label={t('createEvent.nameLabel')}
          placeholder={t('createEvent.namePlaceholder')}
          onChangeText={(value) => setName(value)}
        />
        <Title>{t('g.createEventBanner')}</Title>
        <ImageContent onPress={onPickImage}>
          <ImageBanner
            testID={`PagePhotoCategoryCardImage`}
            source={{ uri: imgUri }}
          >
            {!imgUri ? <EmptyImageIcon /> : null}
          </ImageBanner>
        </ImageContent>
        <Title>{t('createEvent.dateTitle')}</Title>
        <SettingPanel>
          <SettingToggleItem
            testID={'isActivateToggleItem'}
            text={'All-Day event'}
            checked={isFullDayEvent as boolean}
            onToggleChange={setIsFullDayEvent}
          />
          <SettingLabelItem
            testID={'buttonDueDate'}
            text={t('g.startDay')}
            labelText={
              startDay ? formattedDate(startDay.dateString, 'MMM d yyyy') : ''
            }
            onLabelPress={() => startModalizeRef.current?.open()}
          />
          {!isFullDayEvent && (
            <SettingLabelItem
              testID={'buttonDueTime'}
              text={t('g.dueTime')}
              labelText={
                startTimeDate ? formattedDate(startTimeDate, 'hh:mm a') : ''
              }
              onLabelPress={() => startTimeModalizeRef.current?.open()}
            />
          )}

          <SettingLabelItem
            testID={'buttonDueDate'}
            text={t('g.endDay')}
            labelText={
              endDay ? formattedDate(endDay.dateString, 'MMM d yyyy') : ''
            }
            onLabelPress={() => endModalizeRef.current?.open()}
          />
          {!isFullDayEvent && (
            <SettingLabelItem
              testID={'buttonDueTime'}
              text={t('g.dueTime')}
              labelText={
                endTimeDate ? formattedDate(endTimeDate, 'hh:mm a') : ''
              }
              onLabelPress={() => endTimeModalizeRef.current?.open()}
            />
          )}
        </SettingPanel>
        <Title>{t('createEvent.typeTitle')}</Title>
        <SwitchSelector
          items={Object.keys(EventType).map((key) => ({
            key,
            title: EventType[key as keyof typeof EventType],
          }))}
          selectedItemKey={eventKey}
          onSelectedItem={setEventKey}
        />
        <Title>{t('createEvent.locationTitle')}</Title>
        <InputField
          testID={'inputLocation'}
          placeholder={t('createEvent.namePlaceholder')}
          onChangeText={(value) => setEventLocation(value)}
          value={eventLocation}
        />
        <Title>{t('createEvent.aboutEventTitle')}</Title>
        <AboutEvent
          testID={'inputAbout'}
          multiline
          placeholder={t('createEvent.describeEvent')}
          style={{ textAlignVertical: 'top' }}
          onChangeText={(value) => setAbout(value)}
          value={about}
        />
        {!isFromTurkeyEntrance && (
          <>
            <Title>{t('createEvent.postAs')}</Title>
            <EventToolbar
              testID={loggedUserGroup ? 'toolbarInput' : ''}
              author={authorId}
              userGroup={loggedUserGroup}
              teams={teams}
              groups={groupsUser}
              onAuthorChange={(authorId, type) => {
                setAuthorId(authorId);
                setAuthorType(type as AuthorType);
              }}
            />
            <Title>{t('createEvent.settings')}</Title>
            <SettingPanel>
              <SettingToggleItem
                testID={'isActivateToggleItem'}
                text={t('createEvent.joinable')}
                checked={isJoinable as boolean}
                onToggleChange={setIsJoinable}
              />
              <SettingToggleItem
                testID={'isActivateToggleItem'}
                text={t('createEvent.public')}
                checked={isPublic as boolean}
                onToggleChange={setIsPublic}
              />
            </SettingPanel>
          </>
        )}
        <Spacer v={250} />
        <Button
          testID={'buttonDeleteEvent'}
          text={t('createEvent.deleteEventButton')}
          size={'lg'}
          type={'destructive'}
          iconName={'plus'}
          onPress={onDeleteEvent}
          flex
          loading={loading}
        />
        <Spacer v={16} />
        <Button
          testID={'buttonEditEvent'}
          text={t('createEvent.saveChangesButton')}
          size={'lg'}
          type={'primary-brand-01'}
          iconName={'plus'}
          onPress={onSubmitUpdate}
          flex
          loading={loading}
        />
        <Spacer v={16} />
      </ScrollView>

      {/* MODALS */}
      <ModalDatePicker
        testID={'startDatePicker'}
        ref={startModalizeRef}
        title={t('g.deadlineTime')}
        buttonText={t('g.confirmDate')}
        date={startDay}
        onConfirmDate={(dateObj) => {
          setStartDay(dateObj);
          startModalizeRef.current?.close();
        }}
      />

      <ModalDatePicker
        testID={'endDatePicker'}
        ref={endModalizeRef}
        title={t('g.deadlineTime')}
        buttonText={t('g.confirmDate')}
        date={endDay}
        onConfirmDate={(dateObj) => {
          setEndDay(dateObj);
          endModalizeRef.current?.close();
        }}
      />

      <ModalTimePicker
        testID={'endTimePicker'}
        ref={endTimeModalizeRef}
        title={t('g.deadlineTime')}
        buttonText={t('g.confirmTime')}
        time={endTimeDate}
        onConfirmTime={(time) => {
          setEndTimeDate(time);
          endTimeModalizeRef.current?.close();
        }}
      />

      <ModalTimePicker
        testID={'startTimePicker'}
        ref={startTimeModalizeRef}
        title={t('createEvent.timeModalTitle')}
        buttonText={t('createEvent.timeModalButtonText')}
        time={
          startTimeDate ? new Date(`1970-01-01T${startTimeDate}:00`) : undefined
        }
        onConfirmTime={(time) => {
          setStartTimeDate(time);
          startTimeModalizeRef.current?.close();
        }}
      />
    </Container>
  );
}
