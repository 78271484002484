import { ApolloClient, ApolloLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { SentryLink } from 'apollo-link-sentry';
import { createUploadLink } from 'apollo-upload-client';
import jwt_decode, { JwtPayload } from 'jwt-decode';
import { Platform } from 'react-native';
import {
  clearAuth,
  getAppLocale,
  getRefreshToken,
  getToken,
} from '~/data/storage';
import { isTokenDateExpired } from '~/utils/dates';
import { eventEmitter } from '~/utils/helpers/eventEmitter';
import cache from './cache';
import { setAuth } from './storage';

const uri = process.env.EXPO_PUBLIC_GRADOO_URL as string;
const origin = process.env.EXPO_PUBLIC_GRADOO_ORIGIN;

type RefreshTokenData = {
  refreshToken: {
    token: string;
    refreshToken: string;
  };
};

const refreshTokenMutation = async (
  refreshToken: string,
): Promise<RefreshTokenData> => {
  const query = JSON.stringify({
    query: `mutation {
      refreshToken(input:{refreshToken: "${refreshToken}"}) {
        token
        refreshToken
      }
    }
    `,
  });

  const response = await fetch(uri, {
    headers: { 'content-type': 'application/json' },
    method: 'POST',
    body: query,
  });

  const responseJson = await response.json();

  return responseJson.data as RefreshTokenData;
};

export const authLink = setContext(async (operation, { headers }) => {
  let token = null;
  let locale = null;

  //Fixes 'Signature has expired' on Invite and Reset Password
  const { operationName = '' } = operation;
  const noAuthOperations = ['GroupSample', 'ResetPassword'];
  const isAuthOperation = !noAuthOperations.includes(operationName);
  try {
    token = await getToken();
    locale = await getAppLocale();

    if (token && isAuthOperation) {
      const decoded = jwt_decode<JwtPayload>(token);
      if (!decoded.exp) {
        throw new Error('invalid jwt token');
      }

      const isExpired = isTokenDateExpired(decoded.exp);

      if (isExpired) {
        const refreshToken = await getRefreshToken();

        if (!refreshToken) {
          throw new Error('no refresh token');
        }

        const refreshTokenData = await refreshTokenMutation(refreshToken);

        const newToken = refreshTokenData?.refreshToken?.token;
        const newRefreshToken = refreshTokenData?.refreshToken?.refreshToken;

        if (!newToken || !newRefreshToken) {
          throw new Error('could not refresh token');
        }
        setAuth(newToken, newRefreshToken);

        token = newToken;
      }
    }
  } catch (err) {
    clearAuth();
  }
  return {
    headers: {
      ...headers,
      'Accept-Language': locale || 'en',
      origin: origin || headers?.origin,
      platform: Platform.OS,
      Authorization: token && isAuthOperation ? `JWT ${token}` : '',
    },
  };
});

const uploadLink = createUploadLink({
  uri,
});

const cmsForTurkey = createUploadLink({
  uri: process.env.EXPO_PUBLIC_CMS_TURKEY_URL,
  headers: {
    Authorization: `Bearer ${process.env.CMS_TURKEY_ACCESS_TOKEN}`,
  },
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    //TODO: handle specific errors returned by graphql
  }

  if (networkError && 'statusCode' in networkError) {
    if (networkError.statusCode === 500) {
      eventEmitter.emit('internalServerError', networkError);
    }
  }
});

export default new ApolloClient({
  link: ApolloLink.split(
    (operation) => operation.getContext().client === 'cmsForTurkey',
    cmsForTurkey as unknown as ApolloLink,
    ApolloLink.from([
      errorLink,
      new SentryLink({
        attachBreadcrumbs: {
          includeQuery: true,
          includeVariables: true,
          includeFetchResult: true,
          includeError: true,
        },
      }),
      authLink,
      uploadLink as unknown as ApolloLink,
    ]),
  ),
  cache,
});
