import React from 'react';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import {} from '@react-navigation/native';
import { TurkeyEntranceTabParamList } from '~/navigation/types';
import EventsScreenTR from '~/screens/TurkeyEntrance/EventsTR';
import GraduationScreenTR from '~/screens/TurkeyEntrance/GraduationTR';
import ExploreTRStack from './ExploreTR';
import ProfileTRStack from './ProfileTR';
import CustomTabBar from './layout/CustomTabBar';

const BottomTabNavigator =
  createBottomTabNavigator<TurkeyEntranceTabParamList>();

const SCREENS: {
  name: keyof TurkeyEntranceTabParamList;
  component: () => JSX.Element;
}[] = [
  { name: 'EventsTR', component: EventsScreenTR },
  { name: 'ExploreTRStack', component: ExploreTRStack },
  { name: 'GraduationTR', component: GraduationScreenTR },
  { name: 'ProfileTRStack', component: ProfileTRStack },
];

export default function TurkeyEntranceTab(): JSX.Element {
  const INITIAL_ROUTE = 'EventsTR';

  return (
    <>
      <BottomTabNavigator.Navigator
        id="TurkeyEntranceTab"
        initialRouteName={INITIAL_ROUTE}
        tabBar={(props) =>
          CustomTabBar({
            ...props,
          })
        }
        screenOptions={{
          headerShown: false,
        }}
      >
        {SCREENS.map((screen) => (
          <BottomTabNavigator.Screen key={screen.name} {...screen} />
        ))}
      </BottomTabNavigator.Navigator>
    </>
  );
}
