import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import styled from 'styled-components/native';
import SectionHeader from '~/components/SectionHeader';
import { TabScreenContainer } from '~/screens/style';

type DotType = {
  isActive?: boolean;
  isLast?: boolean;
};

export const Container = styled(TabScreenContainer)`
  flex: 1;
  background-color: ${({ theme }) => theme.color.base.c0};
`;

export const TabContainer = styled.View`
  padding: 0 15px 0 15px;
`;

export const ScrollView = styled(KeyboardAwareScrollView)`
  background-color: ${({ theme }) => theme.color.base.c0};
  padding-horizontal: 16px;
`;

export const MyHostedEventList = styled.FlatList``;
export const MySavedEventList = styled.FlatList``;

export const MyEventCardContainer = styled.View`
  align-items: center;
`;

export const MyAttendedEventCardContainer = styled.View`
  align-items: center;
  padding-top: 10px;
`;

export const MyAppliedEventCardContainer = styled.View`
  align-items: center;
  padding-top: 10px;
`;

export const UpcomingEventCardContainer = styled.View`
  align-items: center;
  padding-top: 10px;
`;

export const PastEventCardContainer = styled.View`
  align-items: center;
  padding-top: 10px;
`;

export const EventsContainer = styled.View`
  margin-top: 20px;
`;

export const MyHostedEventsHeader = styled(SectionHeader)`
  margin-top: 16px;
`;

export const MySavedEventsHeader = styled(SectionHeader)`
  margin-top: 16px;
`;

export const PastEventsHeader = styled(SectionHeader)`
  margin-top: 16px;
`;

export const UpcomingEventsHeader = styled(SectionHeader)``;

export const MyAttendedEventsHeader = styled(SectionHeader)``;
export const MyAppliedEventsHeader = styled(SectionHeader)``;

export const DotTrackWrapper = styled.View`
  flex-direction: row;
  background-color: ${({ theme }) => theme.color.base.c2};
  margin: auto;
  padding: 5px;
  margin-top: 10px;
  margin-bottom: 10px;
  border-radius: 20px;
  gap: 18px;
`;

export const Dot = styled.View<DotType>`
  width: 10px;
  height: 10px;
  border-radius: 10px;
  margin-right: ${({ isLast }) => (isLast ? '0px' : '5px')};
  background-color: ${({ theme, isActive }) =>
    isActive ? theme.color.base.c4 : theme.color.base.c0};
`;

export const Loading = styled.ActivityIndicator.attrs(({ theme }) => ({
  color: theme.color.base.c4,
}))`
  padding-top: 20px;
`;

export const ErrorMessage = styled.Text`
  font-family: ${({ theme }) => theme.fontFamily.core.w400};
  color: ${({ theme }) => theme.color.error};
  font-size: 16px;
  line-height: 23px;
  text-align: center;
  padding-vertical: 10px;
`;

export const EmptyMessage = styled.Text`
  font-family: ${({ theme }) => theme.fontFamily.core.w400};
  color: ${({ theme }) => theme.color.base.c5};
  font-size: 14px;
  line-height: 23px;
  text-align: center;
  padding-vertical: 10px;
  padding-horizontal: 20px;
`;
