import React from 'react';
import { Spacer } from '~/theme/wrappers';
import Badge from '../Badge';
import { BadgeColorTheme } from '../Badge/types';
import {
  Container,
  Text,
  BlogImage,
  Content,
  BlogHeadline,
  BadgeContainer,
} from './style';

type BlogCardProps = {
  title: string;
  text: string;
  imgUri?: string;
  imageHeightRatio?: number;
  testID?: string;
  badgeText?: string;
  badgeColor?: BadgeColorTheme;
};

export default function BlogCard({
  text,
  title,
  imgUri,
  imageHeightRatio = 1,
  testID,
  badgeText,
  badgeColor,
}: BlogCardProps): JSX.Element {
  return (
    <Container testID={testID}>
      <BlogImage source={{ uri: imgUri }} heightRatio={imageHeightRatio} />
      {badgeText && (
        <BadgeContainer>
          <Badge text={badgeText} colorTheme={badgeColor} type="rounded" />
        </BadgeContainer>
      )}
      <Spacer size={12} />
      <Content>
        <BlogHeadline>{title}</BlogHeadline>
        <Text numberOfLines={4}>{text}</Text>
      </Content>
      <Spacer size={8} />
    </Container>
  );
}
