import styled from 'styled-components/native';
import { TabScreenContainer } from '~/screens/style';

interface Spacer {
  size: number;
}

export const Container = styled(TabScreenContainer)`
  flex: 1;
  background-color: ${({ theme }) => theme.color.base.c0};
`;

export const ContentContainer = styled.View`
  padding-horizontal: 16px;
  flex: 1;
  padding-bottom: 48px;
`;

export const VSpacer = styled.View<Spacer>`
  height: ${({ size }) => size}px;
`;

export const HSpacer = styled.View<Spacer>`
  width: ${({ size }) => size}px;
`;
