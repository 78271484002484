import React from 'react';
import {
  CompositeNavigationProp,
  useNavigation,
  useRoute,
} from '@react-navigation/native';
import { RouteProp } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import { Share } from 'react-native';
import Snackbar from '~/components/Snackbar';
import { useAuth } from '~/context/auth';
import { Group } from '~/data/models/group';
import { useGroupQuery } from '~/data/operations/group/group';
import { GroupStackParamList, RootStackParamList } from '~/navigation/types';
import { getInviteUrl } from '~/utils/helpers';
import CreateGroupSuccessLayout from './layout';

type CreateGroupSuccessNavProp = CompositeNavigationProp<
  StackNavigationProp<RootStackParamList>,
  StackNavigationProp<GroupStackParamList, 'CreateGroupSuccess'>
>;
type CreateGroupSuccessRouteProp = RouteProp<
  GroupStackParamList,
  'CreateGroupSuccess'
>;

export default function CreateGroupSuccess(): JSX.Element {
  const navigation = useNavigation<CreateGroupSuccessNavProp>();
  const {
    params: { groupId },
  } = useRoute<CreateGroupSuccessRouteProp>();

  const { onLogout } = useAuth();

  const { data: groupData } = useGroupQuery({
    variables: {
      id: groupId,
    },
  });

  const group = groupData?.group as Group;

  const onShare = async () => {
    try {
      const url =
        group && group.token ? getInviteUrl(group.id, group.token) : '';
      Share.share({
        message: url,
        url,
      });
    } catch (e) {
      if (e instanceof Error) {
        Snackbar.show(e.message);
      }
    }
  };

  const logoutHandler = async () => {
    await onLogout();

    navigation.reset({
      routes: [{ name: 'AuthStack' }],
    });
  };

  return (
    <CreateGroupSuccessLayout
      avatar={group?.avatar || group?.icon?.icon || ''}
      onBack={() => navigation.goBack()}
      onStartPlaning={() => {
        //hack to pop from GroupStack back to Main or TurkeyEntrance
        navigation.popToTop();
        navigation.goBack();
      }}
      onShareInvite={() => onShare()}
      onLogout={logoutHandler}
    />
  );
}

/**
 * TODO:
 * - Extract invite url logic
 */
