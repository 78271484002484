import React from 'react';
import { BottomTabBarProps } from '@react-navigation/bottom-tabs';
import TabBarItem from '~/components/TabBarItem';
import { getMobileRouteMeta } from '../utils/getRouteMeta';
import { TabContainer } from './style';

type CustomTabBarProps = {
  onLongPress?: (label: string) => void;
} & BottomTabBarProps;

export default function CustomTabBar({
  state,
  navigation,
  onLongPress,
}: CustomTabBarProps): JSX.Element {
  return (
    <TabContainer>
      {state.routes.map((route, index) => {
        const { isFocused, iconName, label } = getMobileRouteMeta(
          state,
          route,
          index,
        );

        const onPress = () => {
          const event = navigation.emit({
            type: 'tabPress',
            target: route.key,
            canPreventDefault: true,
          });

          if (!isFocused && !event.defaultPrevented) {
            navigation.navigate(route.name);
          }
        };

        return (
          <TabBarItem
            key={route.key}
            tabStyledIcon={iconName}
            label={label}
            isFocused={isFocused}
            onPress={onPress}
            onLongPress={onLongPress}
          />
        );
      })}
    </TabContainer>
  );
}
