import * as React from 'react';
import { createStackNavigator } from '@react-navigation/stack';
import { Platform } from 'react-native';
import {
  PlanningStackParamList,
  MobileRootPlanningStackParamList,
} from '~/navigation/types';
import AddTeamMember from '~/screens/Main/AddTeamMember';
import AttendeesList from '~/screens/Main/AttendeesList';
import Calendar from '~/screens/Main/Calendar';
import Comments from '~/screens/Main/Comments';
import CreateEvent from '~/screens/Main/CreateEvent';
import CreatePoll from '~/screens/Main/CreatePoll';
import CreatePost from '~/screens/Main/CreatePost';
import CreateTeam from '~/screens/Main/CreateTeam';
import EditEvent from '~/screens/Main/EditEvent';
import EditPoll from '~/screens/Main/EditPoll';
import EditTeam from '~/screens/Main/EditTeam';
import EventDetail from '~/screens/Main/EventDetail';
import GroupSettings from '~/screens/Main/GroupSettings';
import Planning from '~/screens/Main/Planning';
import Polls from '~/screens/Main/Polls';
import TeamDetail from '~/screens/Main/TeamDetail';
import Teams from '~/screens/Main/Teams';
import ToDoLists from '~/screens/Main/ToDoLists';
import ToDoTasks from '~/screens/Main/ToDoTasks';

const PlanningStackNavigator = createStackNavigator<PlanningStackParamList>();

export const MOBILE_ROOT_PLANNING_SCREENS: {
  name: keyof MobileRootPlanningStackParamList;
  component: () => JSX.Element;
  isModalPresentationIOS?: boolean;
}[] = [
  { name: 'Comments', component: Comments, isModalPresentationIOS: false },
  { name: 'CreateEvent', component: CreateEvent, isModalPresentationIOS: true },
  { name: 'CreatePoll', component: CreatePoll, isModalPresentationIOS: true },
  { name: 'CreatePost', component: CreatePost, isModalPresentationIOS: true },
  {
    name: 'GroupSettings',
    component: GroupSettings,
    isModalPresentationIOS: true,
  },
  { name: 'EditPoll', component: EditPoll, isModalPresentationIOS: true },
  {
    name: 'AddTeamMember',
    component: AddTeamMember,
    isModalPresentationIOS: true,
  },
];

export default function PlanningStack(): JSX.Element {
  return (
    <PlanningStackNavigator.Navigator
      screenOptions={{
        headerShown: false,
      }}
      initialRouteName={'Planning'}
    >
      <PlanningStackNavigator.Screen name={'Planning'} component={Planning} />
      <PlanningStackNavigator.Screen name={'Teams'} component={Teams} />
      <PlanningStackNavigator.Screen
        name={'CreateTeam'}
        component={CreateTeam}
      />
      <PlanningStackNavigator.Screen
        name={'TeamDetail'}
        component={TeamDetail}
      />
      <PlanningStackNavigator.Screen name={'EditTeam'} component={EditTeam} />
      <PlanningStackNavigator.Screen name={'Polls'} component={Polls} />
      <PlanningStackNavigator.Screen name={'ToDoLists'} component={ToDoLists} />
      <PlanningStackNavigator.Screen name={'Calendar'} component={Calendar} />
      <PlanningStackNavigator.Screen name={'ToDoTasks'} component={ToDoTasks} />
      <PlanningStackNavigator.Screen name={'EditEvent'} component={EditEvent} />
      <PlanningStackNavigator.Screen
        name={'EventDetail'}
        component={EventDetail}
      />
      <PlanningStackNavigator.Screen
        name={'AttendeesList'}
        component={AttendeesList}
      />
      {Platform.OS === 'web' &&
        MOBILE_ROOT_PLANNING_SCREENS.map((screen) => (
          <PlanningStackNavigator.Screen key={screen.name} {...screen} />
        ))}
    </PlanningStackNavigator.Navigator>
  );
}
