import React, { useState } from 'react';
import {
  CompositeNavigationProp,
  useNavigation,
} from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import { useBannersTurkeyCMS } from '~/data/operations/cmsForTurkey/useBannersTurkeyCMS';
import { useBlogsTurkeyCMS } from '~/data/operations/cmsForTurkey/useBlogsTurkeyCMS';
import { useHighlightsTurkeyCMS } from '~/data/operations/cmsForTurkey/useHighlightsTurkeyCMS';
import { useTagsTurkeyCMS } from '~/data/operations/cmsForTurkey/useTagsTurkeyCMS';
import {
  RootStackParamList,
  TurkeyEntranceTabParamList,
} from '~/navigation/types';
import ExploreTRLayout from './layout';

type ExploreTRNavProp = CompositeNavigationProp<
  StackNavigationProp<RootStackParamList>,
  StackNavigationProp<TurkeyEntranceTabParamList, 'ExploreTRStack'>
>;

export const BLOG_PAGINATION_AMOUNT_PER_PAGE = 10;

export default function ExploreTR(): JSX.Element {
  const navigation = useNavigation<ExploreTRNavProp>();
  const [selectedTags, setSelectedTags] = useState<string[]>([]);

  const {
    data: bannerCMSData,
    loading: bannerLoading,
    error: bannerError,
  } = useBannersTurkeyCMS();
  const banners = bannerCMSData?.mobileBannerCollection?.items || [];

  const {
    data: tagsCMSData,
    loading: tagsLoading,
    error: tagsError,
  } = useTagsTurkeyCMS();
  const tags = tagsCMSData?.mobileTagsCollection?.items || [];

  const {
    data: highlightsCMSData,
    loading: highlightsLoading,
    error: highlightsError,
  } = useHighlightsTurkeyCMS();
  const highlights = highlightsCMSData?.mobileHighlightCollection?.items || [];

  const {
    data: blogsCMSData,
    loading: blogsLoading,
    error: blogsError,
    fetchMore: fetchMoreBlog,
  } = useBlogsTurkeyCMS({
    variables: {
      skip: 0,
      limit: BLOG_PAGINATION_AMOUNT_PER_PAGE,
      tags: selectedTags.length > 0 ? selectedTags : null,
    },
  });
  const blogs = blogsCMSData?.blogCollection?.items || [];

  const onFetchMoreBlog = async () => {
    if (
      blogsCMSData &&
      blogsCMSData.blogCollection &&
      blogsCMSData?.blogCollection?.total > blogs.length
    ) {
      await fetchMoreBlog({
        variables: {
          skip: blogs.length,
          limit: BLOG_PAGINATION_AMOUNT_PER_PAGE,
          tags: selectedTags.length > 0 ? selectedTags : null,
        },
      });
    }
  };

  return (
    <ExploreTRLayout
      loading={bannerLoading || highlightsLoading || tagsLoading}
      blogsLoading={blogsLoading}
      errorMessage={
        bannerError?.message ||
        highlightsError?.message ||
        blogsError?.message ||
        tagsError?.message
      }
      bannersCMS={banners}
      selectedTags={selectedTags}
      setSelectedTags={setSelectedTags}
      blogsCMS={blogs}
      highlightsCMS={highlights}
      tagsCMS={tags}
      onFetchMoreBlog={onFetchMoreBlog}
      onOpenLink={(link) =>
        navigation.navigate('Browser', {
          title: '',
          url: link,
        })
      }
      onPressEbook={(ebookId: string) => {
        navigation.navigate('ExploreTRStack', {
          screen: 'EbookTR',
          params: {
            ebookId: ebookId,
          },
        });
      }}
    />
  );
}
