import { ScrollView } from 'react-native';
import styled from 'styled-components/native';
import SectionHeader from '~/components/SectionHeader';
import { TabScreenContainer } from '~/screens/style';

interface IImage {
  size: number;
}

interface ITag {
  selected?: boolean;
}

export const Container = styled(TabScreenContainer)`
  flex: 1;
  background-color: ${({ theme }) => theme.color.base.c0};
`;

export const Content = styled.View`
  padding-horizontal: 16px;
`;

export const HighlightScrollView = styled(ScrollView)``;

export const HightLightImage = styled.Image<IImage>`
  width: ${({ size }) => size}px;
  height: ${({ size }) => size}px;
  border-radius: ${({ size }) => size}px;
  border-width: 2px;
  border-color: ${({ theme }) => theme.color.brand_02};
`;

export const HighlightImageButton = styled.Pressable`
  margin-right: 12px;
`;
export const HighlightText = styled.Text`
  margin: auto;
  font-size: 12px;
  margin-top: 4px;
  color: ${({ theme }) => theme.color.brand_02};
`;

export const TagContainer = styled.View`
  margin-bottom: 8px;
  flex-direction: row;
  flex-wrap: wrap;
`;

export const TagButton = styled.Pressable<ITag>`
  padding: 4px 8px;
  margin-top: 4px;
  border-radius: 16px;
  margin-right: 4px;
  border-width: 2px;
  border-color: ${({ theme }) => theme.color.brand_02};
  background-color: ${({ theme, selected }) =>
    selected ? theme.color.brand_02 : theme.color.base.c0};
`;
export const TagText = styled.Text<ITag>`
  color: ${({ theme, selected }) =>
    selected ? theme.color.base.c0 : theme.color.brand_02};
  font-weight: 500;
  font-size: 12px;
`;

export const Loading = styled.ActivityIndicator.attrs(({ theme }) => ({
  color: theme.color.base.c4,
}))`
  padding-top: 20px;
`;

export const BlogLoading = styled.ActivityIndicator.attrs(({ theme }) => ({
  color: theme.color.base.c4,
}))`
  padding-top: 32px;
  padding-bottom: 32px;
`;

export const ErrorMessage = styled.Text`
  font-family: ${({ theme }) => theme.fontFamily.core.w400};
  color: ${({ theme }) => theme.color.error};
  font-size: 16px;
  line-height: 23px;
  text-align: center;
  padding-vertical: 10px;
`;

export const FlatListContent = styled.FlatList``;
export const BlogCardWrapper = styled.Pressable`
  padding-horizontal: 16px;
  margin-bottom: 24px;
`;

export const GradooBlogHeader = styled(SectionHeader)``;
