import { LinearGradient } from 'expo-linear-gradient';
import { SafeAreaView } from 'react-native-safe-area-context';
import styled from 'styled-components/native';
import ButtonIcon from '~/components/ButtonIcon';
import Image from '~/components/Image';

const LNBG_HEIGHT = 220;

export const Container = styled.ScrollView`
  background-color: ${({ theme }) => theme.color.base.c0};
`;

export const LNContainer = styled(LinearGradient).attrs(({ theme }) => ({
  colors: [theme.color.brand_02, theme.color.brand_02, theme.color.brand_02],
  start: { x: 0, y: 0 },
  end: { x: 1, y: 1 },
}))`
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  height: ${({ theme }) => LNBG_HEIGHT + theme.safeAreaInsets.top}px;
`;

export const NameText = styled.Text`
  margin-top: 16px;
  font-family: ${({ theme }) => theme.fontFamily.core.w600};
  color: ${({ theme }) => theme.color.base.c0};
  font-size: 20px;
`;

export const InfoView = styled.View`
  padding-top: ${({ theme }) => 24 + theme.safeAreaInsets.top}px;
  align-items: center;
`;

export const Content = styled.View`
  padding-horizontal: 16px;
  padding-bottom: 48px;
`;

export const CardTouchView = styled.TouchableOpacity.attrs({
  activeOpacity: 0.9,
})`
  background-color: ${({ theme }) => theme.color.base.c0};
  box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.05);
  border-radius: 14px;
  padding: 16px;
`;

export const CardHeader = styled.View`
  flex-direction: row;
  align-items: center;
`;

export const CardInfo = styled.View`
  flex: 1;
  padding-left: 16px;
`;

export const ShareLinkTopView = styled.View`
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  flex-direction: row;
`;

interface IVSpacer {
  height: number;
}
export const VSpacer = styled.View<IVSpacer>`
  height: ${({ height }) => height}px;
`;

export const ContactImage = styled(Image)`
  height: 48px;
  width: 48px;
`;

export const ContactTitle = styled.Text`
  font-family: ${({ theme }) => theme.fontFamily.core.w600};
  color: ${({ theme }) => theme.color.base.c9};
  font-size: 14px;
`;

export const ContactDescp = styled.Text`
  margin-top: 4px;
  font-family: ${({ theme }) => theme.fontFamily.core.w400};
  color: ${({ theme }) => theme.color.base.c5};
  font-size: 14px;
`;

export const ArrowRightButtonIcon = styled(ButtonIcon).attrs(({ theme }) => ({
  name: 'chevron-right',
  size: 16,
  color: theme.color.success,
}))`
  background-color: ${({ theme }) => theme.color.base.c1};
  width: 32px;
  height: 32px;
  border-radius: 16px;
  align-items: center;
  justify-content: center;
`;

export const LatestVersionText = styled.Text`
  font-family: ${({ theme }) => theme.fontFamily.core.w600};
  color: ${({ theme }) => theme.color.brand_02};
  font-size: 14px;
  text-align: center;
`;

export const VersionText = styled.Text`
  margin-top: 12px;
  font-family: ${({ theme }) => theme.fontFamily.core.w400};
  color: ${({ theme }) => theme.color.base.c6};
  font-size: 14px;
  text-align: center;
`;

export const ReferPromotionBox = styled.View`
  background-color: ${({ theme }) => theme.color.base.c0};
  margin-top: 16px;
  border-radius: 14px;
  padding: 8px;
`;

export const ReferPromotionTitle = styled.Text`
  font-size: 14px;
  font-weight: 600;
  color: ${({ theme }) => theme.color.base.c9};
  padding-vertical: 8px;
  text-align: center;
`;

export const ReferPromotionText = styled.Text`
  background-color: ${({ theme }) => theme.color.brand_02};
  margin-bottom: 4px;
  border-radius: 6px;
  line-height: 32px;
  padding-horizontal: 16px;
`;

export const ReferShareLinkView = styled.View`
  margin-top: 8px;
  height: 58px;
  border-radius: 10px;
  background-color: ${({ theme }) => theme.color.brand_02};
  padding: 16px;
  justify-content: center;
  overflow: hidden;
`;

export const ReferShareLinkButtonView = styled.View`
  background-color: ${({ theme }) => theme.color.brand_02};
  justify-content: center;
  padding-right: 8px;
`;

export const ReferShareLinkShadow = styled(LinearGradient).attrs(
  ({ theme }) => ({
    colors: ['rgba(129,140,248,0.0)', theme.color.brand_02],
    start: { x: 0, y: 0 },
    end: { x: 1.2, y: 0 },
  }),
)`
  flex: 1;
`;

export const ReferShareLinkText = styled.Text`
  font-family: ${({ theme }) => theme.fontFamily.core.w400};
  color: ${({ theme }) => theme.color.base.c0};
  font-size: 14px;
`;

export const ScoreBubble = styled(LinearGradient).attrs(({ theme }) => ({
  colors: [theme.color.brand_02, theme.color.brand_02],
  start: { x: 0, y: 0 },
  end: { x: 1, y: 1 },
}))`
  width: 12px;
  height: 12px;
  border-radius: 12px;
`;

export const ScoreView = styled.View`
  flex-direction: row;
  align-items: center;
  padding-vertical: 8px;
`;

export const ScorePoint = styled.Text`
  color: ${({ theme }) => theme.color.base.c1};
  margin-right: 6px;
  font-weight: 600;
`;

export const Loading = styled.ActivityIndicator.attrs(({ theme }) => ({
  color: theme.color.base.c4,
}))``;

export const ErrorText = styled.Text`
  text-align: center;
  font-family: ${({ theme }) => theme.fontFamily.core.w400};
  color: ${({ theme }) => theme.color.error};
  font-size: 16px;
`;

export const SafeAreaContainer = styled(SafeAreaView)`
  position: absolute;
  top: ${({ theme }) => theme.safeAreaInsets.top}px;
  left: 16px;
  right: 16px;
`;
