import { DefaultTheme } from 'styled-components/native';
import { BadgeType, BadgeSize, BadgeColorTheme } from './types';

/** Size */
interface ButtonSizeHelper {
  containerHeight: (size: BadgeSize) => string;
  containerRadius: (size: BadgeSize, type: BadgeType) => string;
}

type SizeMap = {
  [key in BadgeSize]: string;
};
export const bySizeHelper: ButtonSizeHelper = {
  containerHeight: (size: BadgeSize): string => {
    const sizeMap: SizeMap = {
      small: '24px',
      large: '28px',
    };
    return sizeMap[size];
  },
  containerRadius: (size: BadgeSize, type: BadgeType): string => {
    if (type === 'rounded') {
      return '4px';
    }
    const sizeMap: SizeMap = {
      small: '12px',
      large: '14px',
    };
    return sizeMap[size];
  },
};

/** ColorTheme */
interface ButtonColorThemeHelper {
  bgColor: (colorTheme: BadgeColorTheme, theme: DefaultTheme) => string;
  textColor: (colorTheme: BadgeColorTheme, theme: DefaultTheme) => string;
  dotColor: (colorTheme: BadgeColorTheme, theme: DefaultTheme) => string;
}

type ColorThemeMap = {
  [key in BadgeColorTheme]: string;
};
export const byColorThemeHelper: ButtonColorThemeHelper = {
  bgColor: (colorTheme: BadgeColorTheme, theme: DefaultTheme): string => {
    const colorThemeMap: ColorThemeMap = {
      gray: theme.color.base.c1,
      darkGray: theme.color.base.c4,
      red: theme.color.base.c1,
      green: theme.color.base.c1,
      primary: theme.color.brand_02,
      tertiary: theme.color.base.c1,
      dark2Gray: theme.color.base.c4,
      gray2Black: theme.color.base.c7,
    };
    return colorThemeMap[colorTheme];
  },
  textColor: (colorTheme: BadgeColorTheme, theme: DefaultTheme): string => {
    const colorThemeMap: ColorThemeMap = {
      gray: theme.color.base.c9,
      darkGray: theme.color.base.c0,
      red: theme.color.error,
      green: theme.color.success,
      primary: theme.color.brand_02,
      tertiary: theme.color.brand_02,
      dark2Gray: theme.color.base.c10,
      gray2Black: theme.color.base.c2,
    };
    return colorThemeMap[colorTheme];
  },
  dotColor: (colorTheme: BadgeColorTheme, theme: DefaultTheme): string => {
    const colorThemeMap: ColorThemeMap = {
      gray: theme.color.base.c4,
      darkGray: theme.color.base.c0,
      red: theme.color.error,
      green: theme.color.success,
      primary: theme.color.brand_02,
      tertiary: theme.color.brand_02,
      dark2Gray: theme.color.base.c10,
      gray2Black: theme.color.base.c2,
    };
    return colorThemeMap[colorTheme];
  },
};
