import React from 'react';
import { general } from '~/assets/imgs';
import Button from '~/components/Button';
import SectionHeader from '~/components/SectionHeader';
import { Me } from '~/data/operations/auth/types/Me';
import { t } from '~/utils/i18n';
import OptionCell from './OptionCell';
import {
  Container,
  LNContainer,
  NameText,
  InfoView,
  Content,
  CardTouchView,
  CardHeader,
  CardInfo,
  ShareLinkTopView,
  VSpacer,
  ContactImage,
  ContactTitle,
  ContactDescp,
  ArrowRightButtonIcon,
  LatestVersionText,
  VersionText,
  ReferShareLinkShadow,
  ReferShareLinkButtonView,
  ReferShareLinkText,
  ReferShareLinkView,
  ReferPromotionBox,
  ReferPromotionTitle,
  Loading,
  ErrorText,
  SafeAreaContainer,
} from './style';

export type ProfileLayoutProps = {
  loading?: boolean;
  error?: string;
  version: string;
  needUpdate?: boolean;
  onContact: () => void;
  onEditProfile: () => void;
  onNotification: () => void;
  onAppSetup: () => void;
  onRateApp: () => void;
  onImprintAndLicences: () => void;
  onDataPolicy: () => void;
  referInviteURL?: string | null;
  onShareReferLink: () => void;
  isTurkishUser?: boolean;
  meData?: Me;
};

export default function Profile({
  version,
  needUpdate,
  onContact,
  onEditProfile,
  onNotification,
  onAppSetup,
  onRateApp,
  onImprintAndLicences,
  onDataPolicy,
  referInviteURL,
  onShareReferLink,
  meData,
  loading,
  error,
}: ProfileLayoutProps): JSX.Element {
  if (error) {
    return (
      <SafeAreaContainer>
        <ErrorText>{error}</ErrorText>
      </SafeAreaContainer>
    );
  }

  return (
    <Container>
      <LNContainer />
      <InfoView>
        {loading && <Loading />}
        <NameText>{meData?.me?.firstName}</NameText>
        {/* {isTurkishUser && (
          <ScoreView>
            <ScorePoint>{leaderboardUser?.points || 0}</ScorePoint>
            <ScoreBubble />
          </ScoreView>
        )} */}
      </InfoView>
      <Content>
        <VSpacer height={64} />
        <ReferPromotionBox>
          <ReferPromotionTitle>
            {t('profile.inviteYourFriends')}
          </ReferPromotionTitle>
          {/* <ReferPromotionText>
            ⏰ {t('profile.joinExclusiveEvents')}
          </ReferPromotionText>
          <ReferPromotionText>🏆 {t('profile.getinTop100')}</ReferPromotionText>
          <ReferPromotionText>
            🎁 {t('profile.getGreatGifts')}
          </ReferPromotionText> */}

          <ReferShareLinkView>
            <ReferShareLinkText>{referInviteURL}</ReferShareLinkText>
            <ShareLinkTopView>
              <ReferShareLinkShadow />
              <ReferShareLinkButtonView>
                <Button
                  testID={'buttonShareReferLink'}
                  text={t('g.share')}
                  minWidth={140}
                  size={'sm'}
                  onPress={() => onShareReferLink()}
                  type={'secondary-base'}
                />
              </ReferShareLinkButtonView>
            </ShareLinkTopView>
          </ReferShareLinkView>
        </ReferPromotionBox>
        {/* <SectionHeader
          buttonTestID={'buttonSeeReferralList'}
          title={t('profile.userRankTitle')}
          buttonText={t('profile.rankListButtonTitle')}
          sub
          buttonIcon={'ranking-filled'}
          onButtonPress={onViewLeaderboard}
        />
        {leaderboardLoading ? (
          <Loading />
        ) : (
          <ReferralCell leaderboardUser={leaderboardUser} />
        )} */}

        <VSpacer height={16} />
        <CardTouchView testID={'contactInfoCard'} onPress={onContact}>
          <CardHeader>
            <ContactImage source={general.whatsapp} />
            <CardInfo>
              <ContactTitle>{t('profile.contactTitle')}</ContactTitle>
              <ContactDescp>{t('profile.contactDescp')}</ContactDescp>
            </CardInfo>
            <ArrowRightButtonIcon onPress={onContact} />
          </CardHeader>
        </CardTouchView>
        <VSpacer height={32} />

        <SectionHeader title={t('profile.settings')} />
        <VSpacer height={24} />
        <OptionCell
          testID={'buttonEditProfile'}
          teamIcon={'icn/teams/rocket'}
          title={t('profile.editYourProfile')}
          onPress={onEditProfile}
        />
        <OptionCell
          testID={'buttoNotifications'}
          teamIcon={'icn/teams/paper_plane'}
          title={t('g.notifications')}
          onPress={onNotification}
        />
        <OptionCell
          testID={'buttonAppSetup'}
          teamIcon={'icn/teams/home'}
          title={t('profile.appSetup')}
          onPress={onAppSetup}
        />
        <VSpacer height={32} />
        <SectionHeader title={t('profile.legal')} />
        <VSpacer height={24} />
        <OptionCell
          testID={'buttonRateApp'}
          teamIcon={'icn/teams/heart'}
          title={t('profile.rateOurApp')}
          bgColorType={'secondary'}
          onPress={onRateApp}
        />
        <OptionCell
          testID={'buttonImprintAndLicences'}
          teamIcon={'icn/teams/world'}
          title={t('profile.imprintAndLicences')}
          bgColorType={'base'}
          onPress={onImprintAndLicences}
        />
        <OptionCell
          testID={'buttonDataPolicy'}
          teamIcon={'icn/teams/package'}
          title={t('profile.dataPolicyCenter')}
          bgColorType={'base'}
          onPress={onDataPolicy}
        />
        {/* TODO : add saved events cell */}

        <VSpacer height={32} />
        {needUpdate === false ? (
          <LatestVersionText>
            {t('g.youAreUsingLatestVersion')}
          </LatestVersionText>
        ) : null}
        <VersionText>{t('g.appVersion', { version })}</VersionText>
      </Content>
    </Container>
  );
}
