import { CountryType } from '~/components/CountryPicker';
import { Asset as EditAsset } from '~/data/operations/campaign/types/Asset';
import { SelectionsState } from '~/screens/Admin/Reporting';
import { calendarDateObject } from '~/utils/dates';
import { Asset as DeprecatedAsset, Statistic } from '~/utils/types/adminAds';
import { COUNTRIES, createPlaceholderFilter } from '../constants';
import {
  AdminSearchGroup,
  AdminSearchResults,
  AdminSearchUser,
  AdsTargetingFields,
  AdsTargetingFilterFields,
  EditInstituteActivityFields,
  EditInstituteGroupFields,
} from '../models/admin';
import {
  CalendarDateObject,
  CalendarEvent,
  Event,
  EventAttendees,
} from '../models/calendar';
import {
  Asset,
  Campaign,
  CampaignDetails,
  Statistics,
} from '../models/campaign';
import {
  AuthorTurkeyCMS,
  BannerTurkeyCMS,
  BlogTurkeyCMS,
  EventTurkeyCMS,
  HighlightTurkeyCMS,
  TagTurkeyCMS,
} from '../models/cmsTurkey';
import { Color, DefaultAvatar, GroupIcon, Icon } from '../models/custom';
import {
  GraduationType,
  Group,
  GroupDetail,
  GroupSample,
  GroupStatistics,
  GroupUser,
  NotificationSetting,
  UserGroup,
} from '../models/group';
import { Institute, InstituteType } from '../models/institute';
import { InstituteRequest } from '../models/instituteRequest';
import { UserGroupInfo } from '../models/local';
import { MarketProfile } from '../models/marketProfile';
import {
  BasePoll,
  BasePost,
  Comment,
  Poll,
  PollOption,
  PollVote,
  Post,
} from '../models/post';
import { LeaderboardUser } from '../models/referralLeaderboard';
import {
  Team,
  TeamDetail,
  TeamSample,
  TeamUser,
  UserTeam,
} from '../models/team';
import { ToDoItem, ToDoList } from '../models/todo';
import {
  AdminUser,
  AuthExternalCMSEventTurkey,
  AuthUser,
  AuthUserGroup,
  User,
} from '../models/user';
import {
  ModuleInstance,
  ModuleInstanceDetail,
  ProfilePagePhotoCategory,
  ProfilePageQuestion,
  ProfilePageAnswer,
  ProfilePageSuggestedQuestion,
  ProfilePageComment,
  Quote,
  ProfilePageSetupConfig,
  ProfilePagePhoto,
  CollagesPhoto,
  CollagesAlbum,
  BaseCollagesAlbum,
  CollagesSetupConfig,
  ReportInstance,
  ReportsSetupConfig,
  QuotesSetupConfig,
  RankingQuestion,
  RankingQuestionResult,
  RankingsSetupConfig,
  Module,
  ModuleInstanceResult,
  ProfilePageUserSummary,
  RankingsAnswer,
  CustomList,
  CustomListOption,
  DataExport,
} from '../models/yearbook';
import { AdminGetGroup } from '../operations/admin/types/AdminGetGroup';
import { AdminGetUser } from '../operations/admin/types/AdminGetUser';
import { AdminSearchInstitute } from '../operations/admin/types/AdminSearchInstitute';
import { MatchedAssetFields } from '../operations/campaign/types/MatchedAssetFields';
import {
  CreateTeamInput,
  CreateUserInput,
  Owner,
  RequestMissingInstituteInput,
  Status,
  UpdateTeamInput,
  UserType,
  ModuleType,
  PhotoFormat,
  ApprovalSetting,
  AnswerType,
  AllowedVoteType,
  UsersUserGenderChoices,
  Language,
  InstitutesInstituteCountryChoices,
  TeamType,
  InstituteActivityTypeEnum,
  GroupsUserGroupRoleChoices,
  GroupsUserGroupStatusChoices,
  InstitutesInstituteRequestCountryChoices,
  EventsEventAuthorTypeChoices,
  AuthorType,
  EventType,
  InstitutesInstituteTypeCountryChoices,
  Platform,
  CampaignStatus,
  CampaignType,
  InstituteTargetingActivityEnum,
  AdvertisementsAssetChannelTypeChoices,
  AssetVisibilityType,
} from '../operations/global';
import { CollagesBaseAlbumFields } from '../operations/yearbook/types/CollagesBaseAlbumFields';

/** Models */
export const AUTH_TOKEN_1 = 'token_1';
export const AUTH_REFRESH_TOKEN_1 = 'refresh_token_1';

export const USER_0: User = {
  __typename: 'UserNode',
  id: 'auth_user_0',
  firstName: 'Max',
  lastName: 'Smith',
  gender: UsersUserGenderChoices.MALE,
};

export const USER_1: User = {
  __typename: 'UserNode',
  id: 'user_1',
  firstName: 'Ronald',
  lastName: 'Robertson',
  gender: UsersUserGenderChoices.MALE,
};

export const USER_2: User = {
  __typename: 'UserNode',
  id: 'user_2',
  firstName: 'Johnny',
  lastName: 'Watsond',
  gender: UsersUserGenderChoices.MALE,
};

export const USER_3: User = {
  __typename: 'UserNode',
  id: 'user_3',
  firstName: 'Annette',
  lastName: 'Cooper',
  gender: UsersUserGenderChoices.FEMALE,
};

export const USER_4: User = {
  __typename: 'UserNode',
  id: 'user_4',
  firstName: 'Arthur',
  lastName: 'Bell',
  gender: UsersUserGenderChoices.OTHER,
};

export const CITY_1 = 'Essen';

export const CITY_2 = 'Dortmund';

export const CITY_3 = 'Duisburg';

export const CITIES: string[] = [CITY_1, CITY_2, CITY_3];

export const CITY_TR_1 = 'Istanbul';

export const GRADUATION_TYPE_1: GraduationType = {
  __typename: 'GraduationTypeNode',
  id: 'grad_type_1',
  name: 'Class',
};

export const GRADUATION_TYPE_2: GraduationType = {
  __typename: 'GraduationTypeNode',
  id: 'grad_type_2',
  name: 'Grad type 2',
};

export const GRADUATION_TYPE_3: GraduationType = {
  __typename: 'GraduationTypeNode',
  id: 'grad_type_3',
  name: 'Grad type 3',
};

export const GRADUATION_TYPES: GraduationType[] = [
  GRADUATION_TYPE_1,
  GRADUATION_TYPE_2,
  GRADUATION_TYPE_3,
];

export const INSTITUTE_TYPE_1: InstituteType = {
  __typename: 'InstituteTypeNode',
  id: 'inst_type_1',
  name: 'University',
  graduationTypes: GRADUATION_TYPES,
  country: InstitutesInstituteTypeCountryChoices.DE,
};

export const INSTITUTE_TYPE_2: InstituteType = {
  __typename: 'InstituteTypeNode',
  id: 'inst_type_2',
  name: 'High School',
  graduationTypes: GRADUATION_TYPES,
  country: InstitutesInstituteTypeCountryChoices.AT,
};

export const INSTITUTE_TYPE_3: InstituteType = {
  __typename: 'InstituteTypeNode',
  id: 'inst_type_3',
  name: 'University',
  graduationTypes: GRADUATION_TYPES,
  country: InstitutesInstituteTypeCountryChoices.TR,
};

export const INSTITUTE_TYPES: InstituteType[] = [
  INSTITUTE_TYPE_1,
  INSTITUTE_TYPE_2,
  INSTITUTE_TYPE_3,
];

export const MARKET_TYPE_1: MarketProfile = {
  __typename: 'MarketProfileNode',
  id: 'inst_type_1',
  name: 'DE-DE',
};

export const MARKET_TYPE_2: MarketProfile = {
  __typename: 'MarketProfileNode',
  id: 'inst_type_2',
  name: 'TRY-TRY',
};

export const MARKET_TYPE_3: MarketProfile = {
  __typename: 'MarketProfileNode',
  id: 'inst_type_3',
  name: 'AUT-AUT',
};

export const MARKET_TYPES: MarketProfile[] = [
  MARKET_TYPE_1,
  MARKET_TYPE_2,
  MARKET_TYPE_3,
];

export const INSTITUTE_1: Institute = {
  __typename: 'InstituteNode',
  id: 'institute_1',
  internalId: 'institute_internal_1',
  name: 'Hillview Institute',
  street: 'Lietzenburger Strasse 18',
  zip: '10789',
  city: 'Essen',
  website: 'https://www.woodcreek.com',
  type: INSTITUTE_TYPE_1,
  country: InstitutesInstituteCountryChoices.DE,
};

export const INSTITUTE_2: Institute = {
  __typename: 'InstituteNode',
  id: 'institute_2',
  internalId: 'institute_internal_2',
  name: 'Woodcreek School',
  street: 'Brandenburgische Straße 70',
  zip: '10713',
  city: 'Essen',
  website: 'https://www.woodcreek.com',
  type: INSTITUTE_TYPE_2,
  country: InstitutesInstituteCountryChoices.DE,
};

export const INSTITUTE_3: Institute = {
  __typename: 'InstituteNode',
  id: 'institute_3',
  internalId: 'institute_internal_3',
  name: 'Woodcreek University',
  street: 'Brandenburgische Straße 70',
  zip: '10713',
  city: 'Essen',
  website: 'https://www.woodcreek.com',
  type: INSTITUTE_TYPE_3,
  country: InstitutesInstituteCountryChoices.DE,
};

export const INSTITUTE_4: Institute = {
  ...INSTITUTE_3,
  id: 'institute_4',
};

export const INSTITUTE_5: Institute = {
  ...INSTITUTE_3,
  id: 'institute_5',
};

export const INSTITUTE_6: Institute = {
  ...INSTITUTE_3,
  id: 'institute_6',
};

export const INSTITUTE_7: Institute = {
  ...INSTITUTE_3,
  id: 'institute_7',
};

export const GROUP_ICON_1: GroupIcon = {
  __typename: 'GroupIconNode',
  id: 'group_icon_1',
  icon: 'https://gradoo-staging.eu-central-1.linodeobjects.com/gradoo-staging/media/group_icons/group_icon_9.png',
};

export const GROUP_1: Group = {
  __typename: 'GroupNode',
  id: 'group_1',
  name: 'Abicolada',
  avatar: null,
  icon: GROUP_ICON_1,
  type: GRADUATION_TYPE_1,
  year: 2021,
  token: 'group_token_1',
  created: '2019-02-26T21:12:55.095000+00:00',
  institute: INSTITUTE_1,
};

export const GROUP_DETAIL_1: GroupDetail = {
  ...GROUP_1,
  institute: INSTITUTE_1,
};

export const GROUP_2: Group = {
  __typename: 'GroupNode',
  id: 'group_2',
  name: 'PhD (Class A)',
  avatar: null,
  icon: null,
  type: GRADUATION_TYPE_1,
  year: 2020,
  token: 'group_token_2',
  created: '2020-02-26T21:12:55.095000+00:00',
  institute: INSTITUTE_2,
};

export const GROUP_3: Group = {
  __typename: 'GroupNode',
  id: 'group_3',
  name: 'PhD (Class B)',
  avatar: null,
  icon: null,
  type: GRADUATION_TYPE_1,
  year: 2021,
  token: 'group_token_3',
  created: '2021-02-26T21:12:55.095000+00:00',
  institute: INSTITUTE_3,
};

export const GROUP_4: Group = {
  __typename: 'GroupNode',
  id: 'group_4',
  name: 'PhD (Class C)',
  avatar: null,
  icon: null,
  type: GRADUATION_TYPE_1,
  year: 2021,
  token: 'group_token_4',
  created: '2021-02-26T21:12:55.095000+00:00',
  institute: INSTITUTE_3,
};

export const USER_GROUP_ACCEPTED_1: UserGroup = {
  __typename: 'UserGroupNode',
  id: 'user_group_accepted_1',
  role: UserType.STUDENT,
  avatar: null,
  defaultAvatar: null,
  status: Status.ACCEPTED,
  user: USER_1,
  created: '2021-07-01T21:12:55.095000+00:00',
};

export const GROUP_USER_ACCEPTED_1: GroupUser = {
  ...USER_GROUP_ACCEPTED_1,
  group: GROUP_1,
};

export const USER_GROUP_ACCEPTED_2: UserGroup = {
  __typename: 'UserGroupNode',
  id: 'user_group_accepted_2',
  role: UserType.STUDENT,
  avatar: null,
  defaultAvatar: null,
  status: Status.ACCEPTED,
  user: USER_1,
  created: '2021-07-01T21:12:55.095000+00:00',
};

export const GROUP_USER_ACCEPTED_2: GroupUser = {
  ...USER_GROUP_ACCEPTED_2,
  group: GROUP_2,
};

export const USER_GROUP_PENDING_1: UserGroup = {
  __typename: 'UserGroupNode',
  id: 'user_group_pending_1',
  role: UserType.STUDENT,
  avatar: null,
  defaultAvatar: null,
  status: Status.PENDING,
  user: USER_1,
  created: '2021-07-01T21:12:55.095000+00:00',
};

export const GROUP_USER_PENDING_1: GroupUser = {
  ...USER_GROUP_PENDING_1,
  group: GROUP_3,
};

export const USER_GROUP_REJECTED_1: UserGroup = {
  __typename: 'UserGroupNode',
  id: 'user_group_rejected_1',
  role: UserType.STUDENT,
  avatar: null,
  defaultAvatar: null,
  status: Status.REJECTED,
  user: USER_1,
  created: '2021-07-01T21:12:55.095000+00:00',
};

export const GROUP_USER_REJECTED_1: GroupUser = {
  ...USER_GROUP_REJECTED_1,
  group: GROUP_4,
};

export const USER_GROUP_0: UserGroup = {
  __typename: 'UserGroupNode',
  id: 'auth_user_group_0',
  role: UserType.STUDENT,
  avatar: null,
  defaultAvatar: null,
  status: Status.ACCEPTED,
  user: USER_0,
  created: '2021-07-01T21:12:55.095000+00:00',
};

export const USER_GROUP_1: UserGroup = {
  __typename: 'UserGroupNode',
  id: 'user_group_1',
  role: UserType.STUDENT,
  avatar: null,
  defaultAvatar: null,
  status: Status.ACCEPTED,
  user: USER_1,
  created: '2021-07-01T21:12:55.095000+00:00',
};

export const GROUP_USER_1: GroupUser = {
  ...USER_GROUP_1,
  group: GROUP_1,
};

export const USER_GROUP_1_ICON: UserGroup = {
  ...USER_GROUP_1,
  avatar: null,
  defaultAvatar: {
    __typename: 'DefaultAvatarNode',
    id: 'RGVmYXVsdEF2YXRhck5vZGU6YjU5ZWM0MTUtYjAyYi00MzA2LTgzZGUtMmZkZjJjMzUwNTM1',
    avatar:
      'https://gradoo-staging.eu-central-1.linodeobjects.com/gradoo-staging/media/default_avatars/user_avatar_3.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=X38UDV7XC6SS648X2H2H%2F20230119%2Feu-central-1%2Fs3%2Faws4_request&X-Amz-Date=20230119T024805Z&X-Amz-Expires=3600&X-Amz-SignedHeaders=host&X-Amz-Signature=a20dbdd1225eaec6ca6a6b40fb13fc0f36b14d1be308df28878d658e84eaacc4',
  },
};

export const USER_GROUP_1_AVATAR: UserGroup = {
  ...USER_GROUP_1,
  avatar:
    'https://gradoo-staging.eu-central-1.linodeobjects.com/gradoo-staging/media/user_avatars/dbace6b6-db06-4edc-ba37-70806e4a9637-C004EB5F-187E-426C-98A6-FCB679D044E9.jpg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=X38UDV7XC6SS648X2H2H%2F20230119%2Feu-central-1%2Fs3%2Faws4_request&X-Amz-Date=20230119T024805Z&X-Amz-Expires=3600&X-Amz-SignedHeaders=host&X-Amz-Signature=eca137225fcccaf2050e4c45cde206b63a1c3b41c39161e4b490596e8b6fe580',
  defaultAvatar: {
    __typename: 'DefaultAvatarNode',
    id: 'RGVmYXVsdEF2YXRhck5vZGU6YjU5ZWM0MTUtYjAyYi00MzA2LTgzZGUtMmZkZjJjMzUwNTM1',
    avatar:
      'https://gradoo-staging.eu-central-1.linodeobjects.com/gradoo-staging/media/default_avatars/user_avatar_3.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=X38UDV7XC6SS648X2H2H%2F20230119%2Feu-central-1%2Fs3%2Faws4_request&X-Amz-Date=20230119T024805Z&X-Amz-Expires=3600&X-Amz-SignedHeaders=host&X-Amz-Signature=a20dbdd1225eaec6ca6a6b40fb13fc0f36b14d1be308df28878d658e84eaacc4',
  },
};

export const USER_GROUP_2: UserGroup = {
  __typename: 'UserGroupNode',
  id: 'user_group_2',
  role: UserType.STUDENT,
  avatar: null,
  defaultAvatar: null,
  status: Status.ACCEPTED,
  user: USER_2,
  created: '2021-07-01T21:12:55.095000+00:00',
};

export const GROUP_USER_2: GroupUser = {
  ...USER_GROUP_2,
  group: GROUP_1,
};

export const USER_GROUP_3: UserGroup = {
  __typename: 'UserGroupNode',
  id: 'user_group_3',
  role: UserType.STUDENT,
  avatar: null,
  defaultAvatar: null,
  status: Status.ACCEPTED,
  user: USER_3,
  created: '2021-07-01T21:12:55.095000+00:00',
};

export const GROUP_USER_3: GroupUser = {
  ...USER_GROUP_3,
  group: GROUP_1,
};

export const USER_GROUP_4: UserGroup = {
  __typename: 'UserGroupNode',
  id: 'user_group_4',
  role: UserType.STUDENT,
  avatar: null,
  defaultAvatar: null,
  status: Status.ACCEPTED,
  user: USER_4,
  created: '2021-07-01T21:12:55.095000+00:00',
};

export const GROUP_USER_4: GroupUser = {
  ...USER_GROUP_4,
  group: GROUP_1,
};

export const GROUP_SAMPLE_1: GroupSample = {
  ...GROUP_1,
  sampleMembers: [
    USER_GROUP_0,
    USER_GROUP_1,
    USER_GROUP_2,
    USER_GROUP_3,
    USER_GROUP_4,
  ],
  usersCount: 10,
};

export const GROUP_ICON_2: GroupIcon = {
  __typename: 'GroupIconNode',
  id: 'group_icon_2',
  icon: 'https://gradoo-staging.eu-central-1.linodeobjects.com/gradoo-staging/media/group_icons/group_icon_8.png',
};

export const GROUP_ICON_3: GroupIcon = {
  __typename: 'GroupIconNode',
  id: 'group_icon_3',
  icon: 'https://gradoo-staging.eu-central-1.linodeobjects.com/gradoo-staging/media/group_icons/group_icon_7.png',
};
export const GROUP_ICON_4: GroupIcon = {
  __typename: 'GroupIconNode',
  id: 'group_icon_4',
  icon: 'https://gradoo-staging.eu-central-1.linodeobjects.com/gradoo-staging/media/group_icons/group_icon_6.png',
};

export const GROUP_ICON_LIST = [
  GROUP_ICON_1,
  GROUP_ICON_2,
  GROUP_ICON_3,
  GROUP_ICON_4,
];

export const DEFAULT_AVATAR_1: DefaultAvatar = {
  __typename: 'DefaultAvatarNode',
  id: 'default_avatar_1',
  avatar:
    'https://gradoo-staging.eu-central-1.linodeobjects.com/gradoo-staging/media/default_avatars/user_avatar_97.png',
};

export const DEFAULT_AVATAR_2: DefaultAvatar = {
  __typename: 'DefaultAvatarNode',
  id: 'default_avatar_2',
  avatar:
    'https://gradoo-staging.eu-central-1.linodeobjects.com/gradoo-staging/media/default_avatars/user_avatar_96.png',
};

export const DEFAULT_AVATAR_3: DefaultAvatar = {
  __typename: 'DefaultAvatarNode',
  id: 'default_avatar_3',
  avatar:
    'https://gradoo-staging.eu-central-1.linodeobjects.com/gradoo-staging/media/default_avatars/user_avatar_95.png',
};

export const DEFAULT_AVATAR_4: DefaultAvatar = {
  __typename: 'DefaultAvatarNode',
  id: 'default_avatar_4',
  avatar:
    'https://gradoo-staging.eu-central-1.linodeobjects.com/gradoo-staging/media/default_avatars/user_avatar_94.png',
};

export const DEFAULT_AVATAR_LIST = [
  DEFAULT_AVATAR_1,
  DEFAULT_AVATAR_2,
  DEFAULT_AVATAR_3,
  DEFAULT_AVATAR_4,
];

export const COLOR_1: Color = {
  __typename: 'ColorNode',
  id: 'color_1',
  hex: '#C74C00',
};

export const COLOR_2: Color = {
  __typename: 'ColorNode',
  id: 'color_2',
  hex: '#E21034',
};
export const COLOR_3: Color = {
  __typename: 'ColorNode',
  id: 'color_3',
  hex: '#DA1F7B',
};

export const COLOR_LIST: Color[] = [
  COLOR_1,
  COLOR_2,
  COLOR_3,
  {
    __typename: 'ColorNode',
    id: 'color_4',
    hex: '#C606E2',
  },
  {
    __typename: 'ColorNode',
    id: 'color_5',
    hex: '#9551D6',
  },
  {
    __typename: 'ColorNode',
    id: 'color_6',
    hex: '#8252F0',
  },
  {
    __typename: 'ColorNode',
    id: 'color_7',
    hex: '#5F62ED',
  },
  {
    __typename: 'ColorNode',
    id: 'color_8',
    hex: '#1D6CEC',
  },
  {
    __typename: 'ColorNode',
    id: 'color_9',
    hex: '#077AB1',
  },
  {
    __typename: 'ColorNode',
    id: 'color_10',
    hex: '#027D9D',
  },
  {
    __typename: 'ColorNode',
    id: 'color_11',
    hex: '#028270',
  },
  {
    __typename: 'ColorNode',
    id: 'color_12',
    hex: '#3B8301',
  },
  {
    __typename: 'ColorNode',
    id: 'color_13',
    hex: '#767803',
  },
  {
    __typename: 'ColorNode',
    id: 'color_14',
    hex: '#C99504',
  },
  {
    __typename: 'ColorNode',
    id: 'color_15',
    hex: '#C67800',
  },
];

export const ICON_1: Icon = {
  __typename: 'IconNode',
  id: 'icon_1',
  icon: 'icn/teams/plane',
};

export const ICON_2: Icon = {
  __typename: 'IconNode',
  id: 'icon_2',
  icon: 'icn/teams/basket',
};

export const ICON_3: Icon = {
  __typename: 'IconNode',
  id: 'icon_3',
  icon: 'icn/teams/building',
};

export const ICON_LIST: Icon[] = [
  ICON_1,
  ICON_2,
  ICON_3,
  {
    __typename: 'IconNode',
    id: 'icon_4',
    icon: 'icn/teams/book',
  },
  {
    __typename: 'IconNode',
    id: 'icon_5',
    icon: 'icn/teams/burger',
  },
  {
    __typename: 'IconNode',
    id: 'icon_6',
    icon: 'icn/teams/car',
  },
  {
    __typename: 'IconNode',
    id: 'icon_7',
    icon: 'icn/teams/clock',
  },
  {
    __typename: 'IconNode',
    id: 'icon_8',
    icon: 'icn/teams/clothing',
  },
  {
    __typename: 'IconNode',
    id: 'icon_9',
    icon: 'icn/teams/flag',
  },
  {
    __typename: 'IconNode',
    id: 'icon_10',
    icon: 'icn/teams/gaming',
  },
  {
    __typename: 'IconNode',
    id: 'icon_11',
    icon: 'icn/teams/glitter',
  },
  {
    __typename: 'IconNode',
    id: 'icon_12',
    icon: 'icn/teams/heart',
  },
  {
    __typename: 'IconNode',
    id: 'icon_13',
    icon: 'icn/teams/home',
  },
  {
    __typename: 'IconNode',
    id: 'icon_14',
    icon: 'icn/teams/images',
  },
  {
    __typename: 'IconNode',
    id: 'icon_15',
    icon: 'icn/teams/institute',
  },
  {
    __typename: 'IconNode',
    id: 'icon_16',
    icon: 'icn/teams/like',
  },
  {
    __typename: 'IconNode',
    id: 'icon_17',
    icon: 'icn/teams/megaphone',
  },
  {
    __typename: 'IconNode',
    id: 'icon_18',
    icon: 'icn/teams/money_euro',
  },
  {
    __typename: 'IconNode',
    id: 'icon_19',
    icon: 'icn/teams/package',
  },
  {
    __typename: 'IconNode',
    id: 'icon_20',
    icon: 'icn/teams/paper_plane',
  },
  {
    __typename: 'IconNode',
    id: 'icon_21',
    icon: 'icn/teams/pen',
  },
  {
    __typename: 'IconNode',
    id: 'icon_22',
    icon: 'icn/teams/present',
  },
  {
    __typename: 'IconNode',
    id: 'icon_23',
    icon: 'icn/teams/rocket',
  },
  {
    __typename: 'IconNode',
    id: 'icon_24',
    icon: 'icn/teams/star',
  },
  {
    __typename: 'IconNode',
    id: 'icon_25',
    icon: 'icn/teams/trophy',
  },
  {
    __typename: 'IconNode',
    id: 'icon_26',
    icon: 'icn/teams/virus',
  },
  {
    __typename: 'IconNode',
    id: 'icon_27',
    icon: 'icn/teams/world',
  },
];

export const TEAM_1: Team = {
  __typename: 'TeamNode',
  id: 'team_1',
  name: 'Abschlusspullover Team',
  type: TeamType.PROM,
  approvalSetting: ApprovalSetting.ANY_MEMBER,
  color: COLOR_1,
  icon: ICON_1,
  isHidden: false,
  created: '2021-02-26T21:12:55.095000+00:00',
};

export const TEAM_2: Team = {
  __typename: 'TeamNode',
  id: 'team_2',
  name: 'Yearbook Team',
  type: TeamType.YEARBOOK,
  approvalSetting: ApprovalSetting.ANY_MEMBER,
  color: COLOR_2,
  icon: ICON_2,
  isHidden: false,
  created: '2021-02-26T21:12:55.095000+00:00',
};

export const TEAM_3: Team = {
  __typename: 'TeamNode',
  id: 'team_3',
  name: 'Shirts Team',
  type: TeamType.OTHER,
  approvalSetting: ApprovalSetting.ANY_MEMBER,
  color: COLOR_3,
  icon: ICON_3,
  isHidden: false,
  created: '2021-02-26T21:12:55.095000+00:00',
};

export const TEAM_4: Team = {
  __typename: 'TeamNode',
  id: 'team_4',
  name: 'Core Team',
  type: TeamType.CORE,
  approvalSetting: ApprovalSetting.ANY_MEMBER,
  color: COLOR_3,
  icon: ICON_3,
  isHidden: false,
  created: '2021-02-26T21:12:55.095000+00:00',
};

export const USER_TEAM: UserTeam = {
  __typename: 'TeamNode',
  id: 'team_2',
  name: 'Yearbook Team',
  color: COLOR_2,
  icon: ICON_2,
  group: GROUP_1,
  avatar: null,
};

export const TEAMS: Team[] = [TEAM_1, TEAM_2, TEAM_3];
export const TEAMS2: Team[] = [TEAM_1, TEAM_2, TEAM_3, TEAM_4];
export const TEAM_USER_0: TeamUser = {
  __typename: 'UserTeamNode',
  id: 'team_user_0',
  isAdmin: true,
  status: Status.ACCEPTED,
  avatar: null,
  defaultAvatar: null,
  user: USER_0,
  created: '2021-07-01T21:12:55.095000+00:00',
  modified: '2021-07-02T21:12:55.095000+00:00',
};

export const TEAM_USER_1: TeamUser = {
  __typename: 'UserTeamNode',
  id: 'team_user_1',
  isAdmin: true,
  status: Status.ACCEPTED,
  avatar: null,
  defaultAvatar: null,
  user: USER_1,
  created: '2021-07-01T21:12:55.095000+00:00',
  modified: '2021-07-02T21:12:55.095000+00:00',
};

export const TEAM_USER_2: TeamUser = {
  __typename: 'UserTeamNode',
  id: 'team_user_2',
  isAdmin: false,
  status: Status.ACCEPTED,
  avatar: null,
  defaultAvatar: null,
  user: USER_2,
  created: '2021-07-01T21:12:55.095000+00:00',
  modified: '2021-07-02T21:12:55.095000+00:00',
};

export const TEAM_USER_2_ICON: TeamUser = {
  ...TEAM_USER_2,
  avatar: null,
  defaultAvatar:
    'https://gradoo-staging.eu-central-1.linodeobjects.com/gradoo-staging/media/default_avatars/user_avatar_91.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=X38UDV7XC6SS648X2H2H%2F20230119%2Feu-central-1%2Fs3%2Faws4_request&X-Amz-Date=20230119T025516Z&X-Amz-Expires=3600&X-Amz-SignedHeaders=host&X-Amz-Signature=10ab557819a178ade6779f8867cbeed1295f55cf6ed0c4590c5a5655a3cf8535',
};

export const TEAM_USER_2_AVATAR: TeamUser = {
  ...TEAM_USER_2,
  avatar:
    'https://gradoo-staging.eu-central-1.linodeobjects.com/gradoo-staging/media/user_avatars/dbace6b6-db06-4edc-ba37-70806e4a9637-08A7F855-FF3F-4BE7-8CD3-8B7529F73DC8.jpg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=X38UDV7XC6SS648X2H2H%2F20230119%2Feu-central-1%2Fs3%2Faws4_request&X-Amz-Date=20230119T025516Z&X-Amz-Expires=3600&X-Amz-SignedHeaders=host&X-Amz-Signature=6f30d40bbfa3618800df10f100d34f659157333da634099d011688243784d50b',
  defaultAvatar:
    'https://gradoo-staging.eu-central-1.linodeobjects.com/gradoo-staging/media/default_avatars/user_avatar_91.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=X38UDV7XC6SS648X2H2H%2F20230119%2Feu-central-1%2Fs3%2Faws4_request&X-Amz-Date=20230119T025516Z&X-Amz-Expires=3600&X-Amz-SignedHeaders=host&X-Amz-Signature=10ab557819a178ade6779f8867cbeed1295f55cf6ed0c4590c5a5655a3cf8535',
};

export const TEAM_USER_3: TeamUser = {
  __typename: 'UserTeamNode',
  id: 'team_user_3',
  isAdmin: false,
  status: Status.ACCEPTED,
  avatar: null,
  defaultAvatar: null,
  user: USER_3,
  created: '2021-07-01T21:12:55.095000+00:00',
  modified: '2021-07-02T21:12:55.095000+00:00',
};

export const TEAM_USER_4: TeamUser = {
  __typename: 'UserTeamNode',
  id: 'user_group_4',
  isAdmin: false,
  status: Status.ACCEPTED,
  avatar: null,
  defaultAvatar: null,
  user: USER_4,
  created: '2021-07-01T21:12:55.095000+00:00',
  modified: '2021-07-02T21:12:55.095000+00:00',
};

export const TEAM_USER_PENDING_0: TeamUser = {
  __typename: 'UserTeamNode',
  id: 'team_user_pending_0',
  isAdmin: false,
  status: Status.PENDING,
  avatar: null,
  defaultAvatar: null,
  user: USER_0,
  created: '2021-07-01T21:12:55.095000+00:00',
  modified: '2021-07-02T21:12:55.095000+00:00',
};

export const TEAM_USER_PENDING_1: TeamUser = {
  __typename: 'UserTeamNode',
  id: 'team_user_pending_1',
  isAdmin: false,
  status: Status.PENDING,
  avatar: null,
  defaultAvatar: null,
  user: USER_1,
  created: '2021-07-01T21:12:55.095000+00:00',
  modified: '2021-07-02T21:12:55.095000+00:00',
};

export const TEAM_USER_REJECTED_1: TeamUser = {
  __typename: 'UserTeamNode',
  id: 'team_user_rejected_1',
  isAdmin: false,
  status: Status.REJECTED,
  avatar: null,
  defaultAvatar: null,
  user: USER_2,
  created: '2021-07-01T21:12:55.095000+00:00',
  modified: '2021-07-02T21:12:55.095000+00:00',
};

export const TEAM_SAMPLE_1: TeamSample = {
  ...TEAM_1,
  usersCount: 5,
  sampleMembers: [
    TEAM_USER_0,
    TEAM_USER_1,
    TEAM_USER_2,
    TEAM_USER_3,
    TEAM_USER_4,
  ],
};

export const TEAM_SAMPLE_2: TeamSample = {
  ...TEAM_2,
  usersCount: 5,
  sampleMembers: [
    TEAM_USER_0,
    TEAM_USER_1,
    TEAM_USER_2,
    TEAM_USER_3,
    TEAM_USER_4,
  ],
};

export const TEAM_SAMPLE_3: TeamSample = {
  ...TEAM_3,
  usersCount: 5,
  sampleMembers: [
    TEAM_USER_0,
    TEAM_USER_1,
    TEAM_USER_2,
    TEAM_USER_3,
    TEAM_USER_4,
  ],
};

export const TEAM_SAMPLE_4: TeamSample = {
  ...TEAM_4,
  usersCount: 5,
  sampleMembers: [
    TEAM_USER_0,
    TEAM_USER_1,
    TEAM_USER_2,
    TEAM_USER_3,
    TEAM_USER_4,
  ],
};

export const TEAM_DETAIL_1: TeamDetail = {
  ...TEAM_1,
  users: [TEAM_USER_0, TEAM_USER_1, TEAM_USER_2, TEAM_USER_3, TEAM_USER_4],
};

export const TEAM_DETAIL_2: TeamDetail = {
  ...TEAM_2,
  users: [TEAM_USER_0, TEAM_USER_1, TEAM_USER_2, TEAM_USER_3, TEAM_USER_4],
};

export const TEAM_DETAIL_3: TeamDetail = {
  ...TEAM_3,
  users: [TEAM_USER_0, TEAM_USER_1, TEAM_USER_2, TEAM_USER_3, TEAM_USER_4],
};

export const TEAM_DETAIL_4: TeamDetail = {
  ...TEAM_4,
  users: [TEAM_USER_0, TEAM_USER_1, TEAM_USER_2, TEAM_USER_3, TEAM_USER_4],
};

export const POST_1: Post = {
  __typename: 'PostNode',
  id: 'post_1',
  owner: Owner.TEAM,
  text: 'Post 1',
  createdBy: USER_GROUP_0,
  poll: null,
  images: null,
  likesCount: 1,
  likes: {
    __typename: 'LikeNodeConnection',
    edges: [],
  },
  team: TEAM_1,
  comments: [],
  created: '2021-07-21T21:12:55.095000+00:00',
};

export const POST_1_U0_LIKED: Post = {
  ...POST_1,
  likes: {
    __typename: 'LikeNodeConnection',
    edges: [
      {
        __typename: 'LikeNodeEdge',
        node: {
          __typename: 'LikeNode',
          userGroup: {
            __typename: 'UserGroupNode',
            id: USER_GROUP_0.id,
          },
        },
      },
    ],
  },
};

export const POST_T1_U0: Post = {
  __typename: 'PostNode',
  id: 'post_t1_1',
  owner: Owner.TEAM,
  text: 'Hi guys, we need our thoughts and ideas on the profile section of the yearbook. Should we set up a question pool and everybody can decide which questions to answer or do we want a fixed list?',
  createdBy: USER_GROUP_0,
  poll: null,
  images: null,
  likesCount: 1,
  likes: {
    __typename: 'LikeNodeConnection',
    edges: [],
  },
  team: TEAM_1,
  comments: [],
  created: '2021-07-21T21:12:55.095000+00:00',
};

export const POST_T1_U2: Post = {
  __typename: 'PostNode',
  id: 'post_t1_2',
  owner: Owner.TEAM,
  text: 'Hi guys',
  createdBy: USER_GROUP_2,
  poll: null,
  images: null,
  likesCount: 1,
  likes: {
    __typename: 'LikeNodeConnection',
    edges: [],
  },
  team: TEAM_1,
  comments: [],
  created: '2021-07-25T15:02:55.095000+00:00',
};

export const POST_T1_U3: Post = {
  __typename: 'PostNode',
  id: 'post_t1_3',
  owner: Owner.TEAM,
  text: 'TODO: Pool',
  createdBy: USER_GROUP_3,
  poll: null,
  images: null,
  likesCount: 1,
  likes: {
    __typename: 'LikeNodeConnection',
    edges: [],
  },
  team: TEAM_1,
  comments: [],
  created: '2021-07-21T21:12:55.095000+00:00',
};
export const POLL_OPTION_1_1: PollOption = {
  __typename: 'PollOptionNode',
  id: 'poll_1_opt_1',
  text: 'WasABI',
  image: null,
  votes: [],
  percentage: 0.08,
  votesNumber: 8,
};

export const POLL_VOTE_OP11_U0: PollVote = {
  __typename: 'PollVoteNode',
  id: 'poll_vote_1_1',
  userGroup: {
    __typename: 'UserGroupNode',
    user: {
      __typename: 'UserNode',
      id: USER_0.id,
    },
  },
};

export const POLL_OPTION_1_1_U0_VOTED: PollOption = {
  __typename: 'PollOptionNode',
  id: 'poll_1_opt_1',
  text: 'WasABI',
  image: null,
  votes: [POLL_VOTE_OP11_U0],
  percentage: 0.1,
  votesNumber: 10,
};

export const POLL_OPTION_1_2: PollOption = {
  __typename: 'PollOptionNode',
  id: 'poll_1_opt_2',
  text: 'TZABIlando',
  image: null,
  votes: [],
  percentage: 0.2,
  votesNumber: 20,
};

export const POLL_VOTE_OP12_U0: PollVote = {
  __typename: 'PollVoteNode',
  id: 'poll_vote_1_2',
  userGroup: {
    __typename: 'UserGroupNode',
    user: {
      __typename: 'UserNode',
      id: USER_0.id,
    },
  },
};

export const POLL_OPTION_1_2_U0_VOTED: PollOption = {
  __typename: 'PollOptionNode',
  id: 'poll_1_opt_2',
  text: 'WasABI',
  image: null,
  votes: [POLL_VOTE_OP12_U0],
  percentage: 0.25,
  votesNumber: 25,
};

export const POLL_VOTE_OP12_U1: PollVote = {
  __typename: 'PollVoteNode',
  id: 'poll_vote_1_2',
  userGroup: {
    __typename: 'UserGroupNode',
    user: {
      __typename: 'UserNode',
      id: USER_1.id,
    },
  },
};

export const POLL_OPTION_1_2_U1_VOTED: PollOption = {
  __typename: 'PollOptionNode',
  id: 'poll_1_opt_2',
  text: 'WasABI',
  image: null,
  votes: [POLL_VOTE_OP12_U1],
  percentage: 0.25,
  votesNumber: 25,
};

export const POLL_OPTION_1_3: PollOption = {
  __typename: 'PollOptionNode',
  id: 'poll_1_opt_3',
  text: 'ABIleave I can fly',
  image: null,
  votes: [],
  percentage: 0.72,
  votesNumber: 72,
};

export const COMMENT_1: Comment = {
  __typename: 'CommentNode',
  id: 'comment_p1_1',
  text: 'An summo saepe maiestatis sit, ei saepe lobortis senserit eos. 🌞',
  userGroup: USER_GROUP_0,
  likesCount: 0,
  likes: null,
  created: '2021-07-18T21:12:55.095000+00:00',
  owner: Owner.USER,
  team: null,
};

export const COMMENT_1_U0_LIKED: Comment = {
  ...COMMENT_1,
  likesCount: 1,
  likes: {
    __typename: 'CommentLikeNodeConnection',
    edges: [
      {
        __typename: 'CommentLikeNodeEdge',
        node: {
          __typename: 'CommentLikeNode',
          userGroup: {
            __typename: 'UserGroupNode',
            id: USER_GROUP_0.id,
          },
        },
      },
    ],
  },
};

export const COMMENT_2: Comment = {
  __typename: 'CommentNode',
  id: 'comment_p1_2',
  text: 'An summo saepe maiestatis sit',
  userGroup: USER_GROUP_2,
  likesCount: 0,
  likes: null,
  created: '2021-07-22T21:12:55.095000+00:00',
  owner: Owner.USER,
  team: null,
};

export const COMMENT_3: Comment = {
  ...COMMENT_2,
  id: 'comment_p1_3',
  owner: Owner.TEAM,
  team: TEAM_2,
};

export const BASE_POLL_1: BasePoll = {
  __typename: 'PollNode',
  id: 'poll_1',
  question: 'What should our motto be?',
  deadline: '2021-08-25T15:00:00.095000+00:00',
  answersAllowed: 1,
  randomizeOptions: true,
  allowRevote: false,
  allowComments: true,
  anonymousVotes: false,
  privateResults: false,
  options: [POLL_OPTION_1_1, POLL_OPTION_1_2, POLL_OPTION_1_3],
  created: '2021-07-25T15:02:55.095000+00:00',
};

export const BASE_POST_POLL_1: BasePost = {
  __typename: 'PostNode',
  id: 'post_poll_1',
  owner: Owner.TEAM,
  text: '',
  createdBy: USER_GROUP_0,
  images: null,
  likesCount: 0,
  team: TEAM_1,
  comments: [COMMENT_1],
  created: '2021-07-21T21:12:55.095000+00:00',
};

export const POLL_1: Poll = {
  ...BASE_POLL_1,
  post: {
    ...BASE_POST_POLL_1,
    likes: {
      __typename: 'LikeNodeConnection',
      edges: [],
    },
  },
};

export const POST_POLL_1: Post = {
  ...BASE_POST_POLL_1,
  likes: {
    __typename: 'LikeNodeConnection',
    edges: [],
  },
  poll: BASE_POLL_1,
};

export const BASE_POLL_T1_1: BasePoll = {
  __typename: 'PollNode',
  id: 'poll_t1_1',
  question: 'Where will we celebrate?',
  deadline: '2021-08-25T15:02:55.095000+00:00',
  answersAllowed: 1,
  randomizeOptions: false,
  allowRevote: false,
  allowComments: false,
  anonymousVotes: false,
  privateResults: false,
  options: [
    {
      __typename: 'PollOptionNode',
      id: 'poll_t1_1_opt_1',
      text: 'Goldstar venue',
      image: null,
      votes: [],
      percentage: 0.15,
      votesNumber: 15,
    },
    {
      __typename: 'PollOptionNode',
      id: 'poll_t1_1_opt_2',
      text: 'Total Yard',
      image: null,
      votes: [],
      percentage: 0.3,
      votesNumber: 30,
    },
    {
      __typename: 'PollOptionNode',
      id: 'poll_t1_1_opt_3',
      text: 'Bluefeast venue',
      image: null,
      votes: [],
      percentage: 0.55,
      votesNumber: 55,
    },
  ],
  created: '2021-07-25T15:02:55.095000+00:00',
};

export const POST_POLL_T1_1: Post = {
  ...POST_1,
  poll: BASE_POLL_T1_1,
};

export const POLL_T1_1: Poll = {
  ...BASE_POLL_T1_1,
  post: POST_1,
};

export const BASE_POST_2: BasePost = {
  __typename: 'PostNode',
  id: 'post_poll_t1_2',
  owner: Owner.TEAM,
  text: '',
  createdBy: USER_GROUP_0,
  images: null,
  likesCount: 1,
  team: TEAM_1,
  comments: [COMMENT_1],
  created: '2021-07-21T21:12:55.095000+00:00',
};

export const POST_2: Post = {
  ...BASE_POST_2,
  likes: {
    __typename: 'LikeNodeConnection',
    edges: [],
  },
  comments: [COMMENT_1],
  poll: null,
};

export const BASE_POLL_T1_2: BasePoll = {
  __typename: 'PollNode',
  id: 'poll_t1_2',
  question: 'Where will we celebrate?',
  deadline: '2021-08-25T15:02:55.095000+00:00',
  answersAllowed: 1,
  randomizeOptions: false,
  allowRevote: false,
  allowComments: false,
  anonymousVotes: false,
  privateResults: false,
  options: [
    {
      __typename: 'PollOptionNode',
      id: 'poll_t1_2_opt_1',
      text: 'Goldstar venue',
      image: 'foo',
      votes: [],
      percentage: 0.17,
      votesNumber: 17,
    },
    {
      __typename: 'PollOptionNode',
      id: 'poll_t1_2_opt_2',
      text: 'Total Yard',
      image: 'foo',
      votes: [],
      percentage: 0.29,
      votesNumber: 29,
    },
    {
      __typename: 'PollOptionNode',
      id: 'poll_t1_3_opt_3',
      text: 'Bluefeast venue',
      image: 'foo',
      votes: [],
      percentage: 0.54,
      votesNumber: 54,
    },
  ],
  created: '2021-07-25T15:02:55.095000+00:00',
};

export const POST_POLL_T1_2: Post = {
  ...POST_2,
  poll: BASE_POLL_T1_2,
};

export const POLL_T1_2: Poll = {
  ...BASE_POLL_T1_2,
  post: POST_2,
};

export const USER_INPUT_1: CreateUserInput = {
  firstName: 'Max',
  lastName: 'Mustermann',
  email: 'max@gmail.com',
  phoneCountryCode: '49',
  phone: '12345678',
  password: 'pass123.',
  passwordConfirmation: 'pass123.',
  dataPolicyAcceptance: true,
  dataPolicyVersion: '1',
  termsAcceptance: true,
  termsVersion: '1',
  subscribeNewsletter: true,
  communicationLanguage: 'ENGLISH',
  countryOfResidence: 'DE',
};

export const INSTITUTE_INPUT_1: RequestMissingInstituteInput = {
  name: INSTITUTE_1.name,
  zip: INSTITUTE_1.zip as string,
  website: 'www.website.com',
  country: COUNTRIES.DE.code,
  street: 'street',
  type: 'inst_type_1',
  city: 'Berlin',
};

export const CREATE_TEAM_INPUT_1: CreateTeamInput = {
  name: TEAM_1.name,
  color: COLOR_1.id,
  icon: ICON_1.id,
  group: GROUP_1.id,
};

export const UPDATE_TEAM_INPUT_1: UpdateTeamInput = {
  id: TEAM_1.id,
  name: `${TEAM_1.name} updated`,
  color: COLOR_2.id,
  icon: ICON_2.id,
};

export const TODO_ITEM_TL1_1: ToDoItem = {
  id: 'todo_item_tl1_1',
  title: 'ToDo Item TL1 #1',
  creationDate: '2021-08-25T15:00:00',
  creator: USER_1,
  finished: true,
};

export const TODO_ITEM_TL1_2: ToDoItem = {
  id: 'todo_item_tl1_2',
  title: 'ToDo Item TL1 #2',
  creationDate: '2021-09-24T15:00:00',
  creator: USER_2,
  finished: false,
};

export const TODO_ITEM_TL1_3: ToDoItem = {
  id: 'todo_item_tl1_3',
  title: 'ToDo Item TL1 #1',
  creationDate: '2021-09-22T15:00:00',
  creator: USER_3,
  finished: true,
};

export const TODO_ITEM_TL2_1: ToDoItem = {
  id: 'todo_item_tl2_1',
  title: 'ToDo Item TL1 #1',
  creationDate: '2021-09-25T15:00:00',
  creator: USER_4,
  finished: true,
};

export const TODO_T1_1: ToDoList = {
  id: 'todo_t1_1',
  text: 'ToDo List T1 #1',
  team: TEAM_1,
  items: [TODO_ITEM_TL1_1, TODO_ITEM_TL1_2, TODO_ITEM_TL1_3],
};

export const TODO_T2_1: ToDoList = {
  id: 'todo_t2_1',
  text: 'ToDo List T2 #1',
  team: TEAM_2,
  items: [TODO_ITEM_TL2_1],
};

export const TODO_T3_1: ToDoList = {
  id: 'todo_t3_1',
  text: 'ToDo List T3 #1',
  team: TEAM_3,
  items: [],
};

export const TODO_T1_2: ToDoList = {
  id: 'todo_t1_2',
  text: 'ToDo List T1 #2',
  team: TEAM_1,
  items: [],
};

export const TODO_T1_3: ToDoList = {
  id: 'todo_t1_3',
  text: 'ToDo List T1 #3',
  team: TEAM_1,
  items: [],
};

export const TODO_T2_2: ToDoList = {
  id: 'todo_t2_2',
  text: 'ToDo List T2 #2',
  team: TEAM_2,
  items: [],
};

export const CALENDAR_DATE_OBJ_3: CalendarDateObject =
  calendarDateObject('2021-08-03');

export const CALENDAR_DATE_OBJ_6: CalendarDateObject =
  calendarDateObject('2021-08-06');

export const CALENDAR_DATE_OBJ_18: CalendarDateObject =
  calendarDateObject('2021-08-18');

export const CALENDAR_DATE_OBJ_26: CalendarDateObject =
  calendarDateObject('2021-08-26');

export const PROFILE_PAGE_PHOTO_1: ProfilePagePhoto = {
  __typename: 'ProfilePagePhotoNode',
  id: 'photo_1',
  image: 'photo_1',
};

export const PROFILE_PAGE_PHOTO_CATEGORY_1: ProfilePagePhotoCategory = {
  __typename: 'ProfilePagePhotoCategoryNode',
  id: 'photo_category_1',
  name: 'Photo category 1',
  profilePagePhotos: null,
};

export const PROFILE_PAGE_PHOTO_CATEGORY_2: ProfilePagePhotoCategory = {
  __typename: 'ProfilePagePhotoCategoryNode',
  id: 'photo_category_2',
  name: 'Photo category 2',
  profilePagePhotos: null,
};

export const PROFILE_PAGE_PHOTO_CATEGORY_3: ProfilePagePhotoCategory = {
  __typename: 'ProfilePagePhotoCategoryNode',
  id: 'photo_category_3',
  name: 'Photo category 3',
  profilePagePhotos: null,
};

export const PROFILE_PAGE_ANSWER_Q1: ProfilePageAnswer = {
  __typename: 'ProfilePageAnswerNode',
  id: 'question_1_answer',
  text: 'Some teacher',
  profilePageQuestionOption: null,
  userGroup: {
    __typename: 'UserGroupNode',
    id: USER_GROUP_0.id,
  },
};

export const PROFILE_PAGE_QUESTION_1: ProfilePageQuestion = {
  __typename: 'ProfilePageQuestionNode',
  id: 'question_1',
  text: 'Who is your favorite teacher?',
  order: 1,
  profilePageQuestionOptions: null,
  profilePageAnswers: null,
};

export const PROFILE_PAGE_QUESTION_1_WITH_ANSWER: ProfilePageQuestion = {
  ...PROFILE_PAGE_QUESTION_1,
  profilePageAnswers: {
    __typename: 'ProfilePageAnswerNodeConnection',
    edges: [
      {
        __typename: 'ProfilePageAnswerNodeEdge',
        node: PROFILE_PAGE_ANSWER_Q1,
      },
    ],
  },
};

export const PROFILE_PAGE_QUESTION_2: ProfilePageQuestion = {
  __typename: 'ProfilePageQuestionNode',
  id: 'question_2',
  text: 'Best moment in class:',
  order: 2,
  profilePageQuestionOptions: null,
  profilePageAnswers: null,
};

export const PROFILE_PAGE_QUESTION_3: ProfilePageQuestion = {
  __typename: 'ProfilePageQuestionNode',
  id: 'question_3',
  text: 'Question 3',
  order: 3,
  profilePageQuestionOptions: null,
  profilePageAnswers: null,
};

export const PROFILE_PAGE_QUESTIONS: ProfilePageQuestion[] = [
  PROFILE_PAGE_QUESTION_1,
  PROFILE_PAGE_QUESTION_2,
  PROFILE_PAGE_QUESTION_3,
  {
    __typename: 'ProfilePageQuestionNode',
    id: 'question_4',
    text: 'Question 4',
    order: 4,
    profilePageQuestionOptions: null,
    profilePageAnswers: null,
  },
  {
    __typename: 'ProfilePageQuestionNode',
    id: 'question_5',
    text: 'Question 5',
    order: 5,
    profilePageQuestionOptions: null,
    profilePageAnswers: null,
  },
  {
    __typename: 'ProfilePageQuestionNode',
    id: 'question_6',
    text: 'Question 6',
    order: 6,
    profilePageQuestionOptions: null,
    profilePageAnswers: null,
  },
  {
    __typename: 'ProfilePageQuestionNode',
    id: 'question_7',
    text: 'Question 7',
    order: 7,
    profilePageQuestionOptions: null,
    profilePageAnswers: null,
  },
  {
    __typename: 'ProfilePageQuestionNode',
    id: 'question_8',
    text: 'Question 8',
    order: 8,
    profilePageQuestionOptions: null,
    profilePageAnswers: null,
  },
];

export const PROFILE_PAGE_SUGGESTED_QUESTION_1: ProfilePageSuggestedQuestion = {
  __typename: 'ProfilePageSuggestedQuestionNode',
  id: 'sq_1',
  text: 'Your dream job',
  options: null,
};

export const PROFILE_PAGE_SUGGESTED_QUESTION_2: ProfilePageSuggestedQuestion = {
  __typename: 'ProfilePageSuggestedQuestionNode',
  id: 'sq_2',
  text: 'Your power courses',
  options: null,
};

export const PROFILE_PAGE_RECOMMENDED_QUESTIONS: ProfilePageSuggestedQuestion[] =
  [
    PROFILE_PAGE_SUGGESTED_QUESTION_1,
    PROFILE_PAGE_SUGGESTED_QUESTION_2,
    {
      __typename: 'ProfilePageSuggestedQuestionNode',
      id: 'sq_3',
      text: 'Question 3',
      options: null,
    },
    {
      __typename: 'ProfilePageSuggestedQuestionNode',
      id: 'sq_4',
      text: 'Question 4',
      options: null,
    },
  ];

export const PROFILE_PAGE_INSPIRATIONAL_QUESTIONS: ProfilePageSuggestedQuestion[] =
  [
    {
      __typename: 'ProfilePageSuggestedQuestionNode',
      id: 'sq_5',
      text: 'Plans after graduation',
      options: null,
    },
    {
      __typename: 'ProfilePageSuggestedQuestionNode',
      id: 'sq_6',
      text: 'Best moment in class:',
      options: null,
    },
    {
      __typename: 'ProfilePageSuggestedQuestionNode',
      id: 'sq_7',
      text: 'Inspirational Question 3',
      options: null,
    },
    {
      __typename: 'ProfilePageSuggestedQuestionNode',
      id: 'sq_8',
      text: 'Inspirational Question 4',
      options: null,
    },
    {
      __typename: 'ProfilePageSuggestedQuestionNode',
      id: 'sq_9',
      text: 'Inspirational Question 5',
      options: null,
    },
    {
      __typename: 'ProfilePageSuggestedQuestionNode',
      id: 'sq_10',
      text: 'Inspirational Question 6',
      options: null,
    },
    {
      __typename: 'ProfilePageSuggestedQuestionNode',
      id: 'sq_11',
      text: 'Inspirational Question 7',
      options: null,
    },
  ];
export const PROFILE_PAGE_COMMENT_1: ProfilePageComment = {
  __typename: 'ProfilePageCommentNode',
  id: 'prof_comment_1',
  text: 'Hey Philipp, thank you for a great school time and what ever people do in such greetings, blab bla. thank you for a great school time and what ever people do in such greetings, blab bla.',
  profileUserGroup: USER_GROUP_2,
  comentatorUserGroup: USER_GROUP_0,
  created: '2021-07-25T15:02:55.095000+00:00',
};

export const PROFILE_PAGE_COMMENT_2: ProfilePageComment = {
  __typename: 'ProfilePageCommentNode',
  id: 'prof_comment_2',
  text: 'Some comment 2',
  profileUserGroup: USER_GROUP_3,
  comentatorUserGroup: USER_GROUP_0,
  created: '2021-07-30T15:02:55.095000+00:00',
};

export const PROFILE_PAGE_COMMENT_3: ProfilePageComment = {
  __typename: 'ProfilePageCommentNode',
  id: 'prof_comment_3',
  text: 'Some other comment 3',
  profileUserGroup: USER_GROUP_4,
  comentatorUserGroup: USER_GROUP_0,
  created: '2021-08-12T15:02:55.095000+00:00',
};

export const QUOTE_1: Quote = {
  __typename: 'QuotesInstanceNode',
  id: 'quote_1',
  quote: 'Funny quote from the school time',
  userGroup: USER_GROUP_0,
  quotesInstanceLikesCount: 5,
  quotesInstanceLikes: {
    __typename: 'QuotesInstanceLikeNodeConnection',
    edges: [],
  },
  created: '2022-02-07T19:10:01.679200+00:00',
};

export const QUOTE_2: Quote = {
  __typename: 'QuotesInstanceNode',
  id: 'quote_2',
  quote:
    'Teacher: blabla\nStudent X: hdhdhsuhdsu\n\nAll laugh. Teacher: blabla\nStudent X: hdhdhsuhdsu\n\nAll laugh.',
  userGroup: USER_GROUP_2,
  quotesInstanceLikesCount: 3,
  quotesInstanceLikes: {
    __typename: 'QuotesInstanceLikeNodeConnection',
    edges: [
      {
        __typename: 'QuotesInstanceLikeNodeEdge',
        node: {
          __typename: 'QuotesInstanceLikeNode',
          id: 'quotes_like_id_1',
          userGroup: {
            __typename: 'UserGroupNode',
            id: USER_GROUP_0.id,
          },
        },
      },
    ],
  },
  created: '2022-02-07T19:20:01.679200+00:00',
};

export const QUOTE_3: Quote = {
  __typename: 'QuotesInstanceNode',
  id: 'quote_3',
  quote: 'Quote 3',
  userGroup: USER_GROUP_0,
  quotesInstanceLikesCount: 0,
  quotesInstanceLikes: {
    __typename: 'QuotesInstanceLikeNodeConnection',
    edges: [],
  },
  created: '2022-02-07T19:10:01.679200+00:00',
};

//PROFILE_PAGE
export const YEARBOOK_MODULE_PROFILE_PAGE: Module = {
  __typename: 'ModuleNode',
  id: 'ym_2',
  name: 'Profile Page',
  image: '',
  type: ModuleType.PROFILE_PAGE,
  description: 'Profile Page description',
  features: ['Feature 1', 'Feature 2', 'Feature 3'],
};

export const YEARBOOK_MODULE_INSTANCE_PROFILE_PAGE: ModuleInstance = {
  __typename: 'ModuleInstanceNode',
  id: 'ymi_2',
  isActive: true,
  dueDate: '2030-09-30T15:02:55.095000+00:00',
  module: YEARBOOK_MODULE_PROFILE_PAGE,
};

export const YEARBOOK_PROFILE_PAGE_SETUP_CONFIG: ProfilePageSetupConfig = {
  __typename: 'ProfilePageSetupNode',
  id: 'ypps_1',
  photoFormat: PhotoFormat.PORTRAIT,
  maxChars: 250,
  anonymousComments: false,
  visibleComments: false,
};

export const YEARBOOK_MODULE_INSTANCE_PROFILE_PAGE_DETAIL: ModuleInstanceDetail =
  {
    ...YEARBOOK_MODULE_INSTANCE_PROFILE_PAGE,
    profilePageSetup: YEARBOOK_PROFILE_PAGE_SETUP_CONFIG,
    rankingsSetup: null,
    collagesSetup: null,
    reportsSetup: null,
    quotesSetup: null,
  };

export const YEARBOOK_MODULE_INSTANCE_PROFILE_PAGE_USER_SUMMARIES: ProfilePageUserSummary[] =
  [
    {
      __typename: 'ProfilePageUserSummaryNode',
      userGroup: USER_GROUP_0,
      imagesUploaded: 3,
      questionsAnswered: 3,
    },
    {
      __typename: 'ProfilePageUserSummaryNode',
      userGroup: USER_GROUP_2,
      imagesUploaded: 2,
      questionsAnswered: 1,
    },
    {
      __typename: 'ProfilePageUserSummaryNode',
      userGroup: USER_GROUP_3,
      imagesUploaded: 0,
      questionsAnswered: 0,
    },
  ];

export const YEARBOOK_MODULE_INSTANCE_PROFILE_PAGE_RESULT: ModuleInstanceResult =
  {
    ...YEARBOOK_MODULE_INSTANCE_PROFILE_PAGE,
    prewordInstance: null,
    profilePageSetup: {
      id: '1',
      __typename: 'ProfilePageSetupNode',
      usersDelivered: 1,
      usersSummary: YEARBOOK_MODULE_INSTANCE_PROFILE_PAGE_USER_SUMMARIES,
    },
    rankingsSetup: null,
    collagesSetup: null,
    reportsSetup: null,
    quotesSetup: null,
  };

//RANKINGS
export const YEARBOOK_MODULE_RANKINGS: Module = {
  __typename: 'ModuleNode',
  id: 'ym_3',
  name: 'Rankings',
  image: '',
  type: ModuleType.RANKINGS,
  description: 'Rankings description',
  features: ['Feature 1', 'Feature 2', 'Feature 3'],
};

export const YEARBOOK_MODULE_INSTANCE_RANKINGS: ModuleInstance = {
  __typename: 'ModuleInstanceNode',
  id: 'ymi_3',
  isActive: true,
  dueDate: '2030-09-30T15:02:55.095000+00:00',
  module: YEARBOOK_MODULE_RANKINGS,
};

export const YEARBOOK_RANKINGS_SETUP_CONFIG: RankingsSetupConfig = {
  __typename: 'RankingsSetupNode',
  id: 'ycs_1',
  resultsTeam: TEAM_1,
};

export const YEARBOOK_MODULE_INSTANCE_RANKINGS_DETAIL: ModuleInstanceDetail = {
  ...YEARBOOK_MODULE_INSTANCE_RANKINGS,
  profilePageSetup: null,
  rankingsSetup: YEARBOOK_RANKINGS_SETUP_CONFIG,
  collagesSetup: null,
  reportsSetup: null,
  quotesSetup: null,
};

export const YEARBOOK_MODULE_INSTANCE_RANKINGS_RESULT: ModuleInstanceResult = {
  ...YEARBOOK_MODULE_INSTANCE_RANKINGS,
  prewordInstance: null,
  profilePageSetup: null,
  rankingsSetup: {
    __typename: 'RankingsSetupNode',
    rankingsCount: 2,
  },
  collagesSetup: null,
  reportsSetup: null,
  quotesSetup: null,
};

//COLLAGES
export const YEARBOOK_MODULE_COLLAGES: Module = {
  __typename: 'ModuleNode',
  id: 'ym_4',
  name: 'Collages',
  image: '',
  type: ModuleType.COLLAGES,
  description: 'Collages description',
  features: ['Feature 1', 'Feature 2', 'Feature 3'],
};

export const YEARBOOK_MODULE_INSTANCE_COLLAGES: ModuleInstance = {
  __typename: 'ModuleInstanceNode',
  id: 'ymi_4',
  isActive: true,
  dueDate: '2030-09-30T15:02:55.095000+00:00',
  module: YEARBOOK_MODULE_COLLAGES,
};

export const YEARBOOK_COLLAGES_SETUP_CONFIG: CollagesSetupConfig = {
  __typename: 'CollagesSetupNode',
  id: 'ycs_1',
  createAlbumsTeam: TEAM_2,
  addPhotosTeam: TEAM_2,
  viewPhotosTeam: TEAM_2,
  photosCount: 0,
};

export const YEARBOOK_COLLAGES_SETUP_CONFIG_2: CollagesSetupConfig = {
  __typename: 'CollagesSetupNode',
  id: 'ycs_1',
  createAlbumsTeam: TEAM_1,
  addPhotosTeam: TEAM_1,
  viewPhotosTeam: TEAM_1,
  photosCount: 0,
};

export const YEARBOOK_MODULE_INSTANCE_COLLAGES_DETAIL: ModuleInstanceDetail = {
  ...YEARBOOK_MODULE_INSTANCE_COLLAGES,
  profilePageSetup: null,
  rankingsSetup: null,
  collagesSetup: YEARBOOK_COLLAGES_SETUP_CONFIG,
  reportsSetup: null,
  quotesSetup: null,
};

export const YEARBOOK_MODULE_INSTANCE_COLLAGES_DETAIL_2: ModuleInstanceDetail =
  {
    ...YEARBOOK_MODULE_INSTANCE_COLLAGES,
    profilePageSetup: null,
    rankingsSetup: null,
    collagesSetup: YEARBOOK_COLLAGES_SETUP_CONFIG_2,
    reportsSetup: null,
    quotesSetup: null,
  };

export const YEARBOOK_MODULE_INSTANCE_COLLAGES_RESULT: ModuleInstanceResult = {
  ...YEARBOOK_MODULE_INSTANCE_COLLAGES,
  prewordInstance: null,
  profilePageSetup: null,
  rankingsSetup: null,
  collagesSetup: {
    __typename: 'CollagesSetupNode',
    photosCount: 2,
  },
  reportsSetup: null,
  quotesSetup: null,
};

//REPORTS
export const YEARBOOK_MODULE_REPORTS: Module = {
  __typename: 'ModuleNode',
  id: 'ym_5',
  name: 'Reports',
  image: '',
  type: ModuleType.REPORTS,
  description: 'Reports description',
  features: ['Feature 1', 'Feature 2', 'Feature 3'],
};

export const YEARBOOK_MODULE_INSTANCE_REPORTS: ModuleInstance = {
  __typename: 'ModuleInstanceNode',
  id: 'ymi_5',
  isActive: true,
  dueDate: '2030-09-30T15:02:55.095000+00:00',
  module: YEARBOOK_MODULE_REPORTS,
};

export const YEARBOOK_REPORTS_SETUP_CONFIG: ReportsSetupConfig = {
  __typename: 'ReportsSetupNode',
  id: 'yrs_1',
  allowImages: false,
};

export const YEARBOOK_MODULE_INSTANCE_REPORTS_DETAIL: ModuleInstanceDetail = {
  ...YEARBOOK_MODULE_INSTANCE_REPORTS,
  profilePageSetup: null,
  rankingsSetup: null,
  collagesSetup: null,
  reportsSetup: YEARBOOK_REPORTS_SETUP_CONFIG,
  quotesSetup: null,
};

export const YEARBOOK_MODULE_INSTANCE_REPORTS_RESULT: ModuleInstanceResult = {
  ...YEARBOOK_MODULE_INSTANCE_REPORTS,
  prewordInstance: null,
  profilePageSetup: null,
  rankingsSetup: null,
  collagesSetup: null,
  reportsSetup: {
    __typename: 'ReportsSetupNode',
    reportsCount: 3,
  },
  quotesSetup: null,
};

//QUOTES
export const YEARBOOK_MODULE_QUOTES: Module = {
  __typename: 'ModuleNode',
  id: 'ym_8',
  name: 'Quotes',
  image: '',
  type: ModuleType.QUOTES,
  description: 'Quotes description',
  features: ['Feature 1', 'Feature 2', 'Feature 3'],
};

export const YEARBOOK_MODULE_INSTANCE_QUOTES: ModuleInstance = {
  __typename: 'ModuleInstanceNode',
  id: 'ymi_8',
  isActive: true,
  dueDate: '2030-09-30T15:02:55.095000+00:00',
  module: YEARBOOK_MODULE_QUOTES,
};

export const YEARBOOK_QUOTES_SETUP_CONFIG: QuotesSetupConfig = {
  __typename: 'QuotesSetupNode',
  id: 'yqs_1',
  anonymous: false,
  public: true,
  maxChars: 500,
};

export const YEARBOOK_MODULE_INSTANCE_QUOTES_DETAIL: ModuleInstanceDetail = {
  ...YEARBOOK_MODULE_INSTANCE_QUOTES,
  profilePageSetup: null,
  rankingsSetup: null,
  collagesSetup: null,
  reportsSetup: null,
  quotesSetup: YEARBOOK_QUOTES_SETUP_CONFIG,
};

export const YEARBOOK_MODULE_INSTANCE_QUOTES_RESULT: ModuleInstanceResult = {
  ...YEARBOOK_MODULE_INSTANCE_QUOTES,
  prewordInstance: null,
  profilePageSetup: null,
  rankingsSetup: null,
  collagesSetup: null,
  reportsSetup: null,
  quotesSetup: {
    __typename: 'QuotesSetupNode',
    quotesCount: 4,
  },
};

export const YEARBOOK_MODULE_INSTANCES = [
  YEARBOOK_MODULE_INSTANCE_PROFILE_PAGE,
  YEARBOOK_MODULE_INSTANCE_RANKINGS,
  YEARBOOK_MODULE_INSTANCE_COLLAGES,
  YEARBOOK_MODULE_INSTANCE_REPORTS,
  YEARBOOK_MODULE_INSTANCE_QUOTES,
];

export const YEARBOOK_MODULES = [
  YEARBOOK_MODULE_PROFILE_PAGE,
  YEARBOOK_MODULE_RANKINGS,
  YEARBOOK_MODULE_COLLAGES,
  YEARBOOK_MODULE_REPORTS,
  YEARBOOK_MODULE_QUOTES,
];

export const YEARBOOK_MODULE_INSTANCE_RESULTS = [
  YEARBOOK_MODULE_INSTANCE_PROFILE_PAGE_RESULT,
  YEARBOOK_MODULE_INSTANCE_RANKINGS_RESULT,
  YEARBOOK_MODULE_INSTANCE_COLLAGES_RESULT,
  YEARBOOK_MODULE_INSTANCE_REPORTS_RESULT,
  YEARBOOK_MODULE_INSTANCE_QUOTES_RESULT,
];

export const RANKING_QUESTION_RESULT_1_1: RankingQuestionResult = {
  __typename: 'RankingsQuestionResultNode',
  votee1UserGroup: USER_GROUP_1,
  votee2UserGroup: null,
  voteeListOption: null,
  percentage: 0.61111111,
};

export const RANKING_ANSWER_1_1: RankingsAnswer = {
  __typename: 'RankingsAnswerNode',
  id: 'ranking_answer_1',
  votee1UserGroup: USER_GROUP_1,
  votee2UserGroup: null,
  voteeListOption: null,
};

export const RANKING_QUESTION_1: RankingQuestion = {
  __typename: 'RankingsQuestionNode',
  id: 'ranking_question_1',
  question: 'Who will become the first millionaire?',
  answerType: AnswerType.PERSON,
  allowedVotes: AllowedVoteType.EVERYBODY,
  onlyOppositeGender: false,
  customList: null,
  results: {
    __typename: 'RankingsQuestionResultsNode',
    alreadyVoted: 10,
    possibleVoters: 10,
    options: [
      RANKING_QUESTION_RESULT_1_1,
      {
        __typename: 'RankingsQuestionResultNode',
        votee1UserGroup: USER_GROUP_2,
        votee2UserGroup: null,
        voteeListOption: null,
        percentage: 0.29,
      },
      {
        __typename: 'RankingsQuestionResultNode',
        votee1UserGroup: USER_GROUP_3,
        votee2UserGroup: null,
        voteeListOption: null,
        percentage: 0.1,
      },
    ],
  },
  rankingsAnswers: {
    __typename: 'RankingsAnswerNodeConnection',
    edges: [
      {
        __typename: 'RankingsAnswerNodeEdge',
        node: RANKING_ANSWER_1_1,
      },
    ],
  },
};

export const RANKING_QUESTION_RESULT_2_1: RankingQuestionResult = {
  __typename: 'RankingsQuestionResultNode',
  votee1UserGroup: USER_GROUP_1,
  votee2UserGroup: USER_GROUP_4,
  voteeListOption: null,
  percentage: 0.4,
};

export const RANKING_ANSWER_2_1: RankingsAnswer = {
  __typename: 'RankingsAnswerNode',
  id: 'ranking_answer_2',
  votee1UserGroup: USER_GROUP_2,
  votee2UserGroup: USER_GROUP_4,
  voteeListOption: null,
};

export const RANKING_QUESTION_2: RankingQuestion = {
  __typename: 'RankingsQuestionNode',
  id: 'ranking_question_2',
  question: 'Cuttest class couple',
  answerType: AnswerType.COUPLE,
  allowedVotes: AllowedVoteType.EVERYBODY,
  onlyOppositeGender: false,
  customList: null,
  results: {
    __typename: 'RankingsQuestionResultsNode',
    alreadyVoted: 10,
    possibleVoters: 10,
    options: [
      RANKING_QUESTION_RESULT_2_1,
      {
        __typename: 'RankingsQuestionResultNode',
        votee1UserGroup: USER_GROUP_2,
        votee2UserGroup: USER_GROUP_4,
        voteeListOption: null,
        percentage: 0.4,
      },
      {
        __typename: 'RankingsQuestionResultNode',
        votee1UserGroup: USER_GROUP_1,
        votee2UserGroup: USER_GROUP_2,
        voteeListOption: null,
        percentage: 0.18,
      },
      {
        __typename: 'RankingsQuestionResultNode',
        votee1UserGroup: USER_GROUP_3,
        votee2UserGroup: USER_GROUP_4,
        voteeListOption: null,
        percentage: 0.12,
      },
    ],
  },
  rankingsAnswers: {
    __typename: 'RankingsAnswerNodeConnection',
    edges: [
      {
        __typename: 'RankingsAnswerNodeEdge',
        node: RANKING_ANSWER_2_1,
      },
    ],
  },
};

export const CUSTOM_LIST_OPTION_1_1: CustomListOption = {
  __typename: 'CustomListOptionNode',
  id: 'custom_list_option_1_1',
  text: 'Custom List Option 1 1',
};

export const CUSTOM_LIST_OPTION_1_2: CustomListOption = {
  __typename: 'CustomListOptionNode',
  id: 'custom_list_option_1_2',
  text: 'Custom List Option 1 2',
};

export const CUSTOM_LIST_OPTION_1_3: CustomListOption = {
  __typename: 'CustomListOptionNode',
  id: 'custom_list_option_1_3',
  text: 'Custom List Option 1 3',
};

export const CUSTOM_LIST_1: CustomList = {
  __typename: 'CustomListNode',
  id: 'custom_list_1',
  title: 'Custom List 1',
};

export const CUSTOM_LIST_2: CustomList = {
  __typename: 'CustomListNode',
  id: 'custom_list_2',
  title: 'Custom List 2',
};

export const RANKING_QUESTION_3: RankingQuestion = {
  __typename: 'RankingsQuestionNode',
  id: 'ranking_question_3',
  question: 'Ranking question 3',
  answerType: AnswerType.PERSON,
  allowedVotes: AllowedVoteType.EVERYBODY,
  onlyOppositeGender: false,
  customList: null,
  results: {
    __typename: 'RankingsQuestionResultsNode',
    alreadyVoted: 10,
    possibleVoters: 10,
    options: [
      {
        __typename: 'RankingsQuestionResultNode',
        votee1UserGroup: USER_GROUP_0,
        votee2UserGroup: null,
        voteeListOption: null,
        percentage: 0.6,
      },
      {
        __typename: 'RankingsQuestionResultNode',
        votee1UserGroup: USER_GROUP_2,
        votee2UserGroup: null,
        voteeListOption: null,
        percentage: 0.3,
      },
      {
        __typename: 'RankingsQuestionResultNode',
        votee1UserGroup: USER_GROUP_3,
        votee2UserGroup: null,
        voteeListOption: null,
        percentage: 0.1,
      },
    ],
  },
  rankingsAnswers: {
    __typename: 'RankingsAnswerNodeConnection',
    edges: [],
  },
};

export const RANKING_ANSWER_4_1: RankingsAnswer = {
  __typename: 'RankingsAnswerNode',
  id: 'ranking_answer_4_1',
  votee1UserGroup: null,
  votee2UserGroup: null,
  voteeListOption: CUSTOM_LIST_OPTION_1_1,
};

export const RANKING_QUESTION_RESULT_4_1: RankingQuestionResult = {
  __typename: 'RankingsQuestionResultNode',
  votee1UserGroup: null,
  votee2UserGroup: null,
  voteeListOption: CUSTOM_LIST_OPTION_1_1,
  percentage: 0.6,
};

export const RANKING_QUESTION_RESULT_4_2: RankingQuestionResult = {
  __typename: 'RankingsQuestionResultNode',
  votee1UserGroup: null,
  votee2UserGroup: null,
  voteeListOption: CUSTOM_LIST_OPTION_1_2,
  percentage: 0.3,
};

export const RANKING_QUESTION_4: RankingQuestion = {
  __typename: 'RankingsQuestionNode',
  id: 'ranking_question_4',
  question: 'Ranking question 4',
  answerType: AnswerType.LIST,
  allowedVotes: AllowedVoteType.EVERYBODY,
  onlyOppositeGender: false,
  customList: {
    __typename: 'CustomListNode',
    id: CUSTOM_LIST_1.id,
  },
  results: {
    __typename: 'RankingsQuestionResultsNode',
    alreadyVoted: 10,
    possibleVoters: 10,
    options: [
      RANKING_QUESTION_RESULT_4_1,
      RANKING_QUESTION_RESULT_4_2,
      {
        __typename: 'RankingsQuestionResultNode',
        votee1UserGroup: null,
        votee2UserGroup: null,
        voteeListOption: CUSTOM_LIST_OPTION_1_3,
        percentage: 0.1,
      },
    ],
  },
  rankingsAnswers: {
    __typename: 'RankingsAnswerNodeConnection',
    edges: [
      {
        __typename: 'RankingsAnswerNodeEdge',
        node: RANKING_ANSWER_4_1,
      },
    ],
  },
};

export const COLLAGES_PHOTO_11: CollagesPhoto = {
  __typename: 'CollagesPhotoNode',
  id: 'collage_photo_11',
  photo: 'remote_photo_11',
  collagesPhotoLikesCount: 1,
  collagesPhotoLikes: {
    __typename: 'CollagesPhotoLikeNodeConnection',
    edges: [],
  },
  created: '2021-09-30T15:02:55.095000+00:00',
  userGroup: {
    __typename: 'UserGroupNode',
    id: USER_GROUP_0.id,
  },
};

export const COLLAGES_PHOTO_12: CollagesPhoto = {
  __typename: 'CollagesPhotoNode',
  id: 'collage_photo_12',
  photo: 'remote_photo_12',
  collagesPhotoLikesCount: 0,
  collagesPhotoLikes: {
    __typename: 'CollagesPhotoLikeNodeConnection',
    edges: [],
  },
  created: '2021-09-04T15:02:55.095000+00:00',
  userGroup: {
    __typename: 'UserGroupNode',
    id: USER_GROUP_0.id,
  },
};

export const COLLAGES_PHOTO_13: CollagesPhoto = {
  __typename: 'CollagesPhotoNode',
  id: 'collage_photo_13',
  photo: 'remote_photo_13',
  collagesPhotoLikesCount: 10,
  collagesPhotoLikes: {
    __typename: 'CollagesPhotoLikeNodeConnection',
    edges: [],
  },
  created: '2021-09-09T15:02:55.095000+00:00',
  userGroup: {
    __typename: 'UserGroupNode',
    id: USER_GROUP_0.id,
  },
};

export const COLLAGES_PHOTO_13_01: CollagesPhoto = {
  __typename: 'CollagesPhotoNode',
  id: 'collage_photo_13_01',
  photo: 'remote_photo_13_01',
  collagesPhotoLikesCount: 12,
  collagesPhotoLikes: {
    __typename: 'CollagesPhotoLikeNodeConnection',
    edges: [],
  },
  created: '2021-09-010T15:02:55.095000+00:00',
  userGroup: {
    __typename: 'UserGroupNode',
    id: USER_GROUP_1.id,
  },
};

export const COLLAGES_PHOTO_14: CollagesPhoto = {
  __typename: 'CollagesPhotoNode',
  id: 'collage_photo_14',
  photo: 'remote_photo_14',
  collagesPhotoLikesCount: 5,
  collagesPhotoLikes: {
    __typename: 'CollagesPhotoLikeNodeConnection',
    edges: [],
  },
  created: '2021-09-20T15:02:55.095000+00:00',
  userGroup: {
    __typename: 'UserGroupNode',
    id: USER_GROUP_0.id,
  },
};

export const COLLAGES_PHOTO_15: CollagesPhoto = {
  __typename: 'CollagesPhotoNode',
  id: 'collage_photo_15',
  photo: 'remote_photo_15',
  collagesPhotoLikesCount: 12,
  collagesPhotoLikes: {
    __typename: 'CollagesPhotoLikeNodeConnection',
    edges: [],
  },
  created: '2021-09-10T15:02:55.095000+00:00',
  userGroup: {
    __typename: 'UserGroupNode',
    id: USER_GROUP_0.id,
  },
};

export const COLLAGES_PHOTO_21: CollagesPhoto = {
  __typename: 'CollagesPhotoNode',
  id: 'collage_photo_21',
  photo: 'remote_photo_21',
  collagesPhotoLikesCount: 1,
  collagesPhotoLikes: {
    __typename: 'CollagesPhotoLikeNodeConnection',
    edges: [],
  },
  created: '2021-09-15T15:02:55.095000+00:00',
  userGroup: {
    __typename: 'UserGroupNode',
    id: USER_GROUP_0.id,
  },
};

export const COLLAGES_PHOTO_22: CollagesPhoto = {
  __typename: 'CollagesPhotoNode',
  id: 'collage_photo_22',
  photo: 'remote_photo_22',
  collagesPhotoLikesCount: 0,
  collagesPhotoLikes: {
    __typename: 'CollagesPhotoLikeNodeConnection',
    edges: [],
  },
  created: '2021-09-14T15:02:55.095000+00:00',
  userGroup: {
    __typename: 'UserGroupNode',
    id: USER_GROUP_0.id,
  },
};

export const COLLAGES_PHOTO_23: CollagesPhoto = {
  __typename: 'CollagesPhotoNode',
  id: 'collage_photo_23',
  photo: 'remote_photo_23',
  collagesPhotoLikesCount: 5,
  collagesPhotoLikes: {
    __typename: 'CollagesPhotoLikeNodeConnection',
    edges: [],
  },
  created: '2021-09-16T15:02:55.095000+00:00',
  userGroup: {
    __typename: 'UserGroupNode',
    id: USER_GROUP_0.id,
  },
};

export const COLLAGES_PHOTO_31: CollagesPhoto = {
  __typename: 'CollagesPhotoNode',
  id: 'collage_photo_31',
  photo: 'remote_photo_31',
  collagesPhotoLikesCount: 10,
  collagesPhotoLikes: {
    __typename: 'CollagesPhotoLikeNodeConnection',
    edges: [],
  },
  created: '2021-09-19T15:02:55.095000+00:00',
  userGroup: {
    __typename: 'UserGroupNode',
    id: USER_GROUP_0.id,
  },
};

export const COLLAGES_ALBUM_1: CollagesAlbum = {
  __typename: 'CollagesAlbumNode',
  id: 'collage_album_1',
  name: 'Collages Photo 1',
  photosCount: 6,
  collagesPhotos: {
    __typename: 'CollagesPhotoNodeConnection',
    edges: [
      {
        __typename: 'CollagesPhotoNodeEdge',
        node: COLLAGES_PHOTO_11,
      },
      {
        __typename: 'CollagesPhotoNodeEdge',
        node: COLLAGES_PHOTO_12,
      },
      {
        __typename: 'CollagesPhotoNodeEdge',
        node: COLLAGES_PHOTO_13,
      },
      {
        __typename: 'CollagesPhotoNodeEdge',
        node: COLLAGES_PHOTO_13_01,
      },
      {
        __typename: 'CollagesPhotoNodeEdge',
        node: COLLAGES_PHOTO_14,
      },
      {
        __typename: 'CollagesPhotoNodeEdge',
        node: COLLAGES_PHOTO_15,
      },
    ],
  },
};

export const COLLAGES_ALBUM_0: CollagesBaseAlbumFields = {
  __typename: 'CollagesAlbumNode',
  id: 'collage_album_0',
  name: 'Collages Photo 0',
  photosCount: 0,
};

export const COLLAGES_ALBUM_2: CollagesAlbum = {
  __typename: 'CollagesAlbumNode',
  id: 'collage_album_2',
  name: 'Collages Photo 2',
  photosCount: 3,
  collagesPhotos: {
    __typename: 'CollagesPhotoNodeConnection',
    edges: [
      {
        __typename: 'CollagesPhotoNodeEdge',
        node: COLLAGES_PHOTO_21,
      },
      {
        __typename: 'CollagesPhotoNodeEdge',
        node: COLLAGES_PHOTO_22,
      },
      {
        __typename: 'CollagesPhotoNodeEdge',
        node: COLLAGES_PHOTO_23,
      },
    ],
  },
};

export const COLLAGES_ALBUM_3: CollagesAlbum = {
  __typename: 'CollagesAlbumNode',
  id: 'collage_album_3',
  name: 'Collages Photo 3',
  photosCount: 1,
  collagesPhotos: {
    __typename: 'CollagesPhotoNodeConnection',
    edges: [
      {
        __typename: 'CollagesPhotoNodeEdge',
        node: COLLAGES_PHOTO_31,
      },
    ],
  },
};

export const BASE_COLLAGES_ALBUM_4: BaseCollagesAlbum = {
  __typename: 'CollagesAlbumNode',
  id: 'collage_album_4',
  name: 'Collages Photo 4',
};

export const REPORTS_INSTANCE_EMPTY_TEXT: ReportInstance = {
  __typename: 'ReportsInstanceNode',
  id: 'ri_1',
  title: 'Report Title',
  text: 'Report Title',
  maxChars: 500,
  editTeam: TEAM_2,
  viewTeam: TEAM_2,
};

export const REPORTS_INSTANCE_1: ReportInstance = {
  __typename: 'ReportsInstanceNode',
  id: 'ri_1',
  title: 'Hey title',
  text: '#Hey body',
  maxChars: 500,
  editTeam: TEAM_2,
  viewTeam: TEAM_2,
};

export const REPORTS_INSTANCE_2: ReportInstance = {
  __typename: 'ReportsInstanceNode',
  id: 'ri_2',
  title: 'Reports Instance 2',
  text: 'Lore ipsum text',
  maxChars: 500,
  editTeam: TEAM_2,
  viewTeam: TEAM_2,
};

export const REPORTS_INSTANCE_3: ReportInstance = {
  __typename: 'ReportsInstanceNode',
  id: 'ri_3',
  title: 'Reports Instance 3',
  text: 'Lore ipsum text',
  maxChars: 500,
  editTeam: TEAM_2,
  viewTeam: TEAM_2,
};

export const EXTERNAL_EVENTS_CMS_TURKEY: AuthExternalCMSEventTurkey = {
  __typename: 'ExternalEventAttendeeNodeConnection',
  edges: [
    {
      __typename: 'ExternalEventAttendeeNodeEdge',
      node: {
        __typename: 'ExternalEventAttendeeNode',
        platform: Platform.CONTENTFUL,
        eventId: 'eventID',
      },
    },
  ],
};

export const AUTH_USER_0: AuthUser = {
  ...USER_0,
  email: 'max@email.com',
  phoneCountryCode: '49',
  phone: '123123123',
  emailValidated: true,
  communicationLanguage: Language.ENGLISH,
  isSuperuser: false,
  inviteCode: '1234',
  countryOfResidence: 'DE',
  externalEvents: EXTERNAL_EVENTS_CMS_TURKEY,
  externalTokens: {
    __typename: 'ExternalTokensNode',
    intercom: 'intercom-token',
  },
};

export const AUTH_USER_0_TR: AuthUser = {
  ...USER_0,
  email: 'max@email.com',
  phoneCountryCode: '49',
  phone: '123123123',
  emailValidated: true,
  communicationLanguage: Language.ENGLISH,
  isSuperuser: false,
  inviteCode: '1234',
  countryOfResidence: 'TR',
  externalEvents: EXTERNAL_EVENTS_CMS_TURKEY,
  externalTokens: {
    __typename: 'ExternalTokensNode',
    intercom: 'intercom-token',
  },
};

export const AUTH_USER_0_PHONE_TR: AuthUser = {
  ...USER_0,
  email: 'max@email.com',
  phoneCountryCode: '90',
  phone: '123123123',
  emailValidated: true,
  communicationLanguage: Language.ENGLISH,
  isSuperuser: false,
  inviteCode: '1234',
  countryOfResidence: null,
  externalEvents: EXTERNAL_EVENTS_CMS_TURKEY,
  externalTokens: {
    __typename: 'ExternalTokensNode',
    intercom: 'intercom-token',
  },
};

export const AUTH_USER_1: AuthUser = {
  ...USER_1,
  email: 'max@email.com',
  phoneCountryCode: '49',
  phone: '123123123',
  emailValidated: true,
  communicationLanguage: Language.ENGLISH,
  isSuperuser: true,
  inviteCode: '1234',
  countryOfResidence: 'DE',
  externalEvents: EXTERNAL_EVENTS_CMS_TURKEY,
  externalTokens: {
    __typename: 'ExternalTokensNode',
    intercom: 'intercom-token',
  },
};

export const AUTH_USER_GROUP_0: AuthUserGroup = {
  ...USER_GROUP_0,
  user: AUTH_USER_0,
  isCoreTeamMember: true,
  isYearbookTeamMember: true,
};

export const AUTH_USER_GROUP_0_PROFILE_PENDING: AuthUserGroup = {
  ...USER_GROUP_0,
  avatar: null,
  defaultAvatar: null,
  role: null,
  user: {
    ...AUTH_USER_0,
    gender: null,
  },
  isCoreTeamMember: false,
  isYearbookTeamMember: false,
};

export const AUTH_USER_GROUP_1: AuthUserGroup = {
  ...USER_GROUP_0,
  user: AUTH_USER_0,
  isCoreTeamMember: true,
  isYearbookTeamMember: false,
};

export const USER_GROUP_INFO_0: UserGroupInfo = {
  authGroupId: GROUP_1.id,
  authUserGroupId: USER_GROUP_0.id,
};

export const GROUP_STATISTICS: GroupStatistics = {
  __typename: 'GroupNode',
  id: GROUP_1.id,
  yearbookStatistics: {
    __typename: 'YearbookStatisticsNode',
    profilePagesFilledIn: 0.2,
    usersWithQuote: 0.7544444444444,
    lastDeadline: '2022-09-19T15:02:55.095000+00:00',
    contentModules: 8,
    estimatedPages: 136,
    imagesUploaded: 312,
    wordCount: 12312,
  },
};

export const NOTIFICATION_SETTING: NotificationSetting = {
  groupUpdatesPush: false,
  chatPush: false,
  sponsoringsPush: true,
  orderUpdatesPush: false,
  groupUpdatesEmail: false,
  chatEmail: false,
  sponsoringsEmail: true,
  orderUpdatesEmail: false,
};

export const DATA_EXPORT_1: DataExport = {
  __typename: 'DataExportNode',
  id: 'data_export_1',
  userGroup: USER_GROUP_0,
  exportCollages: true,
  exportProfilePages: false,
  exportQuotes: false,
  exportRankings: false,
  exportFile: 'export_file',
  isodatetime: '2023-03-30T15:02:55.095000+00:00',
};

export const DATA_EXPORT_2: DataExport = {
  __typename: 'DataExportNode',
  id: 'data_export_2',
  userGroup: USER_GROUP_1,
  exportCollages: true,
  exportProfilePages: true,
  exportQuotes: false,
  exportRankings: false,
  exportFile: null,
  isodatetime: '2023-03-31T15:02:55.095000+00:00',
};

export const DATA_EXPORT_3: DataExport = {
  __typename: 'DataExportNode',
  id: 'data_export_3',
  userGroup: USER_GROUP_2,
  exportCollages: true,
  exportProfilePages: true,
  exportQuotes: false,
  exportRankings: false,
  exportFile: null,
  isodatetime: '2023-03-31T15:02:55.095000+00:00',
};

export const ADMIN_SEARCH_INSTITUTE_1: AdminSearchInstitute = {
  __typename: 'SearchInstituteType',
  id: INSTITUTE_1.id,
  name: INSTITUTE_1.name,
  internalId: INSTITUTE_1.internalId,
  city: INSTITUTE_1.city,
  country: INSTITUTE_1.country,
  zip: INSTITUTE_1.zip,
  type: INSTITUTE_1.type.name,
};

export const ADMIN_SEARCH_INSTITUTE_2: AdminSearchInstitute = {
  __typename: 'SearchInstituteType',
  id: INSTITUTE_2.id,
  name: INSTITUTE_2.name,
  internalId: INSTITUTE_2.internalId,
  city: INSTITUTE_2.city,
  country: INSTITUTE_2.country,
  zip: INSTITUTE_2.zip,
  type: INSTITUTE_2.type.name,
};

export const ADMIN_SEARCH_INSTITUTE_3: AdminSearchInstitute = {
  __typename: 'SearchInstituteType',
  id: INSTITUTE_3.id,
  name: INSTITUTE_3.name,
  internalId: INSTITUTE_3.internalId,
  city: INSTITUTE_3.city,
  country: INSTITUTE_3.country,
  zip: INSTITUTE_3.zip,
  type: INSTITUTE_3.type.name,
};

export const ADMIN_SEARCH_GROUP_1: AdminSearchGroup = {
  __typename: 'SearchGroupType',
  id: GROUP_1.id,
  name: GROUP_1.name,
  graduationYear: GROUP_1.year.toString(),
  institute: ADMIN_SEARCH_INSTITUTE_1,
  type: GROUP_1.type.name,
  avatar: null,
};

export const ADMIN_SEARCH_GROUP_2: AdminSearchGroup = {
  __typename: 'SearchGroupType',
  id: GROUP_2.id,
  name: GROUP_2.name,
  graduationYear: GROUP_2.year.toString(),
  institute: ADMIN_SEARCH_INSTITUTE_1,
  type: GROUP_2.type.name,
  avatar: null,
};

export const ADMIN_SEARCH_GROUP_3: AdminSearchGroup = {
  __typename: 'SearchGroupType',
  id: GROUP_3.id,
  name: GROUP_3.name,
  graduationYear: GROUP_3.year.toString(),
  institute: ADMIN_SEARCH_INSTITUTE_3,
  type: GROUP_3.type.name,
  avatar: null,
};

export const ADMIN_SEARCH_USER_1: AdminSearchUser = {
  __typename: 'SearchUserType',
  email: 'foo@bar.com',
  firstName: USER_1.firstName,
  lastName: USER_1.lastName,
  phone: '123123123',
  id: USER_1.id,
  avatar: null,
  phoneCountryCode: '+43',
  groups: [ADMIN_SEARCH_GROUP_1, ADMIN_SEARCH_GROUP_2, ADMIN_SEARCH_GROUP_3],
};

export const ADMIN_SEARCH_USER_2: AdminSearchUser = {
  __typename: 'SearchUserType',
  email: 'foo@bar.com',
  firstName: USER_2.firstName,
  lastName: USER_2.lastName,
  phone: '123123123',
  id: USER_2.id,
  avatar: null,
  phoneCountryCode: '+43',
  groups: [ADMIN_SEARCH_GROUP_1, ADMIN_SEARCH_GROUP_2],
};

export const ADMIN_SEARCH_USER_3: AdminSearchUser = {
  __typename: 'SearchUserType',
  email: 'foo@bar.com',
  firstName: USER_3.firstName,
  lastName: USER_3.lastName,
  phone: '123123',
  id: USER_3.id,
  avatar: null,
  phoneCountryCode: '+43',
  groups: [],
};

export const ADMIN_SEARCH_RESULTS: AdminSearchResults = {
  __typename: 'SearchStructure3',
  groups: [ADMIN_SEARCH_GROUP_1, ADMIN_SEARCH_GROUP_2, ADMIN_SEARCH_GROUP_3],
  institutes: [
    ADMIN_SEARCH_INSTITUTE_1,
    ADMIN_SEARCH_INSTITUTE_2,
    ADMIN_SEARCH_INSTITUTE_3,
  ],
  users: [ADMIN_SEARCH_USER_1, ADMIN_SEARCH_USER_2, ADMIN_SEARCH_USER_3],
};

export const ADMIN_EDIT_GROUP: AdminGetGroup = {
  group: {
    ...GROUP_1,
    isActive: true,
    isImported: true,
    allMembers: [
      {
        ...ADMIN_SEARCH_USER_1,
        __typename: 'GroupMemberNode',
        user: USER_1,
        role: GroupsUserGroupRoleChoices.STUDENT,
        status: GroupsUserGroupStatusChoices.ACCEPTED,
        teams: [],
      },
    ],
    institute: {
      ...INSTITUTE_1,
      type: { ...INSTITUTE_1.type, graduationTypes: GRADUATION_TYPES },
    },
  },
};

export const ADMIN_EDIT_USER: AdminGetUser = {
  user: {
    ...AUTH_USER_0,
    __typename: 'UserNode',
    created: '2021-07-01T21:12:55.095000+00:00',
    isActive: true,
    avatar: '',
    optIn: true,
    isImported: true,
    isAgent: false,
    isSuperuser: false,
    groups: [
      {
        ...GROUP_DETAIL_1,
        __typename: 'GroupNode',
        users: [],
        teams: [],
      },
    ],
    teams: [],
  },
};

export const ADMIN_EDIT_USER_1: AdminGetUser = {
  user: {
    ...AUTH_USER_0,
    __typename: 'UserNode',
    created: '2021-07-01T21:12:55.095000+00:00',
    isActive: true,
    avatar: '',
    optIn: true,
    isImported: true,
    isAgent: false,
    isSuperuser: false,
    groups: [
      {
        ...GROUP_DETAIL_1,
        __typename: 'GroupNode',
        users: [],
        teams: [],
      },
    ],
    teams: [USER_TEAM],
  },
};

export const MOCK_INTITUTE_ACTIVITY: EditInstituteActivityFields[] = [
  {
    year: 2022,
    status: null,
    __typename: 'InstituteActivityType',
  },
  {
    year: 2023,
    status: InstituteActivityTypeEnum.ACTIVE,
    __typename: 'InstituteActivityType',
  },
  {
    year: 2024,
    status: InstituteActivityTypeEnum.INACTIVE,
    __typename: 'InstituteActivityType',
  },
  {
    year: 2025,
    status: null,
    __typename: 'InstituteActivityType',
  },
];

export const MOCK_INSTITUTE_GROUPS: EditInstituteGroupFields[] = [
  {
    id: GROUP_1.id,
    name: GROUP_1.name,
    type: {
      name: 'Abitur',
      __typename: 'GraduationTypeNode',
    },
    year: GROUP_1.year,
    avatar: null,
    icon: null,
    users: [
      {
        id: USER_1.id,
        __typename: 'UserGroupNode',
      },
      {
        id: USER_2.id,
        __typename: 'UserGroupNode',
      },
    ],
    institute: {
      country: INSTITUTE_1.country,
      internalId: INSTITUTE_1.internalId,
      __typename: 'InstituteNode',
    },
    __typename: 'GroupNode',
  },
];

export const INSTITUTE_REQUEST_1: InstituteRequest = {
  ...INSTITUTE_1,
  user: {
    ...USER_1,
    email: 'foo@bar.com',
    avatar: null,
  },
  status: Status.PENDING,
  website: 'foo@bar.com',
  __typename: 'InstituteRequestNode',
  country: InstitutesInstituteRequestCountryChoices.DE,
  created: '2019-02-26T21:12:55.095000+00:00',
};

export const INSTITUTE_REQUEST_2: InstituteRequest = {
  ...INSTITUTE_2,
  user: {
    ...USER_2,
    email: 'foo@bar.com',
    avatar: null,
  },
  status: Status.PENDING,
  website: 'foo@bar.com',
  __typename: 'InstituteRequestNode',
  country: InstitutesInstituteRequestCountryChoices.DE,
  created: '2019-02-26T21:12:55.095000+00:00',
};

export const INSTITUTE_REQUEST_3: InstituteRequest = {
  ...INSTITUTE_3,
  user: {
    ...USER_3,
    email: 'foo@bar.com',
    avatar: null,
  },
  status: Status.PENDING,
  website: 'foo@bar.com',
  __typename: 'InstituteRequestNode',
  country: InstitutesInstituteRequestCountryChoices.DE,
  created: '2019-02-26T21:12:55.095000+00:00',
};

export const INSTITUTE_REQUESTS: InstituteRequest[] = [
  INSTITUTE_REQUEST_1,
  INSTITUTE_REQUEST_2,
  INSTITUTE_REQUEST_3,
];

export const USER_ATTENDEE_EVENT_0: EventAttendees = {
  __typename: 'EventAttendeeNode',
  /**
   * The ID of the object
   */
  id: 'attendee_1',
  user: {
    __typename: 'UserNode',
    id: USER_0.id,
    firstName: USER_0.firstName,
    lastName: USER_0.lastName,
    avatar: '',
    email: 'max@mgmail.com',
  },
};

export const EVENT_1: Event = {
  __typename: 'EventNode',
  id: 'event_1',
  title: 'Event of the month',
  location: 'Event hall 204',
  startDateTime: '2021-08-02T15:00:00',
  endDateTime: '2021-08-02T17:00:00',
  authorType: EventsEventAuthorTypeChoices.USER,
  eventAuthor: AuthorType.USER,
  eventType: EventType.EVENT,
  authorGroup: null,
  authorUser: {
    ...AUTH_USER_0,
    avatar: '',
  },
  authorTeam: null,
  description:
    'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry',
  companyName: 'Nike',
  bannerImage: 'photo_1',
  companyLogo:
    'https://gradoo-staging.eu-central-1.linodeobjects.com/gradoo-staging/media/group_icons/group_icon_9.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=X38UDV7XC6SS648X2H2H%2F20220930%2Feu-central-1%2Fs3%2Faws4_request&X-Amz-Date=20220930T151153Z&X-Amz-Expires=3600&X-Amz-SignedHeaders=host&X-Amz-Signature=58d9ce060efacb251858a09dfe2017947cf2fd3d7a1b614eb705390188a8edbd',
  isSponsored: false,
  isAllDay: false,
  isJoinable: false,
  isPublic: false,
  attendees: [USER_ATTENDEE_EVENT_0],
};

export const EVENT_1_TEAM: Event = {
  __typename: 'EventNode',
  id: 'event_1',
  title: 'Event of the month',
  location: 'Event hall 204',
  startDateTime: '2021-08-02T15:00:00',
  endDateTime: '2021-08-02T17:00:00',
  authorType: EventsEventAuthorTypeChoices.TEAM,
  eventAuthor: AuthorType.TEAM,
  eventType: EventType.EVENT,
  authorGroup: null,
  authorUser: {
    ...AUTH_USER_0,
    avatar: '',
  },
  authorTeam: {
    ...TEAM_1,
    avatar: '',
  },
  description:
    'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry',
  companyName: 'Nike',
  bannerImage: 'photo_1',
  companyLogo:
    'https://gradoo-staging.eu-central-1.linodeobjects.com/gradoo-staging/media/group_icons/group_icon_9.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=X38UDV7XC6SS648X2H2H%2F20220930%2Feu-central-1%2Fs3%2Faws4_request&X-Amz-Date=20220930T151153Z&X-Amz-Expires=3600&X-Amz-SignedHeaders=host&X-Amz-Signature=58d9ce060efacb251858a09dfe2017947cf2fd3d7a1b614eb705390188a8edbd',
  isSponsored: false,
  isAllDay: false,
  isJoinable: false,
  isPublic: false,
  attendees: [USER_ATTENDEE_EVENT_0],
};
export const EVENT_1_GROUP: Event = {
  __typename: 'EventNode',
  id: 'event_1',
  title: 'Event of the month',
  location: 'Event hall 204',
  startDateTime: '2021-08-02T15:00:00',
  endDateTime: '2021-08-02T17:00:00',
  authorType: EventsEventAuthorTypeChoices.GROUP,
  eventAuthor: AuthorType.GROUP,
  eventType: EventType.EVENT,
  authorGroup: GROUP_1,
  authorUser: null,
  authorTeam: null,
  description:
    'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry',
  companyName: 'Nike',
  bannerImage: 'photo_1',
  companyLogo:
    'https://gradoo-staging.eu-central-1.linodeobjects.com/gradoo-staging/media/group_icons/group_icon_9.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=X38UDV7XC6SS648X2H2H%2F20220930%2Feu-central-1%2Fs3%2Faws4_request&X-Amz-Date=20220930T151153Z&X-Amz-Expires=3600&X-Amz-SignedHeaders=host&X-Amz-Signature=58d9ce060efacb251858a09dfe2017947cf2fd3d7a1b614eb705390188a8edbd',
  isSponsored: false,
  isAllDay: false,
  isJoinable: false,
  isPublic: false,
  attendees: [USER_ATTENDEE_EVENT_0],
};

export const EVENT_2: Event = {
  __typename: 'EventNode',
  id: 'event_2',
  title: 'Event B',
  location: 'Millenium Park Ansgarstr. 4, Wallenhorst, 49134',
  startDateTime: '2021-08-02T17:00:00',
  endDateTime: '2021-08-02T19:00:00',
  authorType: EventsEventAuthorTypeChoices.GROUP,
  eventAuthor: AuthorType.GROUP,
  eventType: EventType.EVENT,
  authorGroup: GROUP_1,
  authorUser: null,
  authorTeam: null,
  description:
    'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry',
  companyName: 'Nike',
  bannerImage: 'photo_1',
  companyLogo:
    'https://gradoo-staging.eu-central-1.linodeobjects.com/gradoo-staging/media/group_icons/group_icon_9.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=X38UDV7XC6SS648X2H2H%2F20220930%2Feu-central-1%2Fs3%2Faws4_request&X-Amz-Date=20220930T151153Z&X-Amz-Expires=3600&X-Amz-SignedHeaders=host&X-Amz-Signature=58d9ce060efacb251858a09dfe2017947cf2fd3d7a1b614eb705390188a8edbd',
  isSponsored: false,
  isAllDay: false,
  isJoinable: false,
  isPublic: false,
  attendees: [],
};

export const EVENT_3: Event = {
  __typename: 'EventNode',
  id: 'event_3',
  title: 'Event C',
  location: 'Millenium Park Ansgarstr. 4, Wallenhorst, 49134',
  startDateTime: '2021-08-21T10:00:00',
  endDateTime: '2021-08-21T12:00:00',
  authorType: EventsEventAuthorTypeChoices.GROUP,
  eventAuthor: AuthorType.GROUP,
  eventType: EventType.EVENT,
  authorGroup: GROUP_1,
  authorUser: null,
  authorTeam: null,
  description:
    'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry',
  companyName: 'Nike',
  bannerImage: 'photo_1',
  companyLogo:
    'https://gradoo-staging.eu-central-1.linodeobjects.com/gradoo-staging/media/group_icons/group_icon_9.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=X38UDV7XC6SS648X2H2H%2F20220930%2Feu-central-1%2Fs3%2Faws4_request&X-Amz-Date=20220930T151153Z&X-Amz-Expires=3600&X-Amz-SignedHeaders=host&X-Amz-Signature=58d9ce060efacb251858a09dfe2017947cf2fd3d7a1b614eb705390188a8edbd',
  isSponsored: false,
  isAllDay: false,
  isJoinable: false,
  isPublic: false,
  attendees: [],
};

export const CALENDAR_EVENT_1: CalendarEvent = {
  ...EVENT_1,
  index: 0,
  color: '#a5b4fc',
  name: EVENT_1.title,
  fromDate: '2021-08-02',
};

export const CALENDAR_EVENT_2: CalendarEvent = {
  ...EVENT_2,
  index: 1,
  color: '#FDA4AF',
  name: EVENT_2.title,
  fromDate: '2021-08-02',
};

export const CALENDAR_EVENT_3: CalendarEvent = {
  ...EVENT_3,
  index: 2,
  color: '#C4B5FD',
  name: EVENT_3.title,
  fromDate: '2021-08-21',
};

export const AD_CAMPAIGN_TYPES = [
  {
    id: '0',
    name: 'External',
  },
  {
    id: '1',
    name: 'Internal',
  },
];

export const AD_CAMPAIGN_STATISTICS: Statistic[] = [
  {
    statistic: '120.312',
    description: 'Total tracked views',
  },
  {
    statistic: '1.498',
    description: 'Total tracked clicks',
  },
  {
    statistic: '3%',
    description: 'Conversion rate feed banner',
  },
  {
    statistic: '13',
    description: 'Total number of assets',
  },
];

export const AD_TARGETING_STATISTICS: Statistic[] = [
  {
    statistic: '100.000',
    description: 'Users',
  },
  {
    statistic: '4.373',
    description: 'Institutes',
  },
  {
    statistic: '564',
    description: 'Active Groups',
  },
  {
    statistic: '234',
    description: 'Inactive Groups',
  },
];

export const AD_CAMPAIGN_STATISTICS_2: Statistic[] = [
  {
    statistic: '120.312',
    description: 'Total tracked views',
  },
  {
    statistic: '1.498',
    description: 'Total tracked clicks',
  },
  {
    statistic: '3%',
    description: 'Conversion rate feed banner',
  },
];

export const BANNER_STATISTICS_1: Statistic[] = [
  {
    statistic: '320x180px',
    description: 'Format',
  },
  {
    statistic: '14.575',
    description: 'Views',
  },
  {
    statistic: '1.575',
    description: 'Clicks',
  },
  {
    statistic: '0.4%',
    description: 'Con.',
  },
];

export const BANNER_STATISTICS_2: Statistic[] = [
  {
    statistic: '320x90px',
    description: 'Format',
  },
  {
    statistic: '14.575',
    description: 'Views',
  },
  {
    statistic: '1.575',
    description: 'Clicks',
  },
  {
    statistic: '0.4%',
    description: 'Con.',
  },
];

export const AD_CAMPAIGN_ASSETS: DeprecatedAsset[] = [
  {
    name: 'BannerAd',
    id: 'BA-326482',
    views: '14.575',
    clicks: '1.575',
    conversion: '0.4%',
    active: true,
  },
  {
    name: 'Sponsored Event',
    id: 'SE-326482',
    views: '14.575',
    clicks: '1.575',
    conversion: '0.4%',
    active: false,
  },
];

export const LEADERBOARD_USER_1: LeaderboardUser = {
  __typename: 'LeaderboardUserNode',
  user: USER_0,
  points: 30,
  position: 1,
};

export const LEADERBOARD_USER_2: LeaderboardUser = {
  __typename: 'LeaderboardUserNode',
  user: USER_1,
  points: 20,
  position: 2,
};

export const LEADERBOARD_USER_3: LeaderboardUser = {
  __typename: 'LeaderboardUserNode',
  user: USER_2,
  points: 10,
  position: 3,
};

export const COUNTRY_TYPE_1: CountryType = {
  name: 'Germany',
  country: 'DE',
};

export const ADMIN_GET_USER_0: AdminUser = {
  __typename: 'UserNode',
  id: 'getusers0',
  isAgent: true,
  isSuperuser: false,
  firstName: 'Keyser',
  lastName: 'Söze',
  email: 'ks@mail.com',
  phoneCountryCode: 'DE',
  gender: UsersUserGenderChoices.MALE,
  phone: '12345678',
  isActive: true,
  optIn: false,
  created: '2021-07-01T21:12:55.095000+00:00',
  isImported: false,
  avatar: null,
  groups: [],
  teams: [],
};

export const ADMIN_GET_USER_1: AdminUser = {
  __typename: 'UserNode',
  id: 'getusers1',
  isAgent: false,
  isSuperuser: true,
  firstName: 'Samantha',
  lastName: 'Carter',
  email: 'sc@mail.com',
  phoneCountryCode: 'DE',
  gender: UsersUserGenderChoices.FEMALE,
  phone: '12345678',
  isActive: true,
  optIn: false,
  created: '2021-07-01T21:12:55.095000+00:00',
  isImported: false,
  avatar: null,
  groups: [],
  teams: [],
};

export const ADMIN_GET_USER_2: AdminUser = {
  __typename: 'UserNode',
  id: 'getuser2',
  isAgent: true,
  isSuperuser: false,
  firstName: 'Willy',
  lastName: 'Smith',
  email: 'ws@mail.com',
  phoneCountryCode: 'DE',
  gender: UsersUserGenderChoices.MALE,
  phone: '12345678',
  isActive: true,
  optIn: false,
  created: '2021-07-01T21:12:55.095000+00:00',
  isImported: false,
  avatar: null,
  groups: [],
  teams: [],
};

export const EVENT_TURKEY_CMS_1: EventTurkeyCMS = {
  eventDate: '2021-07-01T21:12:55.095000+00:00',
  locationTitle: 'Istanbul',
  title: 'AI Istanbul',
  isOnline: true,
  participants: ['anilakgunes@gmail.com'],
  slug: 'slug',
  joinLink: 'https://tr.gradoo.com/events/',
  host: {
    sys: {
      id: 'host_1',
    },
  },
  sys: {
    id: 'EVENT_TURKEY_CMS_1',
  },
};

export const EVENT_TURKEY_CMS_AUTHOR_1: AuthorTurkeyCMS = {
  fullName: 'Anıl Akgunes',
  avatar: {
    url: 'https://gradoo-staging.eu-central-1.linodeobjects.com/gradoo-staging/media/group_icons/group_icon_9.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=X38UDV7XC6SS648X2H2H%2F20220930%2Feu-central-1%2Fs3%2Faws4_request&X-Amz-Date=20220930T151153Z&X-Amz-Expires=3600&X-Amz-SignedHeaders=host&X-Amz-Signature=58d9ce060efacb251858a09dfe2017947cf2fd3d7a1b614eb705390188a8edbd',
  },
  sys: {
    id: 'host_1',
  },
};

export const EVENT_TURKEY_CMS_2: EventTurkeyCMS = {
  eventDate: '2023-07-01T21:12:55.095000+00:00',
  locationTitle: 'Berlin',
  title: 'AI Berlin',
  isOnline: true,
  participants: ['anilakgunes@gmail.com'],
  slug: 'slug',
  joinLink: 'https://tr.gradoo.com/events/',
  host: {
    sys: {
      id: 'host_2',
    },
  },
  sys: {
    id: 'EVENT_TURKEY_CMS_2',
  },
};

export const EVENT_TURKEY_CMS_AUTHOR_2: AuthorTurkeyCMS = {
  fullName: 'Omer Ilhan',
  avatar: {
    url: 'https://gradoo-staging.eu-central-1.linodeobjects.com/gradoo-staging/media/group_icons/group_icon_9.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=X38UDV7XC6SS648X2H2H%2F20220930%2Feu-central-1%2Fs3%2Faws4_request&X-Amz-Date=20220930T151153Z&X-Amz-Expires=3600&X-Amz-SignedHeaders=host&X-Amz-Signature=58d9ce060efacb251858a09dfe2017947cf2fd3d7a1b614eb705390188a8edbd',
  },
  sys: {
    id: 'host_2',
  },
};

export const EVENT_TURKEY_CMS_3: EventTurkeyCMS = {
  eventDate: '2023-07-01T21:12:55.095000+00:00',
  locationTitle: 'Amsterdam',
  title: 'AI Amsterdam',
  isOnline: true,
  participants: ['anilakgunes@gmail.com'],
  slug: 'slug',
  joinLink: 'https://tr.gradoo.com/events/',
  host: {
    sys: {
      id: 'host_3',
    },
  },
  sys: {
    id: 'EVENT_TURKEY_CMS_3',
  },
};

export const EVENT_TURKEY_CMS_AUTHOR_3: AuthorTurkeyCMS = {
  fullName: 'Halil Oklu',
  avatar: {
    url: 'https://gradoo-staging.eu-central-1.linodeobjects.com/gradoo-staging/media/group_icons/group_icon_9.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=X38UDV7XC6SS648X2H2H%2F20220930%2Feu-central-1%2Fs3%2Faws4_request&X-Amz-Date=20220930T151153Z&X-Amz-Expires=3600&X-Amz-SignedHeaders=host&X-Amz-Signature=58d9ce060efacb251858a09dfe2017947cf2fd3d7a1b614eb705390188a8edbd',
  },
  sys: {
    id: 'host_3',
  },
};

export const EVENT_TURKEY_CMS_4: EventTurkeyCMS = {
  eventDate: '2024-07-01T21:12:55.095000+00:00',
  locationTitle: 'Milan',
  title: 'AI Milan',
  isOnline: true,
  slug: 'slug',
  joinLink: 'https://tr.gradoo.com/events/',
  participants: ['anilakgunes@gmail.com'],
  host: {
    sys: {
      id: 'host_4',
    },
  },
  sys: {
    id: 'EVENT_TURKEY_CMS_4',
  },
};

export const EVENT_TURKEY_CMS_AUTHOR_4: AuthorTurkeyCMS = {
  fullName: 'Mira Tarhük',
  avatar: {
    url: 'https://gradoo-staging.eu-central-1.linodeobjects.com/gradoo-staging/media/group_icons/group_icon_9.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=X38UDV7XC6SS648X2H2H%2F20220930%2Feu-central-1%2Fs3%2Faws4_request&X-Amz-Date=20220930T151153Z&X-Amz-Expires=3600&X-Amz-SignedHeaders=host&X-Amz-Signature=58d9ce060efacb251858a09dfe2017947cf2fd3d7a1b614eb705390188a8edbd',
  },
  sys: {
    id: 'host_4',
  },
};

export const EVENT_TURKEY_AUTHORS = [
  EVENT_TURKEY_CMS_AUTHOR_1,
  EVENT_TURKEY_CMS_AUTHOR_2,
  EVENT_TURKEY_CMS_AUTHOR_3,
  EVENT_TURKEY_CMS_AUTHOR_4,
];

export const EVENTS_TURKEY_CMS = [
  EVENT_TURKEY_CMS_1,
  EVENT_TURKEY_CMS_2,
  EVENT_TURKEY_CMS_3,
  EVENT_TURKEY_CMS_4,
];

export const BANNER_TURKEY_CMS_1: BannerTurkeyCMS = {
  title: 'Blog 1',
  banner: {
    url: 'https://gradoo-staging.eu-central-1.linodeobjects.com/gradoo-staging/media/group_icons/group_icon_9.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=X38UDV7XC6SS648X2H2H%2F20220930%2Feu-central-1%2Fs3%2Faws4_request&X-Amz-Date=20220930T151153Z&X-Amz-Expires=3600&X-Amz-SignedHeaders=host&X-Amz-Signature=58d9ce060efacb251858a09dfe2017947cf2fd3d7a1b614eb705390188a8edbd',
  },
  redirectLink: 'https://www.google.com/',
  sys: {
    id: 'BANNER_CMS_1',
  },
};

export const BANNER_TURKEY_CMS_2: BannerTurkeyCMS = {
  title: 'Blog 2',
  banner: {
    url: 'https://gradoo-staging.eu-central-1.linodeobjects.com/gradoo-staging/media/group_icons/group_icon_9.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=X38UDV7XC6SS648X2H2H%2F20220930%2Feu-central-1%2Fs3%2Faws4_request&X-Amz-Date=20220930T151153Z&X-Amz-Expires=3600&X-Amz-SignedHeaders=host&X-Amz-Signature=58d9ce060efacb251858a09dfe2017947cf2fd3d7a1b614eb705390188a8edbd',
  },
  redirectLink: 'https://www.google.com/',
  sys: {
    id: 'BANNER_CMS_2',
  },
};

export const BANNER_TURKEY_CMS_3: BannerTurkeyCMS = {
  title: 'Blog 3',
  banner: {
    url: 'https://gradoo-staging.eu-central-1.linodeobjects.com/gradoo-staging/media/group_icons/group_icon_9.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=X38UDV7XC6SS648X2H2H%2F20220930%2Feu-central-1%2Fs3%2Faws4_request&X-Amz-Date=20220930T151153Z&X-Amz-Expires=3600&X-Amz-SignedHeaders=host&X-Amz-Signature=58d9ce060efacb251858a09dfe2017947cf2fd3d7a1b614eb705390188a8edbd',
  },
  redirectLink: 'https://www.google.com/',
  sys: {
    id: 'BANNER_CMS_3',
  },
};

export const BANNER_TURKEY_CMS_4: BannerTurkeyCMS = {
  title: 'Blog 4',
  banner: {
    url: 'https://gradoo-staging.eu-central-1.linodeobjects.com/gradoo-staging/media/group_icons/group_icon_9.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=X38UDV7XC6SS648X2H2H%2F20220930%2Feu-central-1%2Fs3%2Faws4_request&X-Amz-Date=20220930T151153Z&X-Amz-Expires=3600&X-Amz-SignedHeaders=host&X-Amz-Signature=58d9ce060efacb251858a09dfe2017947cf2fd3d7a1b614eb705390188a8edbd',
  },
  redirectLink: 'https://www.google.com/',
  sys: {
    id: 'BANNER_CMS_4',
  },
};

export const BANNERS_TURKEY_CMS = [
  BANNER_TURKEY_CMS_1,
  BANNER_TURKEY_CMS_2,
  BANNER_TURKEY_CMS_3,
  BANNER_TURKEY_CMS_4,
];

export const HIGHLIGHT_TURKEY_CMS_1: HighlightTurkeyCMS = {
  title: 'Highlight 1',
  label: 'Highlight 1',
  image: {
    url: 'https://gradoo-staging.eu-central-1.linodeobjects.com/gradoo-staging/media/group_icons/group_icon_9.png',
  },
  sys: {
    id: 'HIGHLIGHT_CMS_1',
  },
  description1: 'Description 1',
  description2: 'Description 2',
  ebook: {
    url: '',
    fileName: 'ebook-america.pdf',
  },
  avatar: {
    url: 'https://gradoo-staging.eu-central-1.linodeobjects.com/gradoo-staging/media/group_icons/group_icon_9.png',
  },
};

export const HIGHLIGHT_TURKEY_CMS_2: HighlightTurkeyCMS = {
  title: 'Highlight 2',
  label: 'Highlight 2',
  image: {
    url: 'https://gradoo-staging.eu-central-1.linodeobjects.com/gradoo-staging/media/group_icons/group_icon_9.png',
  },
  sys: {
    id: 'HIGHLIGHT_CMS_2',
  },
  description1: 'Description 1',
  description2: 'Description 2',
  ebook: {
    url: '',
    fileName: '',
  },
  avatar: {
    url: 'https://gradoo-staging.eu-central-1.linodeobjects.com/gradoo-staging/media/group_icons/group_icon_9.png',
  },
};

export const HIGHLIGHT_TURKEY_CMS_3: HighlightTurkeyCMS = {
  title: 'Highlight 3',
  label: 'Highlight 3',
  image: {
    url: 'https://gradoo-staging.eu-central-1.linodeobjects.com/gradoo-staging/media/group_icons/group_icon_9.png',
  },
  sys: {
    id: 'HIGHLIGHT_CMS_3',
  },
  description1: 'Description 1',
  description2: 'Description 2',
  ebook: {
    url: '',
    fileName: '',
  },
  avatar: {
    url: 'https://gradoo-staging.eu-central-1.linodeobjects.com/gradoo-staging/media/group_icons/group_icon_9.png',
  },
};

export const HIGHLIGHT_TURKEY_CMS_4: HighlightTurkeyCMS = {
  title: 'Highlight 4',
  image: {
    url: 'https://gradoo-staging.eu-central-1.linodeobjects.com/gradoo-staging/media/group_icons/group_icon_9.png',
  },
  label: 'Highlight 4',
  sys: {
    id: 'HIGHLIGHT_CMS_4',
  },
  description1: 'Description 1',
  description2: 'Description 2',
  ebook: {
    url: '',
    fileName: '',
  },
  avatar: {
    url: 'https://gradoo-staging.eu-central-1.linodeobjects.com/gradoo-staging/media/group_icons/group_icon_9.png',
  },
};
export const HIGHLIGHTS_TURKEY_CMS = [
  HIGHLIGHT_TURKEY_CMS_1,
  HIGHLIGHT_TURKEY_CMS_2,
  HIGHLIGHT_TURKEY_CMS_3,
  HIGHLIGHT_TURKEY_CMS_4,
];

const TAG_TURKEY_CMS_1: TagTurkeyCMS = {
  tag: 'Tag 1',
  sys: {
    id: 'Tag_1',
  },
};

const TAG_TURKEY_CMS_2: TagTurkeyCMS = {
  tag: 'Tag 2',
  sys: {
    id: 'Tag_2',
  },
};

const TAG_TURKEY_CMS_3: TagTurkeyCMS = {
  tag: 'Tag 3',
  sys: {
    id: 'Tag_3',
  },
};

const TAG_TURKEY_CMS_4: TagTurkeyCMS = {
  tag: 'Tag 4',
  sys: {
    id: 'Tag_4',
  },
};

export const TAGS_TURKEY_CMS = [
  TAG_TURKEY_CMS_1,
  TAG_TURKEY_CMS_2,
  TAG_TURKEY_CMS_3,
  TAG_TURKEY_CMS_4,
];

export const BLOG_TURKEY_CMS_1: BlogTurkeyCMS = {
  title: 'Blog 1',
  summary: 'Blog 1 summary',
  slug: 'blog-1',
  thumbnail: {
    url: 'https://gradoo-staging.eu-central-1.linodeobjects.com/gradoo-staging/media/group_icons/group_icon_9.png',
  },
  sys: {
    id: 'Blog_1',
  },
  blogType: 'Dergi',
  filterTagsCollection: {
    items: [
      {
        tag: TAG_TURKEY_CMS_1.tag,
      },
    ],
  },
};

export const BLOG_TURKEY_CMS_2: BlogTurkeyCMS = {
  title: 'Blog 2',
  summary: 'Blog 2 summary',
  slug: 'blog-2',
  thumbnail: {
    url: 'https://gradoo-staging.eu-central-1.linodeobjects.com/gradoo-staging/media/group_icons/group_icon_9.png',
  },
  sys: {
    id: 'Blog_2',
  },
  blogType: 'Etkinlik',
  filterTagsCollection: {
    items: [
      {
        tag: TAG_TURKEY_CMS_2.tag,
      },
    ],
  },
};

export const BLOG_TURKEY_CMS_3: BlogTurkeyCMS = {
  title: 'Blog 3',
  summary: 'Blog 3 summary',
  slug: 'blog-3',
  thumbnail: {
    url: 'https://gradoo-staging.eu-central-1.linodeobjects.com/gradoo-staging/media/group_icons/group_icon_9.png',
  },
  sys: {
    id: 'Blog_3',
  },
  blogType: 'Dergi',
  filterTagsCollection: {
    items: [
      {
        tag: TAG_TURKEY_CMS_3.tag,
      },
    ],
  },
};

export const BLOG_TURKEY_CMS_4: BlogTurkeyCMS = {
  title: 'Blog 4',
  summary: 'Blog 4 summary',
  slug: 'blog-4',
  thumbnail: {
    url: 'https://gradoo-staging.eu-central-1.linodeobjects.com/gradoo-staging/media/group_icons/group_icon_9.png',
  },
  sys: {
    id: 'Blog_4',
  },
  blogType: 'Dergi',
  filterTagsCollection: {
    items: [
      {
        tag: TAG_TURKEY_CMS_1.tag,
      },
      {
        tag: TAG_TURKEY_CMS_4.tag,
      },
    ],
  },
};

export const BLOGS_TURKEY_CMS = [
  BLOG_TURKEY_CMS_1,
  BLOG_TURKEY_CMS_2,
  BLOG_TURKEY_CMS_3,
  BLOG_TURKEY_CMS_4,
];

export const CAMPAIGN_1: Campaign = {
  __typename: 'CampaignNode',
  endDate: '2021-2-1',
  startDate: '2021-2-1',
  utm: 'Foo',
  name: 'Campaign_name_1',
  customer: '1',
  isDeleted: false,
  id: 'camp_1',
  status: CampaignStatus.INACTIVE,
  type: CampaignType.INTERNAL,
  orderId: 'foo',
};

export const CAMPAIGN_2: Campaign = {
  __typename: 'CampaignNode',
  endDate: '2021-2-1',
  startDate: '2021-2-1',
  utm: 'Foo',
  name: 'Campaign_name_2',
  isDeleted: false,
  customer: '1',
  id: 'camp_2',
  status: CampaignStatus.ACTIVE,
  type: CampaignType.INTERNAL,
  orderId: 'foo',
};

export const CAMPAIGN_DETAILS_1: CampaignDetails = {
  __typename: 'CampaignNode',
  endDate: CAMPAIGN_1.endDate,
  startDate: CAMPAIGN_1.startDate,
  utm: CAMPAIGN_1.utm,
  name: CAMPAIGN_1.name,
  customer: CAMPAIGN_1.customer,
  id: CAMPAIGN_1.id,
  status: CAMPAIGN_1.status,
  type: CampaignType.EXTERNAL,
  orderId: 'foo',
  assets: [],
  totalClicks: 0,
  totalViews: 0,
  conversionRate: 0,
};

export const LOCATION_TARGETING_FILTER_1: AdsTargetingFilterFields = {
  ...createPlaceholderFilter('location'),
  location: {
    country: COUNTRIES.DE.code,
    zip: [12345],
  },
};

export const LOCATION_TARGETING_FILTER_2: AdsTargetingFilterFields = {
  ...createPlaceholderFilter('location'),
  operator: 'or',
  location: {
    country: COUNTRIES.AT.code,
  },
};

export const TARGETING_RESULTS_1: AdsTargetingFields = {
  institutesTargeting: {
    __typename: 'TargetingNode',
    activeGroupsCount: 10,
    inactiveGroupsCount: 15,
    usersCount: 100,
    institutes: [
      {
        __typename: 'InstituteTargetingNode',
        activity: InstituteTargetingActivityEnum.ACTIVE,
        location: {
          type: 'Point',
          coordinates: [10, 10],
        },
        id: INSTITUTE_1.id,
      },
    ],
  },
};

export const BANNER_ASSET_1: Asset = {
  __typename: 'AssetNode',
  channelData: [
    {
      __typename: 'BannerAd',
      trackingUrl: 'https://www.gradoo.com',
      imageLarge:
        'https://gradoo-staging.eu-central-1.linodeobjects.com/gradoo-staging/media/group_icons/group_icon_9.png',
      imageSmall:
        'https://gradoo-staging.eu-central-1.linodeobjects.com/gradoo-staging/media/group_icons/group_icon_9.png',
    },
  ],
  channelType: AdvertisementsAssetChannelTypeChoices.BANNER_ADS,
  assetId: 1,
  id: 'bar',
  targetingConfig: null,
  name: 'Banner Ad 1',
  startDate: '2021-2-1',
  endDate: '2021-2-1',
  visibility: AssetVisibilityType.LIVE,
  totalClicks: 0,
  totalViews: 0,
  conversionRate: 0,
  targetingFilters: [],
};

export const MATCHED_BANNER_ASSET_1: MatchedAssetFields = {
  __typename: 'MatchedAssetNode',
  channelData: [
    {
      __typename: 'BannerAd',
      trackingUrl: 'https://www.gradoo.com',
      imageLarge:
        'https://gradoo-staging.eu-central-1.linodeobjects.com/gradoo-staging/media/group_icons/group_icon_9.png',
      imageSmall:
        'https://gradoo-staging.eu-central-1.linodeobjects.com/gradoo-staging/media/group_icons/group_icon_9.png',
    },
  ],
  channelType: AdvertisementsAssetChannelTypeChoices.BANNER_ADS,
  assetId: 1,
  id: 'bar',
  targetingConfig: null,
  name: 'Banner Ad 1',
  startDate: '2021-2-1',
  endDate: '2021-2-1',
  visibility: AssetVisibilityType.LIVE,
  isActive: true,
  targetingFilters: [],
};

export const ASSET_1: EditAsset = {
  asset: {
    __typename: 'AssetNode',
    channelData: null,
    channelType: AdvertisementsAssetChannelTypeChoices.BANNER_ADS,
    assetId: 1,
    id: 'bar',
    targetingConfig: null,
    name: 'Banner Ad 1',
    startDate: '2021-2-1',
    endDate: '2021-2-1',
    visibility: AssetVisibilityType.LIVE,
    targetingFilters: [],
    totalClicks: 0,
    totalViews: 0,
    conversionRate: 0,
  },
};

export const BANNER_ASSET_2: Asset = {
  __typename: 'AssetNode',
  channelData: [
    {
      __typename: 'BannerAd',
      trackingUrl: 'https://www.gradoo.com',
      imageLarge:
        'https://gradoo-staging.eu-central-1.linodeobjects.com/gradoo-staging/media/group_icons/group_icon_9.png',
      imageSmall:
        'https://gradoo-staging.eu-central-1.linodeobjects.com/gradoo-staging/media/group_icons/group_icon_9.png',
    },
  ],
  channelType: AdvertisementsAssetChannelTypeChoices.BANNER_ADS,
  assetId: 2,
  id: 'bar2',
  targetingConfig: null,
  name: 'Banner Ad 2',
  startDate: '2023-12-21',
  endDate: '2023-12-25',
  visibility: AssetVisibilityType.LIVE,
  totalClicks: 0,
  totalViews: 0,
  conversionRate: 0,
  targetingFilters: [],
};

export const LIST_USERGROUPS_1: UserGroup[] = [
  USER_GROUP_ACCEPTED_1,
  USER_GROUP_ACCEPTED_2,
];

export const REPORTING_STATISTICS_1: Statistics = {
  __typename: 'StatisticsNode',
  activeGroupsCount: 1200,
  inactiveGroupsCount: 23,
  institutesCount: 800,
  usersCount: 20000,
};

export const REPORTING_SELECTIONS_1: SelectionsState = {
  selections1: {
    country: [COUNTRIES.DE.country, COUNTRIES.AT.country],
    graduationYear: [2020, 2021],
    graduationType: [GRADUATION_TYPE_1.name],
    instituteType: [INSTITUTE_1.type.name, INSTITUTE_2.type.name],
  },
  selections2: {
    country: [COUNTRIES.DE.country],
    graduationYear: [2020],
    graduationType: [GRADUATION_TYPE_1.name],
    instituteType: [INSTITUTE_1.type.name],
  },
};
