import { QueryHookOptions, QueryResult, useQuery } from '@apollo/client';
import { gql } from '@apollo/client';
import { MockedResponse } from '@apollo/client/testing';
import { IMobileTagsFields } from '../cmsForTurkey';

export const TAGS_CMS_FOR_TURKEY = gql`
  query TagsCollection {
    mobileTagsCollection(where: { showInFilters: true }) {
      items {
        tag
        sys {
          id
        }
      }
    }
  }
`;

export type TagTurkeyCMSType = {
  tag: IMobileTagsFields['tag'];
  sys: {
    id: string;
  };
};

export interface TagsCMSResponseType {
  mobileTagsCollection: {
    items: TagTurkeyCMSType[];
  };
}

interface Variables {
  none?: string;
}

export const useTagsTurkeyCMS = (
  options?: QueryHookOptions<TagsCMSResponseType, Variables>,
): QueryResult<TagsCMSResponseType, Variables> => {
  return useQuery(TAGS_CMS_FOR_TURKEY, {
    ...options,
    context: {
      client: 'cmsForTurkey',
    },
  });
};

export function createTagsTurkeyCMSMock({
  variables,
  data,
  error,
}: {
  variables?: Variables;
  data?: TagsCMSResponseType;
  error?: Error;
}): MockedResponse {
  return {
    request: {
      query: TAGS_CMS_FOR_TURKEY,
      variables,
    },
    result: {
      data,
    },
    error,
  };
}
