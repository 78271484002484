import React, { useCallback, useMemo } from 'react';
import { BadgeColorTheme } from '~/components/Badge/types';
import BannerCarousel, {
  BannerCarouselProps,
  BannerInfo,
} from '~/components/BannerCarousel';
import BlogCard from '~/components/BlogCard';
import NavHeader from '~/components/NavHeader';
import SectionHeader from '~/components/SectionHeader';
import { LINKS } from '~/data/constants';
import {
  BannerTurkeyCMS,
  BlogTurkeyCMS,
  HighlightTurkeyCMS,
  TagTurkeyCMS,
} from '~/data/models/cmsTurkey';
import { Spacer } from '~/theme/wrappers';
import { t } from '~/utils/i18n';
import {
  Container,
  HighlightImageButton,
  HighlightScrollView,
  HightLightImage,
  Content,
  HighlightText,
  ErrorMessage,
  Loading,
  FlatListContent,
  GradooBlogHeader,
  BlogCardWrapper,
  TagButton,
  TagText,
  TagContainer,
  BlogLoading,
} from './style';

const blogBadgeColorMap: Record<BlogTurkeyCMS['blogType'], BadgeColorTheme> = {
  Dergi: 'green',
  Etkinlik: 'primary',
  Gradoo: 'tertiary',
};

type ExploreTRProps = {
  bannersCMS: BannerTurkeyCMS[];
  loading?: boolean;
  blogsLoading?: boolean;
  errorMessage?: string;
  onOpenLink: BannerCarouselProps['onOpenLink'];
  highlightsCMS: HighlightTurkeyCMS[];
  blogsCMS: BlogTurkeyCMS[];
  tagsCMS: TagTurkeyCMS[];
  onFetchMoreBlog: () => void;
  selectedTags: string[];
  setSelectedTags: (tags: string[]) => void;
  onPressEbook: (ebookId: string) => void;
};

export default function ExploreTR({
  bannersCMS,
  onOpenLink,
  loading,
  errorMessage,
  highlightsCMS,
  blogsCMS,
  onFetchMoreBlog,
  tagsCMS,
  selectedTags,
  setSelectedTags,
  onPressEbook,
  blogsLoading,
}: ExploreTRProps): JSX.Element {
  const bannersInfo: BannerInfo[] = useMemo(
    () =>
      bannersCMS.map((item) => {
        return {
          id: item.sys.id,
          externalImgUri: item.banner.url,
          link: item.redirectLink,
        };
      }),
    [bannersCMS],
  );

  const onTagPress = useCallback(
    (tag: string) => {
      if (selectedTags.includes(tag)) {
        setSelectedTags(selectedTags.filter((item) => item !== tag));
      } else {
        setSelectedTags([...selectedTags, tag]);
      }
    },
    [selectedTags],
  );

  return (
    <Container>
      <NavHeader title={t('g.explore')} type={'main'} />
      <Spacer size={12} />
      {loading && <Loading />}
      {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
      {!loading && (
        <FlatListContent<React.ElementType>
          onEndReachedThreshold={0.3}
          onEndReached={() => onFetchMoreBlog()}
          ListHeaderComponent={() => (
            <>
              <BannerCarousel
                imageHeightRatio={0.5}
                banners={bannersInfo}
                onOpenLink={onOpenLink}
              />
              <Content>
                <Spacer size={8} />
                <TagContainer>
                  {tagsCMS.map((item) => {
                    const isSelected = selectedTags.includes(item.tag);
                    return (
                      <TagButton
                        key={`TagButton:${item.sys.id}`}
                        testID={`TagButton:${item.sys.id}`}
                        selected={isSelected}
                        onPress={() => {
                          onTagPress(item.tag);
                        }}
                      >
                        <TagText selected={isSelected}>{item.tag}</TagText>
                      </TagButton>
                    );
                  })}
                </TagContainer>

                <Spacer size={8} />
                <SectionHeader title={t('discoverTRTab.ebooks')} />
                <HighlightScrollView
                  horizontal
                  showsHorizontalScrollIndicator={false}
                  snapToInterval={70}
                >
                  {highlightsCMS.map((item) => {
                    return (
                      <HighlightImageButton
                        testID={`HighlightImageButton:${item.sys.id}`}
                        key={item.sys.id}
                        onPress={() => {
                          onPressEbook(item.sys.id);
                        }}
                      >
                        <HightLightImage
                          source={{
                            uri: item.avatar.url,
                          }}
                          size={55}
                        />
                        <HighlightText>{item.label}</HighlightText>
                      </HighlightImageButton>
                    );
                  })}
                </HighlightScrollView>
                <Spacer size={16} />
                <GradooBlogHeader title={t('discoverTRTab.contents')} />
                <Spacer size={8} />
              </Content>
              {blogsLoading && <BlogLoading />}
            </>
          )}
          data={blogsCMS}
          testID={'flatList'}
          renderItem={({ item }: { item: BlogTurkeyCMS }) => (
            <BlogCardWrapper
              onPress={() => onOpenLink(LINKS.TR.WEBSITE_URL_BLOG + item.slug)}
            >
              <BlogCard
                testID={`BlogCard:${item.sys.id}`}
                imgUri={item.thumbnail.url}
                title={item.title}
                text={item.summary || ''}
                badgeText={item.blogType}
                badgeColor={blogBadgeColorMap[item.blogType] || 'gray'}
              />
            </BlogCardWrapper>
          )}
        />
      )}
    </Container>
  );
}
