import styled, { DefaultTheme } from 'styled-components/native';
import ButtonIcon from '~/components/ButtonIcon';

export type BgColorType = 'primary' | 'secondary' | 'base';

export const Container = styled.TouchableOpacity.attrs({
  activeOpacity: 0.9,
})`
  background-color: ${({ theme }) => theme.color.base.c0};
  flex-direction: row;
  align-items: center;
  padding-bottom: 16px;
`;

interface IIconView {
  bgColorType: BgColorType;
}

type BgColorMap = { [key in BgColorType]: string };

const getBGColor = (bgColorType: BgColorType, theme: DefaultTheme): string => {
  const bgColorMap: BgColorMap = {
    primary: theme.color.brand_02,
    secondary: theme.color.error,
    base: theme.color.base.c6,
  };
  return bgColorMap[bgColorType];
};

export const IconView = styled.View<IIconView>`
  background-color: ${({ bgColorType, theme }) =>
    getBGColor(bgColorType, theme)};
  width: 48px;
  height: 48px;
  border-radius: 14px;
  align-items: center;
  justify-content: center;
`;

export const Text = styled.Text`
  margin-left: 16px;
  flex: 1;
  font-family: ${({ theme }) => theme.fontFamily.core.w400};
  color: ${({ theme }) => theme.color.base.c9};
  font-size: 16px;
`;

export const ArrowButtonIcon = styled(ButtonIcon).attrs(({ theme }) => ({
  name: 'chevron-right',
  size: 16,
  color: theme.color.brand_02,
}))`
  padding: 8px;
`;
