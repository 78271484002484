import React from 'react';
import { useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import { useAuth } from '~/context/auth';
import { useGroupStatisticsQuery } from '~/data/operations/group/groupStatistics';
import { YearbookStackParamList } from '~/navigation/types';
import BookStatisticsLayout from './layout';

type BookStatisticsNavProp = StackNavigationProp<
  YearbookStackParamList,
  'BookStatistics'
>;

export default function BookStatistics({ now }: { now?: Date }): JSX.Element {
  const navigation = useNavigation<BookStatisticsNavProp>();

  const { authGroupId, isTurkishUser } = useAuth();

  const { data: groupStatisticsData } = useGroupStatisticsQuery({
    variables: {
      id: authGroupId,
    },
  });

  const groupStatistics = groupStatisticsData?.group;
  return (
    <BookStatisticsLayout
      isTurkishUser={isTurkishUser}
      groupStatistics={groupStatistics}
      now={now}
      onBack={() => navigation.goBack()}
    />
  );
}
