import { DEFAULT_LIST_OFFSET } from '../constants';
import { LeaderboardUser } from '../models/referralLeaderboard';
import { useLeaderboardUsersQuery } from '../operations/group/leaderboardUsers';

type LeaderboardHookProps = {
  users: LeaderboardUser[];
  loading: boolean;
  hasNextPage?: boolean;
  onRefresh: () => void;
  onLoadMore: () => void;
};

const useLeaderboard = (): LeaderboardHookProps => {
  const leaderboardVars = {
    after: null,
    first: DEFAULT_LIST_OFFSET,
  };

  const {
    data: leaderboardData,
    fetchMore: fetchMoreUsers,
    loading,
    refetch: refetchUsers,
  } = useLeaderboardUsersQuery({
    variables: leaderboardVars,
    notifyOnNetworkStatusChange: true,
  });

  const users: LeaderboardUser[] =
    (leaderboardData?.leaderboardUsers?.edges?.map(
      (edge) => edge?.node,
    ) as LeaderboardUser[]) || [];
  const hasNextPage = leaderboardData?.leaderboardUsers?.pageInfo.hasNextPage;
  const endCursor = leaderboardData?.leaderboardUsers?.pageInfo.endCursor;

  const onRefresh = () => {
    refetchUsers(leaderboardVars);
  };

  const onLoadMore = () => {
    fetchMoreUsers({
      variables: {
        ...leaderboardVars,
        after: endCursor,
      },
    });
  };

  return {
    users,
    loading,
    hasNextPage,
    onRefresh,
    onLoadMore,
  };
};

export default useLeaderboard;
