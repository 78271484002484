import * as React from 'react';
import { createStackNavigator } from '@react-navigation/stack';
import { ProfileTRStackParamList } from '~/navigation/types';
import AppSetup from '~/screens/Main/AppSetup';
import GroupSetup from '~/screens/Main/GroupSetup';
import NotificationSettings from '~/screens/Main/NotificationSettings';
import ProfileSetupTurkeyEntrance from '~/screens/TurkeyEntrance/ProfileSetupTurkeyEntrance';
import ProfileScreenTR from '~/screens/TurkeyEntrance/ProfileTR';

const ProfileStackNavigator = createStackNavigator<ProfileTRStackParamList>();

export default function ProfileTRStack(): JSX.Element {
  return (
    <ProfileStackNavigator.Navigator
      screenOptions={{
        headerShown: false,
      }}
    >
      <ProfileStackNavigator.Screen
        name={'ProfileTR'}
        component={ProfileScreenTR}
      />
      <ProfileStackNavigator.Screen
        name={'ProfileSetupTurkeyEntrance'}
        component={ProfileSetupTurkeyEntrance}
      />
      <ProfileStackNavigator.Screen
        name={'GroupSetup'}
        component={GroupSetup}
      />
      <ProfileStackNavigator.Screen
        name={'NotificationSettings'}
        component={NotificationSettings}
      />
      <ProfileStackNavigator.Screen name={'AppSetup'} component={AppSetup} />
    </ProfileStackNavigator.Navigator>
  );
}
