import React from 'react';
import Button from '~/components/Button';
import NavHeader from '~/components/NavHeader';
import { HighlightTurkeyCMS } from '~/data/models/cmsTurkey';
import { Spacer } from '~/theme/wrappers';
import { t } from '~/utils/i18n';
import {
  Container,
  Content,
  ContentScroll,
  Description,
  EbookBannerImage,
  Title,
  ErrorText,
  Loading,
  DownloadText,
} from './style';

type EbookTRLayoutProps = {
  onBack: () => void;
  ebook?: HighlightTurkeyCMS;
  onDownload: () => void;
  error?: string;
  loading: boolean;
  downloadLoading: boolean;
};

export default function EbookTR({
  onBack,
  ebook,
  onDownload,
  error,
  loading,
  downloadLoading,
}: EbookTRLayoutProps): JSX.Element {
  const renderReady = ebook && !loading && !error;

  return (
    <Container>
      <NavHeader
        backButtonTestID={'buttonBack'}
        title={t('screens.ebookTR', {
          bookTitle: ebook?.label || '',
        })}
        onBackPress={onBack}
      />
      <ContentScroll>
        {loading && <Loading />}
        {error && <ErrorText>{error}</ErrorText>}
        {renderReady && (
          <Content showsVerticalScrollIndicator={false}>
            <EbookBannerImage
              heightRatio={0.57}
              source={{
                uri: ebook?.image?.url || '',
              }}
            />
            <Spacer size={16} />
            <Title>{ebook.title}</Title>
            <Spacer size={8} />
            <Description>{ebook.description1}</Description>
            <Spacer size={8} />
            <Description>{ebook.description2}</Description>
            <Spacer size={24} />
          </Content>
        )}
        {renderReady && (
          <>
            {downloadLoading && (
              <DownloadText>{t('eBookTR.downloadingText')}</DownloadText>
            )}
            <Button text={t('eBookTR.downloadText')} onPress={onDownload} />
          </>
        )}
      </ContentScroll>
    </Container>
  );
}
