import React from 'react';
import {
  CompositeNavigationProp,
  useNavigation,
} from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import Snackbar from '~/components/Snackbar';
import { useAuth } from '~/context/auth';
import {
  HIDDEN_YEARBOOK_MODULES_FOR_TURKEY,
  NO_PAG_LIST_OFFSET,
} from '~/data/constants';
import { ModuleInstance } from '~/data/models/yearbook';
import { useDestroyModuleInstanceMutation } from '~/data/operations/yearbook/destroyModuleInstance';
import {
  readModuleInstancesQuery,
  writeModuleInstancesQuery,
} from '~/data/operations/yearbook/helpers';
import { useModuleInstancesQuery } from '~/data/operations/yearbook/moduleInstances';
import { RootStackParamList, YearbookStackParamList } from '~/navigation/types';
import ManageLayout from './layout';

type ManageNavProp = CompositeNavigationProp<
  StackNavigationProp<YearbookStackParamList, 'Manage'>,
  StackNavigationProp<RootStackParamList>
>;

export default function Manage(): JSX.Element {
  const navigation = useNavigation<ManageNavProp>();

  const { authUserId, isTurkishUser, authGroupId } = useAuth();

  const openBannersInBrowser = (url: string) => {
    navigation.navigate('Browser', {
      title: 'Banner',
      url,
    });
  };

  const modulesInstancesVar = { group: authGroupId, first: NO_PAG_LIST_OFFSET };

  const { data: moduleInstancesData } = useModuleInstancesQuery({
    skip: !authGroupId,
    variables: modulesInstancesVar,
  });

  let moduleInstances: ModuleInstance[] =
    (moduleInstancesData?.moduleInstances?.edges.map(
      (edge) => edge?.node,
    ) as ModuleInstance[]) || [];

  if (isTurkishUser) {
    moduleInstances = moduleInstances.filter(
      (moduleInstance) =>
        moduleInstance.module.type &&
        !HIDDEN_YEARBOOK_MODULES_FOR_TURKEY.includes(
          moduleInstance.module.type,
        ),
    );
  }

  const [destroyModuleInstance] = useDestroyModuleInstanceMutation();

  const onDeleteModuleInstance = async (moduleInstanceId: string) => {
    try {
      await destroyModuleInstance({
        variables: {
          input: {
            id: moduleInstanceId,
          },
        },
        update(cache) {
          const currentModuleInstancesQuery = readModuleInstancesQuery({
            cache,
            variables: modulesInstancesVar,
          });
          if (
            currentModuleInstancesQuery &&
            currentModuleInstancesQuery.moduleInstances &&
            currentModuleInstancesQuery.moduleInstances.edges
          ) {
            const filteredEdges =
              currentModuleInstancesQuery.moduleInstances.edges.filter(
                (edge) => edge?.node?.id !== moduleInstanceId,
              );
            writeModuleInstancesQuery({
              cache,
              variables: modulesInstancesVar,
              data: {
                ...currentModuleInstancesQuery,
                moduleInstances: {
                  ...currentModuleInstancesQuery.moduleInstances,
                  edges: filteredEdges,
                },
              },
            });
          }
        },
      });
    } catch (e) {
      if (e instanceof Error) {
        Snackbar.show(e.message);
      }
    }
  };

  return (
    <ManageLayout
      onBack={() => navigation.goBack()}
      onExportData={() => navigation.navigate('ExportData')}
      onCollectedData={() => navigation.navigate('CollectedData')}
      onBookStats={() => navigation.navigate('BookStatistics')}
      openBannersInBrowser={openBannersInBrowser}
      authUserId={authUserId}
      moduleInstances={moduleInstances}
      onDeleteModuleInstance={onDeleteModuleInstance}
      onAddModule={() => navigation.navigate('AddModule')}
      onSelectModule={(moduleId) =>
        navigation.navigate('ModuleDetail', { moduleId, viewOnly: true })
      }
    />
  );
}
