import { QueryHookOptions, QueryResult, useQuery } from '@apollo/client';
import { gql } from '@apollo/client';
import { MockedResponse } from '@apollo/client/testing';
import { IMobileHighlightFields } from '../cmsForTurkey';

export const HIGHLIGHTS_CMS_FOR_TURKEY = gql`
  query MobileHighlightCollection {
    mobileHighlightCollection {
      items {
        title
        label
        description1
        description2
        image {
          url
        }
        avatar {
          url
        }
        ebook {
          url
          fileName
        }
        sys {
          id
        }
      }
    }
  }
`;

export type HighlightTurkeyCMSType = {
  title: IMobileHighlightFields['title'];
  label: IMobileHighlightFields['label'];
  description1: IMobileHighlightFields['description1'];
  description2: IMobileHighlightFields['description2'];
  ebook: {
    url: string;
    fileName: string;
  };
  image: {
    url: string;
  };
  avatar: {
    url: string;
  };
  sys: {
    id: string;
  };
};

export interface HighlightCMSResponseType {
  mobileHighlightCollection: {
    items: HighlightTurkeyCMSType[];
  };
}

interface Variables {
  authorsIDs: (string | undefined)[];
}

export const useHighlightsTurkeyCMS = (
  options?: QueryHookOptions<HighlightCMSResponseType, Variables>,
): QueryResult<HighlightCMSResponseType, Variables> => {
  return useQuery(HIGHLIGHTS_CMS_FOR_TURKEY, {
    ...options,
    context: {
      client: 'cmsForTurkey',
    },
  });
};

export function createHighlightTurkeyCMSMock({
  variables,
  data,
  error,
}: {
  variables?: Variables;
  data?: HighlightCMSResponseType;
  error?: Error;
}): MockedResponse {
  return {
    request: {
      query: HIGHLIGHTS_CMS_FOR_TURKEY,
      variables,
    },
    result: {
      data,
    },
    error,
  };
}
