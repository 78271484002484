import { Dimensions } from 'react-native';
import styled from 'styled-components/native';
import Image from '~/components/Image';
import { TabScreenContainer } from '~/screens/style';

const { width } = Dimensions.get('window');

interface IEbookBannerImage {
  heightRatio: number;
}
export const CONTAINER_WIDTH = width;
const CONTAINER_PADDING = 16;
export const EBOOK_BANNER_WIDTH = width - 2 * CONTAINER_PADDING;

export const Container = styled(TabScreenContainer)`
  flex: 1;
  background-color: ${({ theme }) => theme.color.base.c0};
`;

export const ContentScroll = styled.ScrollView.attrs(({ theme }) => ({
  contentContainerStyle: {
    backgroundColor: theme.color.base.c0,
    flex: 1,
  },
}))`
  padding: ${CONTAINER_PADDING}px;
`;

export const EbookBannerImage = styled(Image)<IEbookBannerImage>`
  background-color: ${({ theme }) => theme.color.base.c1};
  width: ${EBOOK_BANNER_WIDTH}px;
  height: ${({ heightRatio }) => heightRatio * EBOOK_BANNER_WIDTH}px;
  border-radius: 5px;
`;

export const Title = styled.Text`
  font-size: 28px;
  font-weight: 500;
  text-align: center;
  margin-bottom: 8px;
`;

export const Description = styled.Text`
  font-size: 16px;
  text-align: center;
`;

export const Content = styled.ScrollView`
  flex: 1;
`;

export const ErrorText = styled.Text`
  text-align: center;
  font-family: ${({ theme }) => theme.fontFamily.core.w400};
  color: ${({ theme }) => theme.color.error};
  font-size: 16px;
`;

export const DownloadText = styled.Text`
  text-align: center;
  font-family: ${({ theme }) => theme.fontFamily.core.w400};
  color: ${({ theme }) => theme.color.base.c5};
  font-size: 16px;
  margin-bottom: 12px;
`;

export const Loading = styled.ActivityIndicator.attrs(({ theme }) => ({
  color: theme.color.base.c4,
  size: 'small',
}))``;
