import React from 'react';
import {
  CompositeNavigationProp,
  useNavigation,
} from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import { DATA_POLICY, TERM } from '~/data/derivedConstants';
import { AuthStackParamList, RootStackParamList } from '~/navigation/types';
import { t } from '~/utils/i18n';
import Icon, { IconName } from '../Icon';
import {
  Container,
  LeftView,
  RightView,
  FooterText,
  LinkView,
  VSeparator,
} from './style';

type WebFooterNavProp = CompositeNavigationProp<
  StackNavigationProp<AuthStackParamList>,
  StackNavigationProp<RootStackParamList>
>;

type LinkProps = {
  text: string;
  onPress: () => void;
};
const Link = ({ text, onPress }: LinkProps) => {
  return (
    <LinkView onPress={onPress}>
      <FooterText>{text}</FooterText>
    </LinkView>
  );
};

type SocialLinkrops = {
  iconName: IconName;
  onPress: () => void;
};
const SocialLink = ({ iconName, onPress }: SocialLinkrops) => {
  return (
    <LinkView onPress={onPress}>
      <Icon name={iconName} />
    </LinkView>
  );
};

const WebFooter = ({
  isCompact = false,
}: {
  isCompact?: boolean;
}): JSX.Element => {
  const navigation = useNavigation<WebFooterNavProp>();

  const links: LinkProps[] = [
    {
      text: t('login.imprint'),
      onPress: () => {
        //TODO-v2: imprint redirect
      },
    },
    {
      text: t('login.dataProtectionCenter'),
      onPress: () => {
        navigation.navigate('Browser', DATA_POLICY);
      },
    },
    {
      text: t('login.revocation'),
      onPress: () => {
        //TODO-v2: revocation redirect
      },
    },
    {
      text: t('login.terms'),
      onPress: () => {
        navigation.navigate('Browser', TERM);
      },
    },
  ];

  const socialLinks: SocialLinkrops[] = [
    {
      iconName: 'instagram',
      onPress: () => {
        //TODO-v2: instagram redirect
      },
    },
    {
      iconName: 'linkedin',
      onPress: () => {
        //TODO-v2: linkedin redirect
      },
    },
    {
      iconName: 'discord',
      onPress: () => {
        //TODO-v2: discord redirect
      },
    },
    {
      iconName: 'youtube',
      onPress: () => {
        //TODO-v2: youtube redirect
      },
    },
    {
      iconName: 'tiktok',
      onPress: () => {
        //TODO-v2: tiktok redirect
      },
    },
  ];

  return (
    <Container isCompact={isCompact}>
      <LeftView isCompact={isCompact}>
        <FooterText>
          {t('login.copyright', { year: new Date().getFullYear() })}
        </FooterText>
        {links.map((link, i) => (
          <>
            {i < links.length && <VSeparator key={`s${i}`} />}
            <Link key={`l${i}`} {...link} />
          </>
        ))}
      </LeftView>
      <RightView isCompact={isCompact}>
        {socialLinks.map((socialLink, i) => (
          <SocialLink key={`sl${i}`} {...socialLink} />
        ))}
      </RightView>
    </Container>
  );
};

export default WebFooter;
