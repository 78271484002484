import React, { useState } from 'react';
import {
  CompositeNavigationProp,
  RouteProp,
  useNavigation,
  useRoute,
} from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import * as FileSystem from 'expo-file-system';
import { shareAsync } from 'expo-sharing';
import { useHighlightsTurkeyCMS } from '~/data/operations/cmsForTurkey/useHighlightsTurkeyCMS';
import {
  ExploreTRStackParamList,
  RootStackParamList,
  TurkeyEntranceTabParamList,
} from '~/navigation/types';
import { t } from '~/utils/i18n';
import EbookTRLayout from './layout';

type EbookTRNavProp = CompositeNavigationProp<
  StackNavigationProp<RootStackParamList>,
  StackNavigationProp<TurkeyEntranceTabParamList, 'ExploreTRStack'>
>;

type EbookRouteProp = RouteProp<ExploreTRStackParamList, 'EbookTR'>;

export default function EbookTR(): JSX.Element {
  const {
    params: { ebookId },
  } = useRoute<EbookRouteProp>();
  const navigation = useNavigation<EbookTRNavProp>();
  const [downloadError, setDownloadError] = useState('');
  const [downloadLoading, setDownloadLoading] = useState(false);
  const {
    data: highlightsCMSData,
    loading: highlightsLoading,
    error: highlightsError,
  } = useHighlightsTurkeyCMS();

  const ebook = highlightsCMSData?.mobileHighlightCollection?.items.find(
    (i) => i.sys.id === ebookId,
  );

  const onDownloadFromUrl = async () => {
    if (!ebook) return;

    try {
      setDownloadLoading(true);
      const result = await FileSystem.downloadAsync(
        ebook.ebook.url,
        FileSystem.documentDirectory + ebook.ebook.fileName,
      );
      await shareAsync(result.uri);
    } catch (error) {
      if (error instanceof Error) {
        setDownloadError(error.message);
      } else {
        setDownloadError(t('eBookTR.downloadError'));
      }
    } finally {
      setDownloadLoading(false);
    }
  };

  return (
    <EbookTRLayout
      ebook={ebook}
      onDownload={onDownloadFromUrl}
      onBack={() => navigation.goBack()}
      error={
        highlightsError?.message ||
        downloadError ||
        (ebook === undefined ? 'Ebook not found' : '')
      }
      loading={highlightsLoading}
      downloadLoading={downloadLoading}
    />
  );
}
