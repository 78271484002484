import React from 'react';
import {
  CompositeNavigationProp,
  useNavigation,
} from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import Snackbar from '~/components/Snackbar';
import { useAuth } from '~/context/auth';
import { NO_PAG_LIST_OFFSET } from '~/data/constants';
import { GroupUser } from '~/data/models/group';
import { useMeQuery } from '~/data/operations/auth/me';
import { GROUP_USERS } from '~/data/operations/group';
import { useGroupUsersQuery } from '~/data/operations/group/groupUsers';
import { useRemoveUserFromGroupMutation } from '~/data/operations/group/removeUserFromGroup';
import { useUserGroupQuery } from '~/data/operations/group/userGroup';
import {
  RootStackParamList,
  TurkeyEntranceTabParamList,
} from '~/navigation/types';
import { t } from '~/utils/i18n';
import GraduationTRLayout from './layout';

type GraduationTRNavProp = CompositeNavigationProp<
  StackNavigationProp<TurkeyEntranceTabParamList, 'GraduationTR'>,
  StackNavigationProp<RootStackParamList>
>;

export default function GraduationTR(): JSX.Element {
  const navigation = useNavigation<GraduationTRNavProp>();
  const { loading: loadingMe, error: meError, data: meData } = useMeQuery();
  const firstName = meData?.me?.firstName || '';

  const { onAuthGroup } = useAuth();

  const {
    loading: loadingGroupUsers,
    error: groupUsersError,
    data: groupUsersData,
  } = useGroupUsersQuery({
    skip: !meData?.me?.id,
    variables: {
      user: meData?.me?.id,
      first: NO_PAG_LIST_OFFSET,
    },
    notifyOnNetworkStatusChange: true,
  });

  const [removeUserFromGroup] = useRemoveUserFromGroupMutation();

  const groupUsers: GroupUser[] =
    (groupUsersData?.userGroups?.edges.map(
      (edge) => edge?.node,
    ) as GroupUser[]) || [];

  const groupUsersAccepted: GroupUser[] = groupUsers.filter(
    (userGroup) => userGroup.status === 'ACCEPTED',
  );
  const groupUsersNotAccepted: GroupUser[] = groupUsers.filter(
    (userGroup) =>
      userGroup.status === 'PENDING' || userGroup.status === 'REJECTED',
  );
  const userGroupPending = groupUsersNotAccepted.find(
    (userGroup) => userGroup.status === 'PENDING',
  );

  useUserGroupQuery({
    skip: !userGroupPending,
    variables: {
      id: userGroupPending?.id as string,
    },
    pollInterval: 5000,
  });

  async function onRemoveRequest(groupId: string) {
    try {
      await removeUserFromGroup({
        variables: {
          input: { groupId, userId: meData?.me?.id as string },
        },
        refetchQueries: [
          {
            query: GROUP_USERS,
            variables: {
              user: meData?.me?.id,
              first: NO_PAG_LIST_OFFSET,
            },
          },
        ],
      });
      Snackbar.show(t('gradutionTRTab.requestCancelled'));
    } catch (e) {
      if (e instanceof Error) {
        Snackbar.show(e.message);
      }
    }
  }

  return (
    <GraduationTRLayout
      loading={loadingMe || loadingGroupUsers}
      errorMessage={
        (meError && meError.message) ||
        (groupUsersError && groupUsersError.message) ||
        undefined
      }
      userName={firstName}
      onJoinGroup={() =>
        navigation.navigate('GroupStack', {
          screen: 'JoinSelectLocation',
        })
      }
      groupUsersAccepted={groupUsersAccepted}
      groupUsersPending={groupUsersNotAccepted}
      onSelectedGroup={(groupId, userGroupId) =>
        onAuthGroup(groupId, userGroupId)
      }
      onRemoveRequest={(groupId) => onRemoveRequest(groupId)}
    />
  );
}
