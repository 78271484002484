import React from 'react';
import TeamIcon, { TeamIconName } from '~/components/TeamIcon';
import {
  Container,
  IconView,
  Text,
  ArrowButtonIcon,
  BgColorType,
} from './style';

type OptionCellProps = {
  testID?: string;
  teamIcon: TeamIconName;
  title: string;
  bgColorType?: BgColorType;
  onPress: () => void;
};

export default function OptionCell({
  testID,
  teamIcon,
  title,
  bgColorType = 'primary',
  onPress,
}: OptionCellProps): JSX.Element {
  return (
    <Container testID={testID} onPress={onPress}>
      <IconView bgColorType={bgColorType}>
        <TeamIcon iconName={teamIcon} />
      </IconView>
      <Text>{title}</Text>
      <ArrowButtonIcon onPress={onPress} />
    </Container>
  );
}
