import { QueryHookOptions, QueryResult, useQuery } from '@apollo/client';
import { gql } from '@apollo/client';
import { MockedResponse } from '@apollo/client/testing';
import { IEventsFields } from '../cmsForTurkey';

export const EVENTS_CMS_FOR_TURKEY = gql`
  query EventsCollection {
    eventsCollection {
      items {
        title
        locationTitle
        eventDate
        isOnline
        participants
        slug
        joinLink
        sys {
          id
        }
        host {
          sys {
            id
          }
        }
      }
    }
  }
`;

export type EventTurkeyCMSType = {
  eventDate: IEventsFields['eventDate'];
  locationTitle: IEventsFields['locationTitle'];
  title: IEventsFields['title'];
  isOnline: IEventsFields['isOnline'];
  joinLink: IEventsFields['joinLink'];
  slug: IEventsFields['slug'];
  participants: IEventsFields['participants'];
  host: {
    sys: {
      id: string;
    };
  };
  sys: {
    id: string;
  };
};

export interface EventsCMSResponseType {
  eventsCollection: {
    items: EventTurkeyCMSType[];
  };
}

interface Variables {
  none?: string;
}

export const useEventsTurkeyCMS = (
  options?: QueryHookOptions<EventsCMSResponseType>,
): QueryResult<EventsCMSResponseType, Variables> => {
  return useQuery(EVENTS_CMS_FOR_TURKEY, {
    ...options,
    context: {
      client: 'cmsForTurkey',
    },
  });
};

export function createEventsTurkeyCMSMock({
  variables,
  data,
  error,
}: {
  variables?: Variables;
  data?: EventsCMSResponseType;
  error?: Error;
}): MockedResponse {
  return {
    request: {
      query: EVENTS_CMS_FOR_TURKEY,
      variables,
    },
    result: {
      data,
    },
    error,
  };
}
