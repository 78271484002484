import { QueryHookOptions, QueryResult, useQuery } from '@apollo/client';
import { gql } from '@apollo/client';
import { MockedResponse } from '@apollo/client/testing';
import { IMobileBannerFields } from '../cmsForTurkey';

export const BANNER_CMS_FOR_TURKEY = gql`
  query BannerCollections {
    mobileBannerCollection {
      items {
        title
        banner {
          url
        }
        redirectLink
        sys {
          id
        }
      }
    }
  }
`;

export type BannerTurkeyCMSType = {
  title: IMobileBannerFields['title'];
  banner: {
    url: string;
  };
  redirectLink: IMobileBannerFields['redirectLink'];
  sys: {
    id: string;
  };
};

export interface BannerCMSResponseType {
  mobileBannerCollection: {
    items: BannerTurkeyCMSType[];
  };
}

interface Variables {
  none?: string;
}

export const useBannersTurkeyCMS = (
  options?: QueryHookOptions<BannerCMSResponseType, Variables>,
): QueryResult<BannerCMSResponseType, Variables> => {
  return useQuery(BANNER_CMS_FOR_TURKEY, {
    ...options,
    context: {
      client: 'cmsForTurkey',
    },
  });
};

export function createBannerTurkeyCMSMock({
  variables,
  data,
  error,
}: {
  variables?: Variables;
  data?: BannerCMSResponseType;
  error?: Error;
}): MockedResponse {
  return {
    request: {
      query: BANNER_CMS_FOR_TURKEY,
      variables,
    },
    result: {
      data,
    },
    error,
  };
}
