import React from 'react';
import {
  CompositeNavigationProp,
  useNavigation,
} from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import { useAuth } from '~/context/auth';
import { GroupStackParamList, RootStackParamList } from '~/navigation/types';
import RequestInstitutionSentLayout from './layout';

type RequestInstitutionSentNavProp = CompositeNavigationProp<
  StackNavigationProp<RootStackParamList>,
  StackNavigationProp<GroupStackParamList, 'RequestInstitutionSent'>
>;

export default function RequestInstitutionSent(): JSX.Element {
  const navigation = useNavigation<RequestInstitutionSentNavProp>();

  const { onLogout } = useAuth();

  const logoutHandler = async () => {
    await onLogout();

    navigation.reset({
      routes: [{ name: 'AuthStack' }],
    });
  };

  return (
    <RequestInstitutionSentLayout
      onGoToLobby={() => {
        //hack to pop from GroupStack back to Main or TurkeyEntrance
        navigation.popToTop();
        navigation.goBack();
      }}
      onLogout={logoutHandler}
    />
  );
}

/**
 * TODO:
 * - Lottie animation
 */
